import React, { useState, useReducer, useRef, useEffect, useMemo } from "react";
import {
  Button,
  ButtonGroup,
  NonIdealState,
  Spinner,
  Divider,
} from "@blueprintjs/core";
import {
  Cell,
  Column,
  Table,
  SelectionModes,
  Regions,
  RenderMode,
} from "@blueprintjs/table";
import { useDebouncedCallback } from "use-debounce";
import { saveAs } from "file-saver";
import { interpolateBlues } from "d3";

import {
  StructureViewer,
  registerColorSchemeFromResidueMap,
} from "./StructureViewer";
import { createColorMapper } from "./../../utils/Helpers";
import { ErrorRefetcher } from "../common/Helpers";
import { CouplingsNetwork, renderLegend } from "./CouplingsNetwork";
import { TableLegend } from "../common/Legend";

import { createSiteId, createSegmentPrefix } from "../../utils/Segments";

// 3D structure viewer
import "./BioBlocks.css"; // hide panels / change default styles of 3D viewer
import { RESET_SELECTION_ICON } from "../../utils/Constants";

// default residue color for non-enriched residues
const DEFAULT_STRUCTURE_COLOR = "#FFFFFF";

/*
  use Array instead of crappy JS set, or TODO: use Immutable
*/
export const selectionReducer = (state, action) => {
  // console.log("REDUCER", "POSITIONS", state.positions, "ACTION", action);
  switch (action.action) {
    case "setAll":
      // console.log("DISPATCH ALL", JSON.stringify(action));
      // override selection completely with whatever is specified
      return {
        sites: action.sites != null ? action.sites : state.sites,
        pairs: action.pairs != null ? action.pairs : state.pairs,
      };

    case "addSites":
      // dispatched change to site selection
      const selectedSiteIds = action.sites;

      // compute updated selection (remove any already selected sites if selected again)
      const newSelection = state.sites
        .filter(
          // keep all already selected sites if they are not in new one
          (s) => !selectedSiteIds.includes(s)
        )
        .concat(
          // vice versa, keep all newly selected sites if they are not in old selection
          selectedSiteIds.filter((s) => !state.sites.includes(s))
        );

      return {
        ...state,
        sites: newSelection,
      };

    case "reset":
      return {
        sites: [],
        pairs: [],
      };

    default:
      // console.log("SHOULDNT HAPPEN!");
      return {
        sites: [],
        pairs: [],
      };
  }
};

export const NO_SITES_WARNING = (
  <NonIdealState
    icon="warning-sign"
    title="No couplings available"
    description="Relax filtering criteria in settings panel."
  />
);

/*
  Strongly coupled site display table (middle panel)
*/
const StronglyCoupledSiteTable = ({
  cumulativeCouplings,
  selection,
  showSegments,
  dispatchSelection,
  jobGroup,
  job,
}) => {
  // appears to work better without debouncing
  const TABLE_DEBOUNCE_THRESHOLD = 0;

  const [dispatchSelectionDebounced] = useDebouncedCallback((newSelection) => {
    // console.log("DEBOUNCED SELECTION", JSON.stringify(newSelection));
    dispatchSelection(newSelection);
  }, TABLE_DEBOUNCE_THRESHOLD);

  if (!cumulativeCouplings) {
    return <></>;
  }

  const cc = cumulativeCouplings.toArray();

  // maping from site (e.g. A__151) to index in table
  const siteToRowIndex = new Map(
    cc.map((row, i) => [createSiteId(row.segment_i, row.i), i])
  );
  // inverse map
  const rowToSiteIndex = new Map(
    cc.map((row, i) => [i, createSiteId(row.segment_i, row.i)])
  );

  // all positions that have enrichment value (as they are in table)
  // (3D structure can contain additional sites that would lead to problems here)
  const validPositions = cc.map((row) => createSiteId(row.segment_i, row.i));

  // selected sites that are valid
  const selectedValidPos = selection.sites.filter((site) =>
    validPositions.includes(site)
  );

  // map selected sites to table region objects
  const selectedRegions = selectedValidPos.map((site) =>
    Regions.row(siteToRowIndex.get(site))
  );

  // console.log("SELECTED REGIONS", selectedRegions);

  return (
    <>
      <Table
        numRows={cc.length}
        key={cc.length}
        columnWidths={[60, 40, 60, 60, 70]}
        enableRowHeader={true}
        enableRowResizing={false}
        enableColumnResizing={false}
        renderMode={RenderMode.NONE}
        selectedRegions={selectedRegions}
        selectionModes={SelectionModes.ROWS_AND_CELLS}
        onSelection={(regions) => {
          // create list of all selected row indices from pairs [regionStart, regionEnd]
          // as a flat array
          // console.log("REGIONS", JSON.stringify(regions));
          const newRowSelectionFull = [];

          // setSelectionDebounced(regions);
          // return;

          for (let [regionStart, regionEnd] of regions.map(
            (region) => region.rows
          )) {
            for (let i = regionStart; i <= regionEnd; i++) {
              newRowSelectionFull.push(i);
            }
          }

          // console.log("FULL", newRowSelectionFull);

          // eliminate anything from selection that occurs twice
          // (deselected element will be added to end of array by blueprint)
          const newRowSelection = newRowSelectionFull.filter((r, i, a) => {
            const occurrenceCount = newRowSelectionFull.filter((r2) => r2 === r)
              .length;
            // console.log("...", r, i, a.length, "count", occurrenceCount);
            return occurrenceCount === 1 || r !== a[a.length - 1];
            // return (newRowSelectionFull.filter(r2 => r2 === r).length === 1) || (i !== a.length - 1)
          });

          // map to selected positions and eliminate possible duplications from dragging selection
          const newPosSelection = [
            ...new Set(newRowSelection.map((r) => rowToSiteIndex.get(r))),
          ];

          // check if current selection equals new selection, in this case we remove
          // entire selection, otherwise add/keep all newly selected sites and drop everything else
          if (
            newPosSelection.length === 1 &&
            newPosSelection.length === selectedValidPos.length &&
            selectedValidPos.every((x) => newPosSelection.includes(x))
          ) {
            // use debounced callback!
            // console.log("ALL THE SAME");
            dispatchSelectionDebounced({
              action: "setAll",
              sites: [],
              pairs: [],
              // action: "setAll", sites: newPosSelection, pairs: []
            });
          } else {
            // console.log("DIFFERENT");
            dispatchSelectionDebounced({
              action: "setAll",
              sites: newPosSelection,
              pairs: [],
            });
          }
        }}
      >
        <Column
          name="Pos"
          cellRenderer={(rowIndex) => (
            <Cell>
              {createSegmentPrefix(showSegments, cc[rowIndex].segment_i) +
                cc[rowIndex].i}
            </Cell>
          )}
        />
        <Column
          name="AA"
          cellRenderer={(rowIndex) => <Cell>{cc[rowIndex].A_i}</Cell>}
        />
        <Column
          name="Cons."
          cellRenderer={(rowIndex) => (
            <Cell>
              {cc[rowIndex].conservation
                ? cc[rowIndex].conservation.toFixed(2)
                : "-"}
            </Cell>
          )}
        />
        <Column
          name={"#ECs"}
          cellRenderer={(rowIndex) => <Cell>{cc[rowIndex].pair_count}</Cell>}
        />
        <Column
          name={"\u2211Score"}
          cellRenderer={(rowIndex) => (
            <Cell>{cc[rowIndex].cumulative_score.toFixed(1)}</Cell>
          )}
        />
      </Table>
      <ButtonGroup minimal={true}>
        <Button
          title="Clear selection"
          icon={RESET_SELECTION_ICON}
          onClick={() => dispatchSelection({ action: "reset" })}
        />
        <Button
          title="Export table"
          icon="import"
          onClick={() => {
            const blob = new Blob([cumulativeCouplings.toCSV()], {
              type: "text/csv;charset=utf-8",
            });
            saveAs(blob, `EVcouplings_network_${jobGroup}_${job}.csv`);
          }}
        />
        <Divider />
        <TableLegend
          columnDescriptions={[
            { heading: "Pos", legend: "Sequence index/position" },
            { heading: "AA", legend: "Amino acid at position" },
            {
              heading: "Cons.",
              legend:
                "Conservation of position in sequence alignment (0: not conserved , 1: completely conserved)",
            },
            {
              heading: "#ECs",
              legend:
                "Number of evolutionary couplings this position has to other positions",
            },
            {
              heading: "\u2211Score",
              legend:
                "Cumulative coupling score(sum of EC scores to all other positions this position is coupled to)",
            },
          ]}
        />
      </ButtonGroup>
    </>
  );
};

/*
    Result panel for strongly coupled sites ("EC enrichment")
*/
export const StronglyCoupledSitesPanel = ({
  jobGroup,
  job,
  couplingsData,
  pairsFilt,
  cumulativeCouplings,
  refetchCouplings,
  showSegments,
  showNetwork,
  showTable,
  showViewer,
  structures,
  availableStructures,
  dispatchStructureSelection,
  refetchFailedStructures,
}) => {
  // selected positions / edges
  const [selection, dispatchSelection] = useReducer(selectionReducer, {
    sites: [],
    pairs: [],
  });

  // Dispatch reset of selections if job/subjob changes
  // Note that exclusion of selection from useEffect condition is on purpose
  useEffect(() => {
    dispatchSelection({ action: "reset" });
  }, [jobGroup, job]);

  const colorMapper = useMemo(() => {
    if (cumulativeCouplings && cumulativeCouplings.count() > 0) {
      return createColorMapper(
        [0, cumulativeCouplings.getSeries("cumulative_score").max()],
        interpolateBlues,
        false,
        false
      );
    } else {
      return null;
    }
  }, [cumulativeCouplings]);

  // colorScheme for structure
  // TODO: dependent on enrichment values
  // TODO: replace colormapper with one color range
  const colorScheme = useMemo(
    // () => registerColorScheme(exampleColorFunc), []
    () => {
      let colorMap = {};
      if (cumulativeCouplings && cumulativeCouplings.count() > 0) {
        // TODO: update mapper function

        colorMap = Object.fromEntries(
          cumulativeCouplings
            .toArray()
            .map((r) => [r.i, colorMapper(r.cumulative_score)])
        );
        // console.log(colorMap); // TODO: remove
      }

      return registerColorSchemeFromResidueMap(
        colorMap,
        DEFAULT_STRUCTURE_COLOR
      );
    },
    [colorMapper, cumulativeCouplings]
  );

  // end of hooks ------------------

  // console.log("RERENDER");

  const renderNetwork = () => {
    let networkContent;
    let showingNetwork = false;

    if (pairsFilt && cumulativeCouplings) {
      // only render network if we have nodes
      if (cumulativeCouplings.count() > 0) {
        networkContent = (
          <CouplingsNetwork
            pairsFilt={pairsFilt}
            cumulativeCouplings={cumulativeCouplings}
            selection={selection}
            dispatchSelectionOld={dispatchSelection}
            exportFilename={`EVcouplings_network_${jobGroup}_${job}.png`}
            showSegments={showSegments}
          />
        );
        showingNetwork = true;
      } else {
        // nothing left after filtering
        networkContent = NO_SITES_WARNING;
      }
    } else {
      if (couplingsData.error) {
        networkContent = <ErrorRefetcher refetcher={refetchCouplings} />;
      } else {
        // networkContent = <div style={{position: "absolute", top: "50%", left: "50%"}}><Spinner /></div>;
        // networkContent = <div><Spinner /></div>;
        networkContent = <Spinner />;
      }
    }

    return (
      <div
        style={{
          display: "flex",
          // flexGrow: "1",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: showingNetwork ? "space-between" : "center",
          // width: "30%",
          width: "35vw",
          minWidth: "500px",
          height: "78vh",
          // height: "100%",
          minHeight: "500px",
          resize: "horizontal",
          borderWidth: "1pt",
          borderStyle: "solid",
          borderColor: "#efefef",
          overflow: "auto",
          marginRight: "1em",
          marginBottom: "1em",
          // avoid spurious scrollbars
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        {networkContent}
      </div>
    );
  };

  /*
  // incomplete version of site rendering table - eventually replace component above

  const renderSiteTable = () => {
    // widths: [60, 40, 70, 60, 70]
    // names:
    // renderers:
    const showSegments = false; // TODO: remove for EC table
    if (cumulativeCouplings)
      console.log("DATAFRAME", cumulativeCouplings.head(5).toString());

    // create map from site (segment, index) to index in table
    const allSitesToIndex = cumulativeCouplings
      ? Map(
          cumulativeCouplings
            .toArray()
            .map((r, i) => [Map({ segment_i: r.segment_i, i: r.i }), i])
        )
      : Map();

    console.log("ALL SITES", JSON.stringify(allSitesToIndex));
    // const selectedRows = null;

    const columns = [
      {
        heading: "Pos",
        width: 60,
        // renderer: (r, i),
        renderer: (r, i) => createSegmentPrefix(showSegments, r.segment_i) + r.i
      },
      {
        heading: "AA",
        width: 40,
        renderer: (r, i) => r.A_i
      },
      {
        heading: "Cons.",
        width: 70,
        // TODO: need to implement conservation
        renderer: (r, i) => ""
      },
      {
        heading: "#ECs",
        width: 60,
        renderer: (r, i) => r.pair_count
      },
      {
        heading: "\u2211Score",
        width: 70,
        renderer: (r, i) => r.cumulative_score.toFixed(1)
      }
    ];

    const tableContent = (
      <DataTable
        data={cumulativeCouplings}
        columns={columns}
        exportFilename={`EVcouplings_network_${jobGroup}_${job}.csv`} // TODO: change for EC panel
        selectedRows={[1]} // indices of selected rows, derived from reducer; must filter for valid sites!
        onSelection={newRows => console.log("ON SELECTION", newRows)} // function row indices => dispatch event
        dispatchReset={() => dispatchSelection({ action: "reset" })}
      />
    );
    return createPanel(tableContent, { minWidth: "320px", maxWidth: "320px" });
  };*/

  const renderSiteTable = () => {
    let content = null;

    if (cumulativeCouplings) {
      if (cumulativeCouplings.count() > 0) {
        content = (
          <StronglyCoupledSiteTable
            cumulativeCouplings={cumulativeCouplings}
            selection={selection}
            showSegments={showSegments}
            dispatchSelection={dispatchSelection}
            jobGroup={jobGroup}
            job={job}
          />
        );
      } else {
        // no sites left after filtering
        content = NO_SITES_WARNING;
      }
    } else {
      // TODO: warning/loading states
      // networkContent = <div><Spinner /></div>;
      if (couplingsData.error) {
        content = <ErrorRefetcher refetcher={refetchCouplings} />;
      } else {
        // networkContent = <div style={{position: "absolute", top: "50%", left: "50%"}}><Spinner /></div>;
        //content = <div><Spinner /></div>;
        content = <Spinner />;
      }
    }

    // TODO: refactor to use createPanel()
    return (
      <div
        style={{
          display: "flex",
          // flexGrow: "1",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "1em",
          // backgroundColor: "#EEEEEE",
          // textAlign: "center",
          minWidth: "350px",
          maxWidth: "350px",
          /*width: "15vw",*/
          height: "78vh",
          minHeight: "500px",
          marginBottom: "1em",
          overflow: "auto",
          resize: "none",
          borderWidth: "1pt",
          borderStyle: "solid",
          borderColor: "#efefef",

          // stop Safari from creating scroll bars
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        {content}
      </div>
    );
  };

  const renderViewer = () => {
    return (
      <StructureViewer
        selection={selection}
        dispatchSelectionOld={dispatchSelection}
        colorScheme={colorScheme}
        imageExportFileName={`EVcouplings_3Dstructure_${jobGroup}_${job}_sites.png`}
        structures={structures}
        availableStructures={availableStructures}
        dispatchStructureSelection={dispatchStructureSelection}
        refetchFailedStructures={refetchFailedStructures}
        legend={renderLegend(cumulativeCouplings, colorMapper)}
      />
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      {showNetwork ? renderNetwork() : null}
      {showTable ? renderSiteTable() : null}
      {showViewer ? renderViewer() : null}
    </div>
  );
};

export default StronglyCoupledSitesPanel;
