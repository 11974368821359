/*
    Functions related to PDB structure handling in NGL object
    (distance map calculations, secondary structure extraction)

    Parts based on https://github.com/cBioCenter/bioblocks-viz/blob/master/src/data/BioblocksPDB.ts

    cf. http://nglviewer.org/ngl/api/classes/residueproxy.html
*/
import { Map as ImmutableMap, Set, List } from "immutable";
import { consecutiveSegments } from "./Helpers";

export const DISTANCE_TYPE = {
  MINIMUM_ATOM: 1,
  C_ALPHA: 2
};

export const LARGE_DISTANCE = 100000000;

const C_ALPHA_STR = "CA";

export const SECONDARY_STRUCTURES = {
  COIL: "C",
  HELIX: "H",
  STRAND: "E"
};

/*
  NGL codes insertion codes using ASCII integers, 
  if no insertion code, will be 0
*/
const convertInsertionCode = insertionCodeNumber => {
  if (insertionCodeNumber > 0) {
    return String.fromCharCode(insertionCodeNumber);
  } else {
    return null;
  }
};

/*
  Return subset of atoms for residue for distance calculation depending
  on type of selected distance computation strategy.
  
  Note: currently only allows continuous ranges of atoms, no gaps in between
  Current options: Minimum atom (all atoms), C alpha (single atom)
*/
const atomRangeForDistanceType = (distanceType, residue) => {
  const firstAtom = residue.atomOffset;
  const lastAtom = residue.atomEnd;

  switch (distanceType) {
    case DISTANCE_TYPE.MINIMUM_ATOM:
      return [firstAtom, lastAtom];
    case DISTANCE_TYPE.C_ALPHA:
      const atomNames = residue.getAtomnameList();
      const cAlphaIdx = atomNames.indexOf(C_ALPHA_STR);
      // check if C_alpha was found
      if (cAlphaIdx === -1) {
        // if not, just use first atom as replacement
        return [firstAtom, firstAtom];
      } else {
        return [firstAtom + cAlphaIdx, firstAtom + cAlphaIdx];
      }
    default:
      throw new Error("Invalid distance type");
  }
};

/*
  Create unique residue representation as string:
  [chain:]number[inscode]
*/
export const createResidueKey = (residue, addChainToKey) => {
  return `${addChainToKey ? residue.chainname + ":" : ""}${residue.resno}${
    //convertInsertionCode(residue.inscode)
    residue.inscode
  }`;
};

/*
  Compute distance matrix from 3D coordinates

  Note: async due to computational load

  Limitations: 
  * only protein resiudes, does not handle hetatms
  * only symmetric computations, no asymmetric calculation between two entities
*/
export const computeDistanceMap = async (
  model,
  distanceType,
  addChainToKey,
  symmetrizeMap
) => {
  // default distance calculation strategy is minimum atom distance
  if (!distanceType) {
    distanceType = DISTANCE_TYPE.MINIMUM_ATOM;
  }

  if (addChainToKey === undefined) {
    addChainToKey = true;
  }

  if (symmetrizeMap === undefined) {
    symmetrizeMap = true;
  }

  // will store distance map that is computed;
  // note that using native JS Map rather than immutablejs here for updating with side effects
  let distanceMap = new Map();
  let residueNumbers = [];

  // iterate over all residue pairs with nested loop
  model.eachResidue(resI => {
    if (resI.isProtein()) {
      // const resNoI = residueStore.resno[resI.index];
      // const insCodeI = convertInsertionCode(residueStore.inscode[resI.index]);
      // const resNoI = resI.resno;
      // const insCodeI = resI.inscode;
      // const chainI = resI.chainname;
      // const firstAtomI = resI.atomOffset;
      // const lastAtomI = resI.atomEnd;

      const indexI = resI.index;
      const [firstAtomI, lastAtomI] = atomRangeForDistanceType(
        distanceType,
        resI
      );

      // record residue numbers for coverage information calculation
      residueNumbers.push(resI.resno);

      // construct unique key for first residue
      // const keyI = `${addChainToKeys ? resI.chainname + ":" : ""}${resI.resno}${
      //  resI.inscode
      //}`;
      const keyI = createResidueKey(resI, addChainToKey);

      /*
      console.log(
        "*** RES",
        // resNoI,
        // insCodeI,
        // chainI,
        resI.resno,
        resI.inscode,
        resI.chainname,
        resI.atomOffset,
        resI.atomCount,
        resI.atomEnd,
        firstAtomI,
        lastAtomI
        // resI.getAtomType(resI.atomCount)
        //resI
      );*/

      // inner residue loop
      model.eachResidue(resJ => {
        if (resJ.isProtein()) {
          // const resNoJ = residueStore.resno[resJ.index];
          // const insCodeJ = convertInsertionCode(residueStore.inscode[resJ.index]);
          // const resNoJ = resJ.resno;
          // const insCodeJ = resJ.inscode;
          // const chainJ = resJ.chainname;
          // const firstAtomJ = resJ.atomOffset;
          // const lastAtomJ = resJ.atomEnd;

          const indexJ = resJ.index;

          const [firstAtomJ, lastAtomJ] = atomRangeForDistanceType(
            distanceType,
            resJ
          );

          // distance matrix is symmetric, only compute half of it and set other to same values
          if (indexI < indexJ) {
            let dist = LARGE_DISTANCE;

            // loop through all combinations of atoms and determine minimal distance;
            // note that last index is inclusive
            for (let i = firstAtomI; i <= lastAtomI; i++) {
              for (let j = firstAtomJ; j <= lastAtomJ; j++) {
                const curDist = model
                  .getAtomProxy(i)
                  .distanceTo(model.getAtomProxy(j));

                if (curDist < dist) {
                  dist = curDist;
                }
              }
            }

            // construct unique key for second residue
            // const keyJ = `${addChainToKeys ? resJ.chainname + ":" : ""}${
            //  resJ.resno
            // }${resJ.inscode}`;
            const keyJ = createResidueKey(resJ, addChainToKey);

            distanceMap.set(keyI + "_" + keyJ, dist);

            // also set reverse case if selected
            if (symmetrizeMap) {
              distanceMap.set(keyJ + "_" + keyI, dist);
            }
          }
        }
      });
    }
  });

  // console.log("*** DISTMAP", distanceMap.size, distanceMap);
  const dm = ImmutableMap(distanceMap);

  // attach structure coverage information (note: will ignore insertion codes)
  dm.structureCoverage = computeResidueCoverage(residueNumbers);

  return dm;
};

/*
  Compute consecutive residue segments
*/
export const computeResidueCoverage = residueNumbers => {
  // sort residue numbers
  const resNumbers = List(residueNumbers).sort();

  // find consecutive segments
  const segments = consecutiveSegments(resNumbers);

  return {
    structure_id: null,
    segments_i: segments,
    segments_j: segments
  };
};

/*
  Merge multiple distance maps into joint distance map;
  aggregate multiple occurrences of residue pairs into a
  single number using minimum distance
*/
export const aggregateDistanceMaps = distanceMaps => {
  if (distanceMaps.count() === 1) {
    // create a deep copy of object so attaching structureCoverage
    // does not mutate original object
    const merged = ImmutableMap(distanceMaps.get(0).toJS());

    // expand structure coverage information to list and attach to distance map
    merged.structureCoverage = [distanceMaps.get(0).structureCoverage];
    return merged;
  } else {
    const m1 = distanceMaps.get(0);
    const mRest = distanceMaps.slice(1);

    const merged = m1.mergeWith(
      (oldVal, newVal, key) => Math.min(newVal, oldVal),
      ...mRest
    );

    // create list from individual structure coverage information and attach to
    // merged distance map
    merged.structureCoverage = distanceMaps
      .map(dm => dm.structureCoverage)
      .toArray();
    return merged;
  }
};

/*
  Get helix/sheet/coil assignments from model
  (e.g. for secondary structure display)
*/
export const extractSecondaryStructure = model => {
  let secStruct = [];

  model.eachResidue(res => {
    if (res.isProtein()) {
      let secStructType;
      if (res.isHelix()) {
        secStructType = SECONDARY_STRUCTURES.HELIX;
      } else {
        if (res.isSheet()) {
          secStructType = SECONDARY_STRUCTURES.STRAND;
        } else {
          secStructType = SECONDARY_STRUCTURES.COIL;
        }
      }

      secStruct.push([
        ImmutableMap({
          chain: res.chainname,
          residueNumber: res.resno,
          insertionCode: convertInsertionCode(res.inscode)
        }),
        secStructType
      ]);
    }
  });

  return ImmutableMap(secStruct);
};

/*
  Get chain names in PDB that have protein residues
  (e.g. for verification that only single chain was uploaded)
*/
export const getUniqueProteinChains = model => {
  let chainValues = [];
  model.eachResidue(res => {
    if (res.isProtein()) {
      chainValues.push(res.chainname);
    }
  });

  return Set(chainValues);
};

/*
  Check if a structure contains insertion codes
*/
export const residuesWithInsertionCodes = model => {
  let residuesWithInsCodes = [];
  model.eachResidue(res => {
    if (res.isProtein()) {
      // const insCodeConverted = convertInsertionCode(res.inscode);
      // console.log("*** RES", res.resno, res.inscode, createResidueKey(res, true));
      if (res.inscode) {
        residuesWithInsCodes.push(createResidueKey(res, true));
      }
    }
  });

  return List(residuesWithInsCodes);
};

/*
  Verify if residues in a structure match a target sequence, return
  all mismatches.

  Positions not in target sequence or positions with insertion codes will be ignored
*/
export const findResidueMismatches = (model, targetSequence) => {
  const mismatches = [];

  model.eachResidue(res => {
    if (res.isProtein()) {
      // get one-letter residue name
      const pdbRes = res.getResname1();

      // only check residues without insertion codes
      if (!res.inscode && targetSequence.has(res.resno)) {
        // get corresponding residue in target sequence
        const targetRes = targetSequence.get(res.resno);

        // check for mismatches and store them
        if (pdbRes !== targetRes) {
          mismatches.push({
            pos: res.resno,
            pdbRes: pdbRes,
            targetRes: targetRes
          });
        }
      }
    }
  });

  return mismatches;
};
