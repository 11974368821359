import React, { useMemo, useRef, useState } from "react";
import { Set, Map } from "immutable";
import {
  Button,
  ButtonGroup,
  Colors,
  Divider,
  Intent,
  ResizeSensor,
  Tag,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { interpolateBlues } from "d3";
import CytoscapePlotly from "../external/CytoscapePlotly";
import CytoscapeComponent from "react-cytoscapejs";
import {
  createEdgeId,
  createSiteId,
  createSegmentPrefix,
  parseSiteId,
} from "../../utils/Segments";
import { Legend, makeColorbar } from "../common/Legend";

import { RESET_SELECTION_ICON } from "../../utils/Constants";
import { createColorMapper } from "./../../utils/Helpers";

import { useDebouncedCallback } from "use-debounce";
import { saveAs } from "file-saver";

export const IMAGE_SIZE_FACTOR = 5;

export const CYTOSCAPE_STYLE_SHEET = [
  {
    selector: "node",
    style: {
      // width: "mapData(score, 0, 20, 50, 100)",
      // height: "mapData(score, 0, 20, 50, 100)",
      width: 80,
      height: 80,
      content: "data(label)",
      "font-size": "20px",
      "text-valign": "center",
      "text-halign": "center",
      // "background-color": `mapData(score, 0, 20, ${Colors.BLUE3}, ${Colors.BLUE1})`,
      "background-color": "data(color)",

      // "text-outline-width": "2px",
      color: "data(fontcolor)",
      // "text-outline-color": "#ffffff",
      // "text-outline-width": "1px",
      // "overlay-padding": "6px",
      "z-index": "10",
      "border-width": "3px",
      "border-color": Colors.DARK_GRAY4, // "#666666", // Colors.LIGHT_GRAY3,
    },
  },
  {
    selector: "node:selected",
    style: {
      "border-width": "10px",
      // "border-color": "#AAD8FF",
      // "border-opacity": "0.5",
      // "background-color": "#77828C",
      // "background-color": Colors.DARK_GRAY2,
      // "text-outline-color": "#77828C"
      "border-color": "#ffa500",
    },
  },
  {
    selector: "edge",
    style: {
      // "curve-style": "haystack",
      //"haystack-radius": "0.5",
      // opacity: "0.4",
      // "line-color": "#bbb",
      "line-color": Colors.DARK_GRAY5,
      width: "mapData(score, 0, 10, 1, 30)",
      "overlay-padding": "3px",
      opacity: 0.75,
    },
  },
  {
    selector: "edge:selected",
    style: {
      // "curve-style": "haystack",
      //"haystack-radius": "0.5",
      // opacity: "0.4",
      // "line-color": "#bbb",
      "line-color": "#ffa500", // Colors.ORANGE4,
      width: "mapData(score, 0, 10, 1, 30)",
      "overlay-padding": "3px",
    },
  },
];

/*
  See for options: https://github.com/iVis-at-Bilkent/cytoscape.js-fcose
  And explanation of settings: https://stackoverflow.com/questions/39170772/cytoscape-js-cose-layout-nodes-overlapping

  Example settings:
  https://github.com/cytoscape/cytoscape.js/blob/master/documentation/demos/cose-layout/code.js
  https://github.com/iVis-at-Bilkent/cytoscape.js-fcose/blob/master/demo-compound.html

  Edge weights:
  https://stackoverflow.com/questions/33445438/using-the-edge-weight-for-force-directed-layout-cose-in-cytoscape-js
*/
export const LAYOUT_COSE_SETTINGS = {
  name: "fcose",
  nodeRepulsion: 100000,
  idealEdgeLength: 40,
  edgeElasticity: 0.1,

  // edgeElasticity: edge => {console.log("eee EDGE", edge.data()); return 0.1; }
  // edgeElasticity: 0.1,
  // nodeSeparation: 500,  // distance between cmponents - not useful

  // not used:
  // numIter: 1500
  // name: "cose-bilkent"
  // name: "cose"
  // tilingPaddingVertical: 2,
  // tilingPaddingHorizontal: 2
  // gravity: 100,
  // gravityRangeCompound: 10,
  // gravityCompound: 100,
  // ravityRange: 100,
};

const createSiteSelectionIds = (selection) => {
  if (!selection || !selection.sites) {
    return null;
  }

  if (Set.isSet(selection.sites)) {
    // new style reducer
    return selection.sites
      .map((site) => createSiteId(site.get("segment_i"), site.get("i")))
      .toArray();
  } else {
    // old-style reducer
    return selection.sites;
  }
};

const createPairSelectionIds = (selection) => {
  if (!selection || !selection.pairs) {
    return null;
  }

  if (Set.isSet(selection.pairs)) {
    // new style reducer
    return selection.pairs
      .map((pair) =>
        createEdgeId(
          createSiteId(pair.get("segment_i"), pair.get("i")),
          createSiteId(pair.get("segment_j"), pair.get("j"))
        )
      )
      .toArray();
  } else {
    // old-style reducer
    return selection.pairs.map((x) => createEdgeId(x[0], x[1]));
  }
};

export const renderLegend = (
  cumulativeCouplings,
  colorMapper,
  showCouplingStrength
) => {
  const colWidth = "55px";

  let colorbarContent;
  if (cumulativeCouplings && cumulativeCouplings.count() > 0) {
    const series = cumulativeCouplings.getSeries("cumulative_score");
    // const vMin = series.min();
    const vMin = 0;
    const vMax = series.max();

    const colorbar = makeColorbar(colorMapper, vMin, vMax, "right", {
      width: "150px",
      height: "20px",
      marginLeft: "0.5em",
      marginRight: "0.5em",
    });

    colorbarContent = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div style={{ textAlign: "right" }}>{vMin.toFixed(1)} (none)</div>
        <div>{colorbar}</div>
        <div style={{ textAlign: "left" }}>{vMax.toFixed(1)} (strongest)</div>
      </div>
    );
  } else {
    colorbarContent = null;
  }

  // only show coupling strength for network
  let couplingStrengthTable;
  if (showCouplingStrength) {
    const ecDiv = (lineWidth) => (
      <div
        style={{
          width: "100%",
          height: lineWidth,
          opacity: "0.75",
          backgroundColor: Colors.DARK_GRAY5,
          marginBottom: "0.3em",
        }}
      >
        &nbsp;
      </div>
    );

    couplingStrengthTable = (
      <>
        <h6 className="bp3-heading">Evolutionary couplings (between sites)</h6>
        <table
          className="bp3-html-table bp3-html-table-condensed"
          style={{
            verticalAlign: "top",
            width: "100%",
            tableLayout: "fixed",
            marginBottom: "1em",
          }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: colWidth,
                  verticalAlign: "middle",
                  paddingRight: "0px",
                  paddingTop: "10px",
                }}
              >
                {ecDiv("2px")}
                {ecDiv("5px")}
                {ecDiv("8px")}
              </td>
              <td style={{ verticalAlign: "middle" }}>
                Evolutionary coupling score (strength)
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }

  const legendContent = (
    <>
      <h6 className="bp3-heading">Cumulative coupling strength (per site)</h6>
      <table
        className="bp3-html-table bp3-html-table-condensed"
        style={{
          verticalAlign: "top",
          width: "100%",
          tableLayout: "fixed",
          marginBottom: "1em",
        }}
      >
        <tbody>
          <tr>
            <td>{colorbarContent}</td>
          </tr>
        </tbody>
      </table>
      {couplingStrengthTable}
    </>
  );

  return <Legend content={legendContent} />;
};

export const CouplingsNetwork = ({
  pairsFilt,
  cumulativeCouplings,
  selection,
  dispatchSelectionOld, // old-style dispatch
  dispatchSelection,
  dispatchReset,
  exportFilename,
  showSegments,
}) => {
  // console.log("--NETWORK COMPONENT RENDERS--");
  // graph layout
  const [graphLayout, setGraphLayout] = useState(LAYOUT_COSE_SETTINGS);

  const cyRef = useRef(null);

  // cytoscape panel resize debouncer
  const [debouncedResizeCallback] = useDebouncedCallback((entries) => {
    // console.log("RESIZE ENTRIES", entries);
    // console.log(entries.map(e => `${e.contentRect.width} x ${e.contentRect.height}`));

    // only fit if graph is shown, not if it is being hidden (by change of tab)
    // entries come from ResizeSensor
    const e = entries[0];
    const hidden = e.contentRect.width === 0 && e.contentRect.height === 0;

    if (cyRef.current && !hidden) {
      // console.log("cytoscape resize triggered");
      cyRef.current.resize();
      cyRef.current.fit();
    }
  }, 200);

  const colorMapper = useMemo(() => {
    if (cumulativeCouplings && cumulativeCouplings.count() > 0) {
      return createColorMapper(
        [0, cumulativeCouplings.getSeries("cumulative_score").max()],
        interpolateBlues,
        false,
        false
      );
    } else {
      return null;
    }
  }, [cumulativeCouplings]);

  /*
  // make sure to update layout whenever input ECs change
  useEffect(() => {
    console.log("NEED TO UPDATE LAYOUT");
    if (cyRef.current && pairsFilt && cumulativeCouplings) {
      console.log("ACTUALLY UPDATING");
      cyRef.current.layout(graphLayout);
    }
  }, [cumulativeCouplings, graphLayout, pairsFilt]);
  */

  // compute nodes/edges, memoize to increase performance
  const elements = useMemo(() => {
    // console.log("...recomputing nodes/edges, # edges=", pairsFilt.count());
    let nodes = [];
    let edges = [];

    // console.log("SELECTION", JSON.stringify(selection));

    // create list of nodes, sort by sequence order for circular layout
    // TODO: assign size by data possible?
    if (cumulativeCouplings && pairsFilt) {
      const maxScore = cumulativeCouplings.getSeries("cumulative_score").max();

      const sitesOrdered = cumulativeCouplings
        .orderBy((row) => row.segment_i)
        .thenBy((row) => row.i);

      // intermediate functions to allow compatbility with old and new
      // reducers for site and edge selection
      const edgeSelectionIds = createSiteSelectionIds(selection);
      const testSiteSelection = (siteId) => {
        if (!selection || !selection.sites) {
          return false;
        }

        return edgeSelectionIds.includes(siteId);
      };

      const pairSelectionIds = createPairSelectionIds(selection);
      const testPairSelection = (edgeId) => {
        if (!selection || !selection.pairs) {
          return false;
        }

        return pairSelectionIds.includes(edgeId);
      };

      nodes = sitesOrdered.toArray().map((row) => {
        const siteId = createSiteId(row.segment_i, row.i);
        return {
          data: {
            id: siteId,
            label:
              row.A_i +
              " " +
              createSegmentPrefix(showSegments, row.segment_i) +
              row.i,
            score: row.cumulative_score,
            color: colorMapper ? colorMapper(row.cumulative_score) : "#ffffff",
            // dynamically set font color on darkness of node background
            fontcolor:
              row.cumulative_score > maxScore * 0.6
                ? Colors.LIGHT_GRAY5
                : Colors.DARK_GRAY1,
            // row.cumulative_score > maxScore * 0.6 ? "#ffffff" : "#111111",
          },
          // check if this is a selected node
          selected: testSiteSelection(siteId), // selection.sites.includes(siteId)
        };
      });

      edges = pairsFilt.toArray().map((row) => {
        const source = createSiteId(row.segment_i, row.i);
        const target = createSiteId(row.segment_j, row.j);
        const edgeId = createEdgeId(source, target);
        return {
          data: {
            id: edgeId,
            source: source,
            target: target,
            score: row.score,
          },
          selected: testPairSelection(edgeId), // selection.pairs.map(x => createEdgeId(x[0], x[1])).includes(edgeId)
        };
      });
    }

    // console.log("...finished recomputing nodes/edges");
    return CytoscapeComponent.normalizeElements({
      nodes: nodes,
      edges: edges,
    });
  }, [cumulativeCouplings, pairsFilt, selection, showSegments, colorMapper]);

  // create ID list of selected items for centering/resizing
  let selectorIds = [];
  if (selection.sites) {
    selectorIds = selectorIds.concat(createSiteSelectionIds(selection));
  }

  if (selection.pairs) {
    selectorIds = selectorIds.concat(createPairSelectionIds(selection));
  }

  const renderCountTag = () =>
    pairsFilt ? (
      <Tag minimal={true} intent={Intent.NONE} style={{ margin: "6px" }}>
        {pairsFilt.count() + " ECs"}
      </Tag>
    ) : null;

  // console.log("--- actual rendering starts");
  return (
    <>
      <ResizeSensor onResize={debouncedResizeCallback} observeParents={true}>
        <CytoscapePlotly
          ref={cyRef}
          elements={elements}
          stylesheet={CYTOSCAPE_STYLE_SHEET}
          //layout={cumulativeCouplings ? graphLayout : null}
          layout={graphLayout}
          minZoom={0.02}
          maxZoom={5}
          // don't set to 100% so flexbox + resizable divs don't show scrollbars in Chrome & Safari
          // style={{ width: "99%", height: "99%" }}

          // minHeight needed so flexbox can be downsized, cf. https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size
          style={{ flex: 1, minHeight: "0px" }}
          autoRefreshLayout={true}
          boxSelectionEnabled={true}
          setProps={(x) => {
            // TODO: ideally, we would like to send a joint message for
            // edges and nodes here, but component calls setProps individually

            // console.log("EVENT", x);
            if (x.selectedNodeData) {
              // console.log("NODE UPDATE", x.selectedNodeData.map(n => n.id));
              // console.log(x);

              // new-style dispatch
              if (dispatchSelection) {
                const sites = x.selectedNodeData.map((n) => parseSiteId(n.id));
                dispatchSelection({
                  action: "setAll",
                  sites: sites.map((site) =>
                    Map({
                      i: site.pos,
                      segment_i: site.segment,
                    })
                  ),
                });
              }

              // old-style dispatch
              if (dispatchSelectionOld) {
                dispatchSelectionOld({
                  action: "setAll",
                  sites: x.selectedNodeData.map((n) => n.id),
                });
              }
            }
            if (x.selectedEdgeData) {
              // console.log("EDGE UPDATE",  x.selectedEdgeData.map(e => [e.source, e.target]));
              // console.log(x);
              // TODO: dispatch from/to

              // new-style dispatch
              if (dispatchSelection) {
                const pairs = x.selectedEdgeData.map((e) => ({
                  source: parseSiteId(e.source),
                  target: parseSiteId(e.target),
                }));

                // ensure i < j
                dispatchSelection({
                  action: "setAll",
                  pairs: pairs.map((pair) =>
                    pair.source.pos < pair.target.pos
                      ? Map({
                          i: pair.source.pos,
                          segment_i: pair.source.segment,
                          j: pair.target.pos,
                          segment_j: pair.target.segment,
                        })
                      : Map({
                          i: pair.target.pos,
                          segment_i: pair.target.segment,
                          j: pair.source.pos,
                          segment_j: pair.source.segment,
                        })
                  ),
                });
              }

              // old-style dispatch
              if (dispatchSelectionOld) {
                dispatchSelectionOld({
                  action: "setAll",
                  pairs: x.selectedEdgeData.map((e) => [e.source, e.target]),
                });
              }
            }
          }}
        />
      </ResizeSensor>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ButtonGroup minimal={true}>
          <Button
            icon="locate"
            title="Center view"
            onClick={() =>
              cyRef.current
                ? cyRef.current.center(
                    // (selection.sites.length > 0) || (selection.sites.size > 0) ? selection.sites : null
                    selectorIds
                  )
                : null
            }
          ></Button>
          <Button
            icon="zoom-to-fit"
            title="Fit view"
            onClick={() =>
              cyRef.current
                ? cyRef.current.fit(
                    // (selection.sites.length > 0) || (selection.sites.size > 0) ? selection.sites : null
                    selectorIds
                  )
                : null
            }
          ></Button>
          <Button
            icon={IconNames.RESET}
            title="Reset view and layout"
            onClick={() =>
              cyRef.current ? cyRef.current.layout(graphLayout) : null
            }
          ></Button>
          <Button
            title="Clear selection"
            icon={RESET_SELECTION_ICON}
            onClick={() => {
              // old-style dispatch
              if (dispatchSelectionOld) {
                dispatchSelectionOld({ action: "reset" });
              }

              // new-style dispatch
              if (dispatchReset) {
                dispatchReset();
              }
            }}
          />
          <Divider />
          <Button
            title="Force-directed layout"
            icon="layout"
            onClick={() => setGraphLayout(LAYOUT_COSE_SETTINGS)}
          />
          <Button
            title="Random layout"
            icon="layout-auto"
            onClick={() => setGraphLayout({ name: "random" })}
          />
          <Button
            title="Circle layout"
            icon="layout-circle"
            onClick={() => setGraphLayout({ name: "circle" })}
          />
          <Button
            title="Grid layout"
            icon="layout-grid"
            onClick={() => setGraphLayout({ name: "grid" })}
          />
          <Divider />
          <Button
            title="Export image"
            icon="import"
            onClick={() =>
              cyRef.current
                ? cyRef.current
                    .exportPNG({
                      output: "blob-promise",
                      scale: IMAGE_SIZE_FACTOR,
                    })
                    .then((x) => {
                      // console.log(x);
                      saveAs(x, exportFilename);
                    }, null)
                : null
            }
          />
          <Divider />
          {renderLegend(cumulativeCouplings, colorMapper, true)}
          {renderCountTag()}
        </ButtonGroup>
      </div>
    </>
  );
};
