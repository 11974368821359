import React, { Component } from "react";

import "./Plotly.css"; // hide panels
import Plotly from "plotly.js-gl2d-dist";
import createPlotlyComponent from "react-plotly.js/factory";
const Plot = createPlotlyComponent(Plotly);

/*
    Export figure using unique div handle, see
    https://github.com/plotly/react-plotly.js/issues/111
*/
export const saveImage = (divId, options) => {
  /*{
      format: "png",
      width: 2400,
      height: 1800,
      filename: "plot"
  }*/
  Plotly.downloadImage(divId, options);
};

/*
    Use class-component based wrapper for plotly to avoid
    problem that event handlers for plotly react component
    can only be set once and are not updated as props are changed,
    i.e. hooks lead to closure from first render being used
    on subsequent rerenders.

    - https://github.com/plotly/react-plotly.js/pull/151
    - https://github.com/plotly/react-plotly.js/issues/150
*/
class PlotlyWrapper extends Component {
  handleClick(event) {
    if (this.props.onClick) {
      this.props.onClick(event);
    }
  }

  handleHover(event) {
    if (this.props.onHover) {
      this.props.onHover(event);
    }
  }

  handleUnhover(event) {
    if (this.props.onUnhover) {
      this.props.onUnhover(event);
    }
  }

  handleDoubleClick(event) {
    if (this.props.onDoubleClick) {
      this.props.onDoubleClick(event);
    }
  }

  render() {
    return (
      <Plot
        {...this.props}
        onClick={event => this.handleClick(event)}
        onHover={event => this.handleHover(event)}
        onUnhover={event => this.handleUnhover(event)}
        onDoubleClick={event => this.handleDoubleClick(event)}
      />
    );
  }
}

export default PlotlyWrapper;
