import React, { useState } from "react";
import { Button, Collapse, Icon } from "@blueprintjs/core";
import { Map } from "immutable";
import createPanel from "./Panel";
import { apiServerAddBaseUrl } from "../../utils/Api";
import { helpIcon } from "../common/Helpers";
import { ErrorRefetcher } from "../common/Helpers";

const FILES_TO_SHOW = [
  {
    title: "Bundled download",
    files: [
      {
        key: "archive_file",
        title: "ZIP archive file with all results"
        // description: "zip archive file",
      }
    ]
  },
  {
    title: "Evolutionary couplings",
    files: [
      {
        key: "ec_file",
        title: "Couplings file (all pairs, no distance information)",
        excludeIf: "ec_compared_all_file"
      },
      {
        key: "ec_compared_all_file",
        title: "Couplings file (all pairs, with distance information)"
      },
      {
        key: "ec_longrange_file",
        title: "Couplings file (long-range pairs, no distance information)",
        excludeIf: "ec_compared_longrange_file"
      },
      {
        key: "ec_compared_longrange_file",
        title: "Couplings file (long-range pairs, with distance information)"
      },
      {
        key: "contact_map_files",
        title: "Contact map plots"
      },
      {
        key: "enrichment_file",
        title: "Strongly coupled residues"
      },
      {
        key: "evzoom_file",
        title: "EVzoom JSON file",
        description: "Can be visualized using evzoom.org"
      },
      {
        key: "model_file",
        title: "EVcouplings model parameters",
        description: "Binary file, for use with EVcouplings python package"
      }
    ]
  },
  {
    title: "Sequence alignment",
    files: [
      {
        key: "alignment_file",
        title: "Sequence alignment",
        description:
          "Aligned FASTA format distinguishing between match and other states (a2m). Note that inserts relative to target sequence are removed."
      },
      {
        key: "target_sequence_file",
        title: "Target sequence region used for computing alignment"
      },
      {
        key: "frequencies_file",
        title: "Positional amino acid frequencies/conservation"
      }
    ]
  },
  {
    title: "Mutation effects",
    files: [
      {
        key: "mutation_matrix_file",
        title: "Predicted single substitution effects"
      },
      {
        key: "mutation_matrix_plot_files",
        title: "Single substitution matrix plots"
      }
    ]
  },
  {
    title: "3D structure predictions",
    files: [
      {
        key: "folded_structure_files",
        title: "Predicted 3D models"
      },
      {
        key: "folding_ranking_file",
        title: "Ranking of predicted structure based on quality score"
      },
      {
        key: "folding_comparison_file",
        title: "Comparison of predicted models to known structures"
      },
      {
        key: "sec_struct_file",
        title: "Predicted secondary structure"
      },
      {
        key: "folding_ec_file",
        title: "Filtered evolutionary couplings used as input for folding"
      }
    ]
  },
  {
    title: "Experimental 3D structures (for result evaluation)",
    files: [
      {
        key: "renumbered_pdb_files",
        title: "Homologous 3D structures remapped to target numbering"
      },
      {
        key: "remapped_pdb_files",
        title:
          "Homologous 3D structures remapped to target numbering and reidue types (backbone only)"
      },
      {
        key: "pdb_structure_hits_file",
        title: "Summary table of identified homologous 3D structures"
      }
    ]
  }
];

export const DownloadsPanel = ({ downloadsData }) => {
  // stores which panels for multi-file lists ("_files") are expanded or not
  // (note that initially, before panel is toggled by user, undefined entries mean panel is closed)
  const [openPanels, setOpenPanels] = useState(Map());

  // proper data structure with all files
  const availableFiles =
    downloadsData && downloadsData.data && downloadsData.data.files
      ? Map(downloadsData.data.files)
      : null;

  const renderSection = (section, sectionIdx) => {
    // only show files actually available for download from API
    const filesToShow = section.files.filter(
      file =>
        availableFiles.has(file.key) &&
        !(file.excludeIf && availableFiles.has(file.excludeIf))
    );

    const fileItems = filesToShow.map((file, idx) => {
      if (file.key.endsWith("_file")) {
        // single file
        // return file.key;
        return (
          <li key={file.key}>
            <a
              className="bp3-button bp3-minimal bp3-icon-blank"
              href={apiServerAddBaseUrl(availableFiles.get(file.key).url)}
            >
              {file.title}
            </a>
            {file.description ? helpIcon(file.description) : null}
          </li>
        );
      } else {
        // multiple files under one key
        const listItems = availableFiles.get(file.key).map((file, idx) => (
          <li key={idx}>
            <Icon icon="blank" />
            <Icon icon="blank" />
            <a
              className="bp3-button bp3-minimal"
              href={apiServerAddBaseUrl(file.url)}
            >
              {file.filename}
            </a>
          </li>
        ));

        const isOpen = openPanels.get(file.key);

        return (
          <li key={file.key}>
            <Button
              minimal={true}
              icon={isOpen ? "caret-down" : "caret-right"}
              onClick={() => setOpenPanels(openPanels.set(file.key, !isOpen))}
            >
              {file.title}
            </Button>
            {file.description ? helpIcon(file.description) : null}
            <Collapse isOpen={isOpen}>
              <ol className="bp3-list-unstyled">{listItems}</ol>
            </Collapse>
          </li>
        );
      }
    });

    // do not render sections that are empty (e.g. folding if no folding files available)
    if (fileItems.length > 0) {
      return (
        <div key={sectionIdx} style={{ marginBottom: "1em" }}>
          <h4>{section.title}</h4>
          <ol className="bp3-list-unstyled">{fileItems}</ol>
        </div>
      );
    } else {
      return <></>;
    }
  };

  let panelContent;
  let isLoading = !availableFiles && !downloadsData.error;

  if (downloadsData.error) {
    // ErrorRefetcher
    panelContent = (
      <ErrorRefetcher
        refetcher={() => {
          if (downloadsData.refetch) {
            downloadsData.refetch();
          }
        }}
      />
    );
  } else {
    if (isLoading) {
      panelContent = <div></div>;
    } else {
      const sectionContent = FILES_TO_SHOW.map(renderSection);
      panelContent = (
        <div style={{ marginLeft: "2em", marginTop: "2em" }}>
          {sectionContent}
        </div>
      );
    }
  }

  return createPanel(
    panelContent,
    {
      width: "95vw",
      alignItems: "right",
      justifyContent: "top",
      overflowX: isLoading ? "hidden": "auto",
      overflowY: isLoading ? "hidden" : "auto"
    },
    isLoading
  );
  //return panelContent;
};
