// import _ from "lodash";
import axios from "axios";
import {
  API_BASE_URL,
  API_DOMAIN_URL,
  API_SERVER_URL,
  JOB_STATUS,
  DOMAIN_SCAN_POLLING_INTERVAL,
} from "./Constants";

export function apiJobgroupEndpoint(jobgroupID) {
  // note slash at end - or Safari will fail with CORS redirect error
  return API_BASE_URL + jobgroupID + "/";
}

export function apiServerAddBaseUrl(relativeUrl) {
  return API_SERVER_URL + relativeUrl;
}

export const frontendJobgroupEndpoint = (jobgroupID) => {
  return "/results/" + jobgroupID;
};

export const apiSubmitJob = (config, onSubmit, onSuccess, onFail) => {
  // console.log("RAW CONFIG", config);   // TODO: remove

  let data = {
    target: {
      // sequence_id: "RASH_HUMAN",  // set conditionally below
      sequence: config.sequence,
      first_index: config.firstIndex,
      region_start: config.sequenceRangeStart,
      region_end: config.sequenceRangeEnd,
    },
    align_search: {
      use_bitscores: config.bitscore,
      thresholds: config.bitscore
        ? config.bitscoreThresholds
        : config.eValueThresholds,
      iterations: config.alignmentIterations,
      database: config.sequenceDatabase,
    },
    align_postprocessing: {
      minimum_column_coverage: config.minimumColumnCoverage / 100,
      minimum_sequence_coverage: config.minimumSequenceCoverage / 100,
      theta: config.theta / 100,
    },
    couplings: {
      inference_method: config.inferenceMethod,
    },
    compare: {
      distance_cutoff: config.compareDistanceThreshold,
      pdb_alignment_method: config.compareAlignmentMethod,
      alignment_threshold: config.compareAlignmentTreshold,
    },
    fold: {
      run_folding: config.runFold,
      num_models: config.numModels,
    },
    user: {
      // job_name: "Some user job", // set below if defined
      // email: "thomas.hopf@gmail.com" // set below if defined
    },
  };

  // substitute optional fields
  if (config.identifier) {
    data["target"]["sequence_id"] = config.identifier;
  }

  // only set identity cutoff if below redundancy cutoff of database
  if (
    config.sequenceIdentityFilter &&
    config.sequenceIdentityFilter < config.databaseRedundancyCutoff
  ) {
    // don't divide by 100!
    data["align_postprocessing"]["sequence_identity_filter"] =
      config.sequenceIdentityFilter;
  }

  if (config.email) {
    data["user"]["email"] = config.email;
  }

  // substitute job name
  if (config.jobName) {
    data["user"]["job_name"] = config.jobName;
  }

  // console.log("PREPARED CONFIG", data); // TODO: remove

  // trigger submission status update
  if (onSubmit) {
    onSubmit();
  }

  // execute submission POST request
  axios({ url: API_BASE_URL, method: "post", data: data })
    .then((response) => {
      if (onSuccess && response.data.job_group_id) {
        // TODO: pass result URL here
        const resultRoute = frontendJobgroupEndpoint(
          response.data.job_group_id
        );
        onSuccess(resultRoute);
      }
    })
    .catch((error) => {
      if (error.response) {
        // response with non-2XX status code
        let message;
        // console.log("ERROR", error.response); // TODO: remove

        if (error.response.data) {
          message = error.response.data.message;
        } else {
          message = "Invalid submssion, please contact support.";
        }

        if (onFail) {
          onFail(message);
        }
      } else if (error.request) {
        // no response
        if (onFail) {
          onFail(
            "Server could not be reached for submission, please try again later or contact support."
          );
        }
      }
    });
};

const retrieveDomainScanResults = (
  url,
  fetcher,
  onResult,
  onError,
  interval,
  initialPoll
) => {
  const executePoll = () =>
    setTimeout(
      () =>
        retrieveDomainScanResults(
          url,
          fetcher,
          onResult,
          onError,
          interval,
          false
        ),
      interval
    );

  if (initialPoll) {
    executePoll();
    return;
  }

  fetcher.query(
    url,
    (res) => {
      if (res.status === 200 && res.data) {
        onResult(res.data);
      } else {
        // 202, continue polling
        executePoll();
      }
    },
    (err) => {
      if (onError) {
        onError(err);
      }
    },
    (err) => {
      if (onError) {
        onError(err);
      }
    }
  );
};

/*
  Start Pfam domain scanning and poll for results
*/
export const apiDomainScan = (
  sequence,
  identifier,
  fetcher,
  onResult,
  onError
) => {
  // TODO: debounce call (if user starts typing sequence...)?
  fetcher.query(
    API_DOMAIN_URL,
    (res) => {
      // get location from data since headers may not be available in CORS setting
      if (res.data) {
        // assemble result location
        let resultLocation = apiServerAddBaseUrl(res.data.location);

        // start polling for results
        retrieveDomainScanResults(
          resultLocation,
          fetcher,
          onResult,
          onError,
          DOMAIN_SCAN_POLLING_INTERVAL,
          true
        );
      } else {
        // TODO: think about a meaningful else case here...
      }
    },
    // handleResponseError
    (err) => {
      if (onError) {
        onError(err);
      }
    },
    // handleRequestError
    (err) => {
      if (onError) {
        onError(err);
      }
    },
    {
      method: "post",
      data: {
        // do not submit sequence id since it may be null (which causes problems with API)
        sequence: sequence,
      },
    }
  );

  // TODO: implement polling for results
};

export const apiExperimentalStructuresQuery = (
  endpoint,
  contactDistanceCutoff,
  structureSet
) => {
  let query = "";
  if (contactDistanceCutoff) {
    query += "contact_distance_cutoff=" + contactDistanceCutoff;
  }

  // custom structure set (must be list of structure IDs)
  if (structureSet) {
    query += (query ? "&" : "") + "structure_ids=" + structureSet.join(",");
  }

  return endpoint + (query ? "?" + query : "");
};

/*
   API input format
    {
      "mutations": [
        {
          "mutant": "A100V,C150G",
          "segment": "A_1,B_1"
        }
      ]
    }

   API output format:
    * segment only returned if given
    * format:
    {
        "mutation_predictions": [
            {
                "mutant": "A100V,C150G",
                "segment": "A_1,B_1",
                "prediction_epistatic": NaN,
                "prediction_independent": NaN
            }
        ]
    }

*/
export const apiSubmitCustomMutationPrediction = (
  endpoint,
  mutations,
  onSuccess,
  onFail
) => {
  // prepare payload
  const data = {
    mutations: mutations.map((m) => ({ mutant: m })),
  };

  const url = apiServerAddBaseUrl(endpoint);

  // execute submission POST request
  axios({ url: url, method: "post", data: data })
    .then((response) => {
      if (onSuccess && response.data.result_location) {
        const resultRoute = apiServerAddBaseUrl(response.data.result_location);
        onSuccess(resultRoute);
      }
    })
    .catch((error) => {
      // console.log("+++ ERROR", error.response);

      if (error.response) {
        // response with non-2XX status code
        let message;
        // console.log("ERROR", error.response); // TODO: remove

        if (error.response.data) {
          message = error.response.data.message;
        } else {
          message = "Invalid submssion, please contact support.";
        }

        if (onFail) {
          onFail(message);
        }
      } else if (error.request) {
        // no response
        if (onFail) {
          onFail(
            "Server could not be reached for submission, please try again later or contact support."
          );
        }
      }
    });
};

export const apiPollMutationPrediction = (url, onSuccess, onError) => {
  // execute submission POST request
  axios({ url: url, method: "get" })
    .then((response) => {
      // console.log("+++ RESPONSE", response);
      if (response.status === 200) {
        if (onSuccess && response.data) {
          onSuccess(response.data.mutation_predictions);
        }
      } else if (response.status === 202) {
        // still running, no need to update anything
        // console.log("+++ STILL RUNNING");
      }
    })
    .catch((error) => {
      // console.log("+++ ERROR", error);
      // error response -> means job failed
      if (error.response) {
        // response with non-2XX status code
        let message;

        if (error.response.data) {
          message = error.response.data.message;
        } else {
          message = "Invalid response, please contact support.";
        }

        if (onError) {
          onError(message);
        }
      } else if (error.request) {
        // no response
        // don't do anything here and try to continue polling...
      }
    });
};

/*
  Create dictionary position -> symbol/residue
  from "target" section of job info
*/
export const createTargetSequenceMap = (target) => {
  let targetMap = new Map();
  [...target.sequence].forEach((symbol, idx) => {
    const curIndex = target.first_index + idx;
    // only include positions that were actually run
    if (
      (!target.region_start || curIndex >= target.region_start) &&
      (!target.region_end || curIndex <= target.region_end)
    ) {
      targetMap.set(curIndex, symbol);
    }
  });
  return targetMap;
};

// hand-fitted function for now as a hack
// TODO: use something proper here!
export const QUALITY_SCORE_TRANSFORM = (score) => {
  if (score < 0.35) {
    return 0;
  } else {
    if (score > 0.9) {
      return 1;
    } else {
      return (20 / 11) * score - (0.35 * 20) / 11;
    }
  }
};

/*
  Transform data returned by REST API into
  format expected by job group view
  (for now, eventually should make the same)
*/
export const transformJobgroupData = (data) => {
  // determine maximum number of expected TP / L,
  // this is selected as recommended run
  let maxScore = Math.max(
    ...data.jobs
      .map(
        (j) =>
          j.result_summary.expected_true_ecs_longrange /
          j.result_summary.num_sites
      )
      .filter((score) => score)
  );

  // transform individual compute jobs
  let jobs = data.jobs.map((j) => {
    const qualityScoreRaw =
      j.result_summary.expected_true_ecs_longrange / j.result_summary.num_sites;
    const qualityScoreTransformed = QUALITY_SCORE_TRANSFORM(qualityScoreRaw);

    let qualityScore = Math.min(Math.floor(qualityScoreTransformed * 10), 10);

    // check if this run has score as high as maximum (need to also check score is actually defined)
    let isRecommendedRun =
      j.status === JOB_STATUS.DONE &&
      j.result_summary.expected_true_ecs_longrange &&
      j.result_summary.expected_true_ecs_longrange /
        j.result_summary.num_sites >=
        maxScore &&
      data.final &&
      maxScore > 0;

    let message = null;
    if (j.status === JOB_STATUS.RUN) {
      let stageIndex = j.all_stages.indexOf(j.stage);
      message = `${j.stage} (${stageIndex + 1}/${j.all_stages.length})`;
    }

    // total number of positions covered by alignment
    let coveredLength;
    if (j.result_summary.alignment_coverage_segments) {
      coveredLength = j.result_summary.alignment_coverage_segments.reduce(
        (acc, cur) => acc + (cur.end - cur.start + 1),
        0
      );
    }

    return {
      id: j.id,
      bitscore: j.config_summary.align_use_bitscores,
      threshold: j.config_summary.align_domain_threshold + "",
      status: j.status,
      message: message,
      segments: j.result_summary.alignment_coverage_segments,
      coveredLength: coveredLength,
      archiveURL: apiServerAddBaseUrl(j.links.archive_file),
      qualityScore: qualityScore,
      recommendedRun: isRecommendedRun,
      validSequences: j.result_summary.num_valid_sequences,
      neff: j.result_summary.effective_sequences,
      neffOverL:
        j.result_summary.effective_sequences / j.result_summary.num_sites,
    };
  });

  // TODO: determine recommended run
  // TODO: only once all runs have finished

  // transform Pfam domain annotation information
  let domains;
  if (data.domains) {
    domains = data.domains.map((domain) => ({
      identifier: domain.pfam_id,
      name: domain.pfam_name,
      start: domain.ali_from,
      end: domain.ali_to,
      score: domain.bitscore.toString(),
      eValue: domain.evalue.toString(),
      numSequences: domain.num_seqs,
      numStructures: domain.num_pdb_structures,
      structures: domain.pdb_structures,
    }));
  } else {
    domains = [];
  }

  // compute sequence region information
  let regionStart = data.target.region_start
    ? data.target.region_start
    : data.target.first_index;

  let regionEnd = data.target.region_end
    ? data.target.region_end
    : data.target.first_index + data.target.sequence.length - 1;

  // extract sequence in region from full sequence returned by API
  let sequence = data.target.sequence.substring(
    regionStart - data.target.first_index,
    regionEnd - data.target.first_index + 1
  );

  // make sure jobs are sorted, depending on bitscore or E-value threshold
  jobs.sort((a, b) => parseFloat(a.threshold) - parseFloat(b.threshold));

  // overall jobgroup dictionary
  let t = {
    pipeline: data.pipeline,
    jobGroupName: data.name,
    // TODO: solve more robustly; we know that all jobs coming from API have same type
    // of threshold
    bitscore: data.jobs[0].config_summary.align_use_bitscores,
    query: {
      sequence: sequence,
      sequenceStart: regionStart,
      sequenceEnd: regionEnd,
      domains: domains,
    },
    jobs: jobs,
  };

  return t;
};
