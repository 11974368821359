export function joinURL(...components) {
    return components.join("/");
}

export function urlJobGroup(jobGroup) {
    return joinURL("/results", jobGroup);
}

export function urlJob(jobGroup, job) {
    return joinURL(urlJobGroup(jobGroup), job);
}
