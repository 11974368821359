import React, { Component } from "react";
// import { xml2js } from "xml-js";
import SequenceInputBox from "./SequenceInputBox";
import DomainSelectionSlider from "./DomainSelectionSlider";
import { helpTooltip } from "../common/Helpers";
import { AlignmentSettings } from "./AdvancedSettingsAlignment";
import { apiDomainScan, apiSubmitJob } from "./../../utils/Api";
import { validEmail } from "./../../utils/Helpers";

import {
  CouplingsSettings,
  FoldingSettings,
  ComparisonSettings
} from "./AdvancedSettingsOther";

import {
  FIRST_INDEX_HELP_TEXT,
  MIN_SEQUENCE_LENGTH,
  MAX_SEQUENCE_LENGTH,
  DEFAULT_PARAMS
} from "../../utils/Constants";
import { RepeatedDataFetcher } from "../../utils/Queries";
import { Redirect } from "react-router-dom";

import {
  Button,
  Classes,
  Collapse,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
  Spinner,
  Tab,
  Tabs,
  Tag,
  Card,
  NumericInput,
  Icon,
  // Callout
} from "@blueprintjs/core";

const SUBMISSION_STATES = {
  NONE: 0,
  RUNNING: 1,
  SUCCESS: 2,
  FAILED: 3
};

// TODO: clear up wrong usage of setting state based on previous state in handleFirstIndexValueChange()
// (https://reactjs.org/docs/state-and-lifecycle.html#state-updates-may-be-asynchronous)

class JobSubmissionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      advancedSettingsOpen: false,
      submission: SUBMISSION_STATES.NONE
    };

    // add default parameters
    Object.assign(this.state, DEFAULT_PARAMS);

    // fetcher for retrieving Pfam annotations
    this.fetcher = new RepeatedDataFetcher();
  }

  handleFirstIndexValueChange = (valueAsNumber, valueAsString) => {
    if (!valueAsString) {
      this.setState({
        firstIndexFieldValue: NumericInput.VALUE_EMPTY
      });
    } else {
      if (
        valueAsString.includes(".") ||
        valueAsString.startsWith("0") ||
        valueAsString.toLowerCase().includes("e") ||
        valueAsString.includes("-")
      ) {
        // do nothing... but somehow have to set state or otherwise changes still show
        // up in textbox
        this.setState({
          firstIndexFieldValue: this.state.firstIndexFieldValue,

          // dirty trick to create new component... otherwise invalid internal state
          // of numeric input overrides props (see note below)
          firstIndexFieldKey: this.state.firstIndexFieldKey + 1
        });
      } else {
        // compute offset between old and new first index to adjust selected range
        let offset = valueAsNumber - this.state.firstIndex;

        // adjust domain boundaries too... the ugly way
        let domains = JSON.parse(JSON.stringify(this.state.domains));
        for (let i = 0; i < domains.length; i++) {
          domains[i].start += offset;
          domains[i].end += offset;
        }

        this.setState({
          firstIndexFieldValue: valueAsNumber,
          firstIndex: valueAsNumber,
          sequenceRangeStart: this.state.sequenceRangeStart + offset,
          sequenceRangeEnd: this.state.sequenceRangeEnd + offset,
          domains: domains
        });
      }
    }
  };

  renderFirstIndexSlider() {
    // note: set key on NumericInput to force new component being created
    // on each render; this appears to be the only way out of the problem
    // that the component is not fully controlled and does not override
    // its internal value in state unless the value prop changes; i.e.
    // there is no way to avoid characters like "."", "e", or "-"
    // otherwise. Unfortunately, this means component loses focus...
    if (this.state.sequence && !this.state.downloadedSequence) {
      return (
        <div
          style={{
            display: "flex",
            marginTop: "1.0em",
            marginBottom: "1.0em",
            order: "row",
            justifyContent: "center",
            alignItems: "baseline"
          }}
        >
          <FormGroup
            label="Start numbering the sequence from "
            labelFor="first-index-input"
            inline={true}
          >
            <div style={{ maxWidth: "60pt", minWidth: "50pt" }}>
              <NumericInput
                id="first-index-input"
                key={this.state.firstIndexFieldKey}
                intent={
                  this.state.firstIndexFieldValue ? Intent.NONE : Intent.DANGER
                }
                placeholder=""
                value={this.state.firstIndexFieldValue}
                min={1}
                onValueChange={this.handleFirstIndexValueChange}
                onButtonClick={this.handleFirstIndexValueChange}
                fill={true}
              />
            </div>
          </FormGroup>
          &emsp;
          {helpTooltip(<Icon icon="help" />, FIRST_INDEX_HELP_TEXT)}
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            marginTop: "1.0em",
            marginBottom: "1.0em",
            minHeight: "2pt",
            order: "rows",
            justifyContent: "center",
            alignContent: "center"
          }}
        />
      );
    }
  }

  /*
  parsePfamDomains(pfamXml) {
    let anno = xml2js(pfamXml, { compact: true });
    let domains = [];
    // console.log(JSON.stringify(anno));
    if (
      anno.pfam &&
      anno.pfam.entry &&
      anno.pfam.entry.matches &&
      anno.pfam.entry.matches.match
    ) {
      let match = anno.pfam.entry.matches.match;
      // console.log(JSON.stringify(match));

      // if only single element, turn into list
      if (!Array.isArray(match)) {
        match = [match];
      }

      domains = match.map(m => {
        return {
          identifier: m._attributes.accession,
          name: m._attributes.id,
          start: Number.parseInt(m.location._attributes.start, 10),
          end: Number.parseInt(m.location._attributes.end, 10),
          score: m.location._attributes.bitscore,
          eValue: m.location._attributes.evalue,
          numSequences: "n/a"
        };
      });

      // TODO: sort? - already sorted in XML
      // TODO: overlaps...
    }
    // console.log(domains);

    return domains;
  }
  */

  updateSequence = (sequence, identifier, downloadedSequence) => {
    // can only set domains for downloaded sequences for now without own API
    let domains = [];
    let domainsLoading = false;
    // let domainsMessage =
    //   "(Domain annotations only available for identifier input until backend ready)";
    let domainsMessage;

    // abort any previous domain loading attempts
    this.fetcher.cancel();

    if (sequence) {
      apiDomainScan(
        sequence,
        identifier,
        this.fetcher,
        data => {
          let parsedDomains = [];
          if (data.pfam_hits) {
            // reformat API response into data structure used by component
            parsedDomains = data.pfam_hits.map(d => ({
              identifier: d.pfam_id,
              name: d.pfam_name,
              start: d.ali_from,
              end: d.ali_to,
              eValue: d.evalue,
              numSequences: d.num_seqs,
              numStructures: d.num_pdb_structures,
              structures: d.pdb_structures
            }));
          } else {
            // TODO: think about else case...
          }
          /*
          // internally expected format
          {
            "identifier": "PF10608",
            "name": "MAGUK_N_PEST",
            "start": 29,
            "end": 64,
            "score": "38.50",
            "eValue": "3.3e-06",
            "numSequences": "n/a"
          },*/
          /*
          // API format
            {
              "pfam_id": "PF00071",
              "pfam_name": "Ras",
              "pfam_text": "Ras family",
              "clan_id": "CL0023",
              "clan_name": "P-loop_NTPase",
              "num_seqs": 62266,
              "hmm_length": 162,
              "ali_from": 5,
              "ali_to": 164,
              "bitscore": 197.4,
              "evalue": 1.1e-58
            }
          */

          this.setState({
            domainsLoading: false,
            domainsMessage: null,
            domains: parsedDomains
          });
        },
        err => {
          this.setState({
            domainsLoading: false,
            domainsMessage: "Failed to retrieve domain annotations"
          });
        }
      );

      // set loading state for state update further down
      domainsLoading = true;
      domainsMessage = "Loading domain annotations";
    }

    /*
    // original Pfam REST-API based retrieval of sequences 
    // (not applicable for sequence-based rather than id-based input)
    if (downloadedSequence) {
      domains = [];
      domainsLoading = true;
      domainsMessage = "Loading domain annotations";

      // start download of annotations
      this.fetcher.query(
        "http://pfam.xfam.org/protein/" + identifier + "?output=xml",
        res => {
          if (res.data) {
            // console.log("Got domain annotation");
            let parsedDomains = this.parsePfamDomains(res.data);

            // sort in ascending order
            parsedDomains = parsedDomains.sort((a, b) => a.start - b.start);
            this.setState({
              domainsLoading: false,
              domainsMessage: null,
              domains: parsedDomains
            });
          } else {
            // console.log("Empty domain annotation");
            this.setState({
              domainsLoading: false,
              domainsMessage: "No content in domain annotations"
            });
          }
        },
        err => {
          this.setState({
            domainsLoading: false,
            domainsMessage:
              "Cannot load domain annotations (invalid identifier)"
          });
        },
        err => {
          this.setState({
            domainsLoading: false,
            domainsMessage: "Cannot load domain annotations (server down)"
          });
        }
      );
    } else {
      // have to live with defaults from above for now
      // domains = exampleDomains;
      // domainsLoading = false;
      // domainsMessage = null;
    }
    */

    this.setState({
      sequence: sequence,
      identifier: identifier,
      downloadedSequence: downloadedSequence,
      firstIndex: 1,
      firstIndexFieldValue: 1,
      firstIndexFieldKey: 1,
      sequenceRangeStart: sequence ? 1 : null,
      sequenceRangeEnd: sequence ? sequence.length : null,
      invalidRangeMessage: sequence
        ? this.evaluateRangeSetting([1, sequence.length])
        : null,
      domains: domains,
      domainsMessage: domainsMessage,
      domainsLoading: domainsLoading
    });
  };

  evaluateRangeSetting = range => {
    let rangeLength = range[1] - range[0] + 1;
    let invalidRangeMessage = null;

    if (rangeLength < MIN_SEQUENCE_LENGTH) {
      invalidRangeMessage =
        "Selected range is too short (minimum length: " +
        MIN_SEQUENCE_LENGTH +
        ")";
    } else if (rangeLength > MAX_SEQUENCE_LENGTH) {
      invalidRangeMessage =
        "Selected range is too long (maximum length: " +
        MAX_SEQUENCE_LENGTH +
        ")";
    }

    return invalidRangeMessage;
  };

  handleRangeChange = range => {
    this.setState({
      sequenceRangeStart: range[0],
      sequenceRangeEnd: range[1],
      invalidRangeMessage: this.evaluateRangeSetting(range)
    });
  };

  // TODO: remove this eventually
  updateParam = (paramName, newValue) => {
    this.setState(state => {
      let newObj = {};
      newObj[paramName] = newValue;
      return newObj;
    });
  };

  updateParamByFunc = updaterFunc => {
    this.setState(state => updaterFunc(state));
  };

  render() {
    // determine if any of the structures has 3D info
    const hasStructureInfo =
      this.state.domains && this.state.domains.some(v => v.numStructures > 0);

    // return (<div><Callout title="Under maintenance" intent={Intent.WARNING}>
    // Dear users, due to the recent retirement of a key external dependency (RCSB MMTF server) our webserver cannot process new job 
    // submissions at this time. We apologize for the inconvenience and are urgently working on fixing the issue.
    // </Callout></div>);

    return (
      <div>
        <SequenceInputBox handleSequenceChange={this.updateSequence} />
        {this.renderFirstIndexSlider()}

        {this.state.sequence ? (
          <div style={{ marginTop: "2em" }}>
            <DomainSelectionSlider
              sequence={this.state.sequence}
              sequenceStart={this.state.firstIndex}
              sequenceEnd={
                this.state.firstIndex + this.state.sequence.length - 1
              }
              rangeStart={this.state.sequenceRangeStart}
              rangeEnd={this.state.sequenceRangeEnd}
              handleValueChange={this.handleRangeChange}
              domainOverhang={5}
              domains={this.state.domains}
              invalidSelectionMessage={this.state.invalidRangeMessage}
              domainsMessage={this.state.domainsMessage}
              domainsLoading={this.state.domainsLoading}
            />
          </div>
        ) : null}

        {/* {hasStructureInfo ? (
          <Tag
            icon="info-sign"
            // intent={Intent.PRIMARY}
            minimal={true}
            large={true}
            style={{ marginTop: "0.5em" }}
          >
            3D structure information exists for your sequence (check domains)
          </Tag>
        ) : null} */}

        {/* <Tag
          icon="info-sign"
          // intent={Intent.PRIMARY}
          minimal={true}
          large={true}
          multiline={true}
          style={{ marginTop: "0.5em" }}
        >
          Please use <a href="//alphafold.ebi.ac.uk" target="_blank" rel="noopener noreferrer">AlphaFold DB</a> to obtain structure predictions. You can upload the models on the results page of this server to visualize ECs and mutations.
        </Tag> */}

        {this.state.sequence && this.state.identifier && this.state.downloadedSequence? 
          <Tag 
            style={{ marginTop: "0.5em" }}
            minimal={true}
            large={true}
            multiline={true}
            icon="info-sign"
          >
            Existing AlphaFoldDB prediction for target sequence will be made available for comparison on results page.
          </Tag >: null
        }

        {this.state.sequence && this.state.identifier && !this.state.downloadedSequence? 
          <Tag 
            style={{ marginTop: "0.5em" }}
            minimal={true}
            large={true}
            multiline={true}
            icon="warning-sign"
            intent={Intent.WARNING}
          >
            Comparison to AlphaFoldDB prediction on results page only possible if included identifier is from UniProt and if sequences agree.
          </Tag >: null
        }   

        {this.state.sequence && !this.state.identifier ?
          <Tag 
            style={{ marginTop: "0.5em" }}
            minimal={true}
            large={true}
            multiline={true}
            icon="warning-sign"
            intent={Intent.WARNING}
          >
            No sequence identifier included in submission - will not be able to visualize AlphaFoldDB predictions for target.
          </Tag >: null
        }   

        <div style={{ marginTop: "2em" }}>
          <Button
            text={
              (this.state.advancedSettingsOpen ? "Hide" : "Show") +
              " advanced settings"
            }
            minimal={true}
            icon={
              this.state.advancedSettingsOpen ? "caret-down" : "caret-right"
            }
            onClick={() =>
              this.setState({
                advancedSettingsOpen: !this.state.advancedSettingsOpen,
              })
            }
          />

          <Collapse isOpen={this.state.advancedSettingsOpen}>
            <Card>
              <Tabs
                animate={true}
                id="TabsAdvancedSettings"
                vertical={false}
                defaultSelectedTabId="tab_align"
                renderActiveTabPanelOnly={true}
              >
                <Tab
                  id="tab_align"
                  title="Alignments"
                  panel={
                    <AlignmentSettings
                      params={this.state}
                      updateParams={this.updateParamByFunc}
                    />
                  }
                />
                <Tab
                  id="tab_couplings"
                  title="Evolutionary couplings"
                  panel={
                    <CouplingsSettings
                      params={this.state}
                      updateParams={this.updateParamByFunc}
                    />
                  }
                />
                {/* <Tab
                  id="tab_fold"
                  title="Folding"
                  panel={
                    <FoldingSettings
                      params={this.state}
                      updateParams={this.updateParamByFunc}
                    />
                  }
                /> */}
                <Tab
                  id="tab_compare"
                  title="Result evaluation"
                  panel={
                    <ComparisonSettings
                      params={this.state}
                      updateParams={this.updateParamByFunc}
                    />
                  }
                />
                <Tabs.Expander />
              </Tabs>
            </Card>
          </Collapse>
        </div>

        <div
          style={{
            width: "87%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
            marginTop: "2em",
            marginBottom: "2em",
          }}
        >
          <FormGroup
            label="Job name"
            labelFor="jobname-input"
            labelInfo="(optional)"
            inline={true}
          >
            <InputGroup
              id="jobname-input"
              placeholder="Your EVcouplings job"
              onChange={(event) => {
                this.setState({ jobName: event.target.value });
              }}
            />
          </FormGroup>

          <FormGroup
            label="Your e-mail address"
            labelFor="text-input"
            labelInfo="(optional)"
            inline={true}
          >
            <InputGroup
              id="email-input"
              intent={
                this.state.email && !validEmail(this.state.email)
                  ? Intent.DANGER
                  : null
              }
              placeholder="your@email.com"
              onChange={(event) => {
                // console.log(
                //  "MAIL",
                //  event.target.value,
                //  validEmail(event.target.value)
                //); // TODO: remove
                this.setState({ email: event.target.value });
              }}
            />
          </FormGroup>
        </div>
        <Button
          fill={false}
          intent={Intent.PRIMARY}
          disabled={
            !this.state.sequence ||
            !this.state.firstIndexFieldValue ||
            this.state.invalidRangeMessage ||
            (this.state.email && !validEmail(this.state.email))
          }
          loading={false}
          rightIcon="circle-arrow-right"
          onClick={() => this.submitJob()}
        >
          Submit EVcouplings job
        </Button>
        {this.renderSubmissionDialog()}
        {this.state.redirect ? (
          <Redirect push to={this.state.redirect} />
        ) : null}
      </div>
    );
  }

  // carry out job submission through REST API
  submitJob() {
    apiSubmitJob(
      this.state,
      () => {
        this.setState({ submission: SUBMISSION_STATES.RUNNING });
      },
      resultURL => {
        this.setState({
          submission: SUBMISSION_STATES.SUCCESS,
          resultURL: resultURL
        });
      },
      message => {
        this.setState({
          submission: SUBMISSION_STATES.FAILED,
          submissionErrorMessage: message
        });
      }
    );
  }

  renderSubmissionDialog() {
    // if no ongoing submission process, don't show dialog
    if (!this.state.submission) {
      return null;
    }

    let sign;
    let heading;
    let buttons;
    let message;

    if (this.state.submission === SUBMISSION_STATES.RUNNING) {
      heading = "Your job is submitting...";
      sign = <Spinner size={50} />;
    } else if (this.state.submission === SUBMISSION_STATES.SUCCESS) {
      heading = "Your job was submitted.";
      sign = <Icon icon="tick-circle" intent={Intent.NONE} iconSize={50} />;
      if (this.state.email) {
        message =
          "A confirmation e-mail with a link to the results will be sent to " +
          this.state.email;
      }

      buttons = (
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            onClick={() => {
              this.setState({ submission: SUBMISSION_STATES.NONE });
            }}
          >
            Submit another job
          </Button>
          <a
            href={this.state.resultURL}
            role="button"
            onClick={event => {
              event.preventDefault();
              this.setState({ redirect: this.state.resultURL });
            }}
            className="bp3-button bp3-intent-primary"
          >
            Go to results page
          </a>
          {/* Do not use classical button anymore (but through link) to allow display and copying of result page URL */}
          {/* <Button
            onClick={() => {
              this.setState({ redirect: this.state.resultURL });
            }}
            intent={Intent.PRIMARY}
          >
            Go to results page
          </Button> */}
        </div>
      );
    } else {
      // submission failed
      heading = "Job submission failed.";
      sign = <Icon icon="error" intent={Intent.NONE} iconSize={50} />;
      message = this.state.submissionErrorMessage;

      buttons = (
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            onClick={() => {
              this.setState({ submission: SUBMISSION_STATES.NONE });
            }}
            intent={Intent.PRIMARY}
          >
            Close
          </Button>
        </div>
      );
    }

    return (
      <Dialog isOpen={true} onClose={null}>
        <div className={Classes.DIALOG_BODY} style={{ textAlign: "center" }}>
          <h2>{heading}</h2>
          {sign}
          <div style={{ marginTop: "1em" }}>{message}</div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>{buttons}</div>
      </Dialog>
    );
  }
}

export default JobSubmissionForm;
