import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Intent, Spinner, Tag, Colors } from "@blueprintjs/core";
import {
  JOB_STATUS,
  ARCHIVE_HELP_TEXT,
  THRESHOLD_HELP_TEXT,
  STATISTICS_HELP_TEXT,
  COVERAGE_HELP_TEXT,
  JOB_FAIL_HELP_TEXT,
  JOB_TERM_HELP_TEXT,
  JOB_BAILOUT_HELP_TEXT,
  SUPPORT_EMAIL,
  MEFF_HELP_TEXT,
  MEFFOVERL_HELP_TEXT
} from "../../utils/Constants";
import { urlJob } from "../../utils/Links";

import SegmentGraph from "../common/SegmentGraph";
import PfamDomainGraph from "../common/PfamDomainGraph";
import { helpTooltip } from "../common/Helpers";

import "./ResultSummary.css";

const TD_STYLE = { padding: "0px" };
const PADDING = "11px";
const ICON_COLOR = null; // Colors.GRAY1;

/*
Update items to whole-row selection
  // DONE: render icon
  // DONE: add job threshold
  // DONE: refactor depending on animation
  // DONE: center font
  // DONE: change font folor of bitscore threshold to black (instead of link)
  // DONE: fix font size
  // DONE: disable unfinished jobs
  // DONE: remove wrapAsLink method
  // DONE: highlight seleted job (in individual job view)
  // DONE: add legend (separate component)
  // DONE: change bitscore / e-value to "alignment depth threshold"
  // DONE: update columns (split, add full Meff)
  // DONE: update spacing on header (padding) to work in same way as for regular rows
  // DONE: update help texts for sequences
  // DONE: check for running/failed jobs

  (TODO: set icon color to grey?)
*/
export class ResultSummaryView extends Component {
  renderTableHeader() {
    // TODO: render domain graph here if available
    const PADDING_HEADER = { paddingBottom: "11px", paddingRight: PADDING };
    return (
      <thead>
        <tr>
          <th
            style={{
              width: "13%",
              // verticalAlign: "middle",
              ...TD_STYLE,
              ...PADDING_HEADER
            }}
          >
            {this.props.jobGroupData.bitscore ? "Bitscore" : "E-value"}
            &nbsp;
            {helpTooltip(
              <Icon
                icon="help"
                style={{ verticalAlign: "middle" }}
                color={ICON_COLOR}
              />,
              THRESHOLD_HELP_TEXT
            )}
          </th>
          <th
            style={{
              width: "52%",
              // verticalAlign: "middle",
              ...TD_STYLE,
              ...PADDING_HEADER
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
                // backgroundColor: "#ff0000"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginBottom: "5px"
                  // verticalAlign: "middle"
                }}
              >
                Aligment coverage of target&nbsp;
                {helpTooltip(
                  <Icon
                    icon="help"
                    style={{ verticalAlign: "middle" }}
                    color={ICON_COLOR}
                  />,
                  COVERAGE_HELP_TEXT
                )}
              </div>
              <PfamDomainGraph
                sequenceStart={this.props.jobGroupData.query.sequenceStart}
                sequenceEnd={this.props.jobGroupData.query.sequenceEnd}
                domains={this.props.jobGroupData.query.domains}
                connectSegments={true}
                small={true}
                positionBottom={true}
              />
            </div>
          </th>
          <th
            style={{
              width: "13%",
              verticalAlign: "top",
              ...TD_STYLE,
              ...PADDING_HEADER
            }}
          >
            Sequences&nbsp;
            {helpTooltip(
              <Icon
                icon="help"
                style={{ verticalAlign: "middle" }}
                color={ICON_COLOR}
              />,
              MEFF_HELP_TEXT
            )}
          </th>
          <th
            style={{
              width: "10%",
              verticalAlign: "top",
              ...TD_STYLE,
              ...PADDING_HEADER
            }}
          >
            Seqs/L&nbsp;
            {helpTooltip(
              <Icon
                icon="help"
                style={{ verticalAlign: "middle" }}
                color={ICON_COLOR}
              />,
              MEFFOVERL_HELP_TEXT
            )}
          </th>
          <th
            style={{
              width: "8%",
              verticalAlign: "top",
              ...TD_STYLE,
              ...PADDING_HEADER
            }}
          >
            Quality&nbsp;
            {helpTooltip(
              <Icon
                icon="help"
                style={{ verticalAlign: "middle" }}
                color={ICON_COLOR}
              />,
              STATISTICS_HELP_TEXT
            )}
          </th>
          <th
            style={{
              width: "6%",
              verticalAlign: "top",
              ...TD_STYLE,
              ...PADDING_HEADER
            }}
          >
            .zip&nbsp;
            {helpTooltip(
              <Icon
                icon="help"
                style={{ verticalAlign: "middle" }}
                color={ICON_COLOR}
              />,
              ARCHIVE_HELP_TEXT
            )}
          </th>
        </tr>
        {/* 
        <tr>
          <th style={{ verticalAlign: "middle", textAlign: "center" }}>
            <Icon icon="document-open" />
          </th>
          <th style={{ verticalAlign: "middle" }}>
            <PfamDomainGraph
              sequenceStart={this.props.jobGroupData.query.sequenceStart}
              sequenceEnd={this.props.jobGroupData.query.sequenceEnd}
              domains={this.props.jobGroupData.query.domains}
              connectSegments={true}
              small={true}
            />
          </th>
          <th>
            {" "}
            <div style={{ verticalAlign: "middle" }}>
              <Tag
                large={true}
                minimal={true}
                style={{ margin: "0.1em", width: "65px", textAlign: "center" }}
              >
                N<sub>eff</sub>/L
              </Tag>
              <Tag
                large={true}
                style={{ margin: "0.1em", width: "40px", textAlign: "center" }}
              >
                Q
              </Tag>
            </div>
          </th>
          <th />
        </tr> */}
      </thead>
    );
  }

  renderJob(job, index) {
    // render depending if job has already finished, is still running,
    // or crashed/terminated
    let validJob = job.status === JOB_STATUS.DONE;
    const selectedJob = this.props.selectedJob === job.id;

    let jobDownloadButton;

    // have to create button instead of link to deactivate (can't do that for link)
    // (goal is to always have actual links for resources that can be reached)
    if (validJob && job.archiveURL) {
      jobDownloadButton = (
        <a
          download
          href={job.archiveURL}
          className="bp3-button bp3-minimal"
          role="button"
          onClick={event => event.stopPropagation()}
        >
          <Icon icon="import" />
        </a>
      );
    } else {
      jobDownloadButton = (
        <Button icon="import" disabled={true} minimal={true} />
      );
    }

    /*
      Wrap table cell content in Link
    */
    const makeLink = (content, deactivate) => {
      const active = deactivate ? false : validJob;

      const wrap = innerDiv => {
        const style = { color: "inherit", textDecoration: "inherit" };
        if (active) {
          return (
            <Link
              to={urlJob(this.props.jobGroup, job.id)}
              onClick={this.props.handleClick ? this.props.handleClick : null}
              style={style}
            >
              {innerDiv}
            </Link>
          );
        } else {
          return <div style={style}>{innerDiv}</div>;
        }
      };

      return wrap(
        <div
          style={{
            height: "55px",
            // paddingLeft: PADDING,
            paddingRight: PADDING,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "stretch",
            cursor: validJob ? "pointer" : "not-allowed"
          }}
        >
          {content}
        </div>
      );
    };

    // animation documentation:
    // <div style={{ animation: "animation: glow 1s infinite alternate;" }}>
    // https://www.reddit.com/r/web_design/comments/6jpmff/is_there_a_way_to_apply_a_boxshadow_to_an_svg/
    // drop-shadow(offset-x offset-y blur-radius spread-radius color)
    // final animation solution: https://stackoverflow.com/questions/55841290/cant-get-my-css-keyframe-hover-drop-shadow-animation-to-work-on-my-svg
    const jobIcon = job.recommendedRun ? (
      <Icon
        iconSize={24}
        icon="star"
        style={{
          marginRight: "1em",
          // filter: "drop-shadow(0 5px 5px gray)"
          animation: "glow 1s ease-in-out infinite alternate"
          // animation: "glow 1s infinite alternate;"
        }}
      />
    ) : (
      <Icon iconSize={24} icon="blank" style={{ marginRight: "1em" }} />
    );

    const jobLink = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {jobIcon}
        <span className={"bp3-text" + (validJob ? "" : " bp3-text-muted")}>
          {this.props.jobGroupData.bitscore ? (
            job.threshold
          ) : (
            <span>
              10<sup>{"-" + job.threshold}</sup>
            </span>
          )}
        </span>
      </div>
    );

    if (validJob) {
      let segments = job.segments.map(s => ({
        start: s.start,
        end: s.end,
        color: Colors.BLUE1
      }));

      let alignmentCoverage = (
        <SegmentGraph
          sequenceStart={this.props.jobGroupData.query.sequenceStart}
          sequenceEnd={this.props.jobGroupData.query.sequenceEnd}
          segments={segments}
          connectSegments={true}
        />
      );

      let qualityIntent;
      if (job.qualityScore <= 3) {
        qualityIntent = Intent.DANGER;
      } else if (job.qualityScore <= 7) {
        qualityIntent = Intent.WARNING;
      } else {
        qualityIntent = Intent.SUCCESS;
      }

      // console.log("xxx JOB", JSON.stringify(job));

      const seqStats = (
        <Tag
          large={true}
          minimal={true}
          style={{ margin: "0.1em", width: "150px", textAlign: "center" }}
        >
          {job.neff.toFixed(0)}
        </Tag>
      );

      const meffStats = (
        <Tag
          large={true}
          minimal={true}
          style={{ margin: "0.1em", width: "65px", textAlign: "center" }}
        >
          {job.neffOverL >= 1000
            ? job.neffOverL.toFixed(0)
            : job.neffOverL.toFixed(1)}
        </Tag>
      );

      let stats = (
        <div style={{ verticalAlign: "middle" }}>
          {/* <Tag
            large={true}
            minimal={true}
            style={{ margin: "0.1em", width: "65px", textAlign: "center" }}
          >
            {job.neffOverL >= 1000
              ? job.neffOverL.toFixed(0)
              : job.neffOverL.toFixed(1)}
          </Tag> */}
          {/*  <Tag
            large={true}
            minimal={true}
            style={{ margin: "0.1em", width: "50px", textAlign: "center" }}
          >
            {job.significantECPercentage.toFixed(2)}
          </Tag> */}
          <Tag
            large={true}
            style={{ margin: "0.1em", width: "40px", textAlign: "center" }}
            intent={qualityIntent}
          >
            {job.qualityScore}
          </Tag>
        </div>
      );

      return (
        <tr
          key={index}
          style={{
            height: "40px",
            background: selectedJob ? "rgba(0,0,0,0.05)" : null
          }}
        >
          <td style={TD_STYLE}>{makeLink(jobLink)}</td>
          <td style={TD_STYLE}>{makeLink(alignmentCoverage)}</td>
          <td style={TD_STYLE}>{makeLink(seqStats)}</td>
          <td style={TD_STYLE}>{makeLink(meffStats)}</td>
          <td style={TD_STYLE}>{makeLink(stats)}</td>
          <td style={TD_STYLE}>{makeLink(jobDownloadButton, true)}</td>
        </tr>
      );
    } else {
      let loading =
        job.status === JOB_STATUS.INIT ||
        job.status === JOB_STATUS.PEND ||
        job.status === JOB_STATUS.RUN;
      let message;
      switch (job.status) {
        case JOB_STATUS.INIT:
          message = "Job is initialized";
          break;
        case JOB_STATUS.PEND:
          message = "Job is pending";
          break;
        case JOB_STATUS.RUN:
          message = "Job is running: " + job.message;
          break;
        case JOB_STATUS.TERM:
          message = "Job exceeded resources!";
          break;
        case JOB_STATUS.FAIL:
          message = "Job failed!";
          break;
        case JOB_STATUS.BAILOUT:
          message = "Insufficient result quality!";
          break;
        default:
          break;
      }

      let statusStyle = {
        // verticalAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      };

      let status;
      if (loading) {
        status = (
          <div style={statusStyle}>
            <Spinner
              size={Spinner.SIZE_SMALL}
              style={{ verticalAlign: "middle" }}
            />
            &nbsp;
            {message}
          </div>
        );
      } else {
        let sendMail;
        let helpText;
        let intent = Intent.DANGER;

        if (job.status === JOB_STATUS.FAIL) {
          sendMail = (
            <a
              href={
                "mailto:" +
                SUPPORT_EMAIL +
                "?subject=EVcouplings job " +
                this.props.jobGroup +
                " failed"
              }
              className="bp3-button bp3-minimal"
            >
              <Icon icon="envelope" />
            </a>
          );
          helpText = JOB_FAIL_HELP_TEXT;
        } else if (job.status === JOB_STATUS.TERM) {
          // Terminated
          helpText = JOB_TERM_HELP_TEXT;
        } else if (job.status === JOB_STATUS.BAILOUT) {
          helpText = JOB_BAILOUT_HELP_TEXT;
          // intent=Intent.WARNING;
        }

        status = (
          <div style={statusStyle}>
            {helpTooltip(
              <Icon
                icon="info-sign"
                intent={intent}
                style={{ marginRight: "0.25em" }}
              />,
              helpText
            )}
            {message}
            {sendMail}
          </div>
        );
      }

      return (
        <tr key={index} style={{ height: "40px" }}>
          <td style={TD_STYLE}>{makeLink(jobLink)}</td>
          <td colSpan="4" style={TD_STYLE}>
            {makeLink(status)}
          </td>
          <td style={TD_STYLE}>{makeLink(jobDownloadButton)}</td>
        </tr>
      );
    }
  }

  render() {
    if (!this.props.jobGroupData) {
      return <div>Error: job group could not be found</div>;
    }

    // render all jobs
    let renderedJobs = this.props.jobGroupData.jobs.map(
      this.renderJob.bind(this)
    );

    return (
      <table
        className="bp3-html-table bp3-interactive"
        style={{ verticalAlign: "top", width: "100%", tableLayout: "fixed" }}
      >
        {this.renderTableHeader()}
        <tbody>{renderedJobs}</tbody>
      </table>
    );
  }
}

/*
  Legend for job summary view
*/
export const SummaryViewLegend = () => {
  const divider = (
    <span
      className="bp3-text-disabled"
      style={{ marginLeft: "0.5em", marginRight: "0.5em" }}
    >
      |
    </span>
  );

  return (
    <div
      style={{
        marginTop: "3em",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap"
      }}
    >
      <Icon icon="star" style={{ marginRight: "0.5em" }} />
      Recommended result
      {divider}
      Result quality:
      <Tag
        intent={Intent.DANGER}
        style={{ marginRight: "0.25em", marginLeft: "0.5em" }}
      >
        0-3
      </Tag>
      low
      <Tag
        intent={Intent.WARNING}
        style={{ marginRight: "0.25em", marginLeft: "0.5em" }}
      >
        4-7
      </Tag>
      medium
      <Tag
        intent={Intent.SUCCESS}
        style={{ marginRight: "0.25em", marginLeft: "0.5em" }}
      >
        8-10
      </Tag>
      high
      {divider}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        Alignment coverage:
        <div
          style={{
            backgroundColor: Colors.BLUE1,
            height: "1em",
            width: "2em",
            marginRight: "0.25em",
            marginLeft: "0.5em"
          }}
        />{" "}
        covered
        <div
          style={{
            backgroundColor: "black",
            height: "1pt",
            width: "2em",
            marginRight: "0.25em",
            marginLeft: "0.5em"
          }}
        />{" "}
        not covered
      </div>
    </div>
  );
};
