import { Map, Range } from "immutable";
import { interpolateViridis, interpolateBlues, interpolatePurples, interpolateGreens, interpolateOranges, interpolateGreys } from "d3";
import { registerColorSchemeFromResidueMap } from "../results/StructureViewer";
import { createColorMapper } from "./../../utils/Helpers";
import { STRUCTURE_CLASSES } from "./../../utils/Structures";

// default residue color for residues not covered by alignment/model
// TODO: consider refactoring and share this constant with other panels
const DEFAULT_STRUCTURE_COLOR = "#FFFFFF";

// color map per structure class (TODO: allow user to set colors eventually?)
export const COLOR_MAP_PER_CLASS = Map([
  [
    STRUCTURE_CLASSES.EXPERIMENTAL,
    {
      colorScheme: interpolateViridis,
      brightnessAdjustment: null,
      opacityAdjustment: -0.9,
    },
  ],
  [
    STRUCTURE_CLASSES.PREDICTED,
    {
      colorScheme: interpolateViridis,
      brightnessAdjustment: null,
      opacityAdjustment: 0.6,
    },
  ],
  [
    STRUCTURE_CLASSES.USER,
    {
      colorScheme: interpolateViridis,
      brightnessAdjustment: null,
      opacityAdjustment: -0.9,
    },
  ],
]);

export const COLOR_MAP_PER_CLASS_SINGLE_HUE = Map([
  [
    STRUCTURE_CLASSES.EXPERIMENTAL,
    {
      colorScheme: interpolateOranges,
      brightnessAdjustment: null,
      opacityAdjustment: 0,
    },
  ],
  [
    STRUCTURE_CLASSES.PREDICTED,
    {
      colorScheme: interpolatePurples,
      brightnessAdjustment: null,
      opacityAdjustment: 0,
    },
  ],
  [
    STRUCTURE_CLASSES.USER,
    {
      colorScheme: interpolateGreys,
      brightnessAdjustment: null,
      opacityAdjustment: 0,
    },
  ],
]);

export const createSequenceColorMapper = (
  targetSequence,
  d3Scheme,
  opacityAdjustment,
  brigthnessAdjustment,
  defaultStructureColor
) => {
  let colorMap = {};
  if (targetSequence) {
    const firstPos = Math.min(...targetSequence.keys());
    const lastPos = Math.max(...targetSequence.keys());

    const mapper = createColorMapper(
      [firstPos, lastPos],
      d3Scheme,
      true,
      false,
      opacityAdjustment,
      brigthnessAdjustment
    );

    colorMap = Map(
      Range(firstPos, lastPos + 1).map((pos) => [pos, mapper(pos)])
    );
  }

  return registerColorSchemeFromResidueMap(colorMap, defaultStructureColor);
};

export const createSequenceColorMapperPerClass = (
  targetSequence,
  colorMapPerClass,
  defaultStructureColor
) => {
  if (!colorMapPerClass) {
    colorMapPerClass = COLOR_MAP_PER_CLASS;
  }

  if (!defaultStructureColor) {
    defaultStructureColor = DEFAULT_STRUCTURE_COLOR;
  }

  // create all color maps
  return colorMapPerClass.map((d3Scheme) =>
    createSequenceColorMapper(
      targetSequence,
      d3Scheme.colorScheme,
      d3Scheme.opacityAdjustment,
      d3Scheme.brightnessAdjustment,
      defaultStructureColor
    )
  );
};

export const getColorSchemePerModel = (structures, colorSchemes) => {
  return structures
    ? structures.mapEntries(([k, v]) => {
        return [
          Map({ id: k.get("id"), class: k.get("class") }),
          colorSchemes.get(k.get("class")),
        ];
      })
    : null;
};
