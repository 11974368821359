import React, { Component } from "react";
import { Radio, RadioGroup, Slider, Switch } from "@blueprintjs/core";
import { helpHeading, thresholdToBitscoreClass } from "../common/Helpers";
import {
  ADV_OPTIONS_HELP_TEXTS,
  FOLD_MODEL_BOUNDARIES,
  INFERENCE_METHODS,
  ALIGNMENT_METHODS,
  COMPARE_ALIGNMENT_ITERATIONS,
  COMPARE_DISTANCE_TRESHOLD,
  ALIGNMENT_THRESHOLD_BOUNDARIES
} from "../../utils/Constants";

export class CouplingsSettings extends Component {
  handleChangeInferenceMethod(selectedValue) {
    let usePLM = selectedValue === "plm";
    this.props.updateParams(params => ({
      inferenceMethod: usePLM
        ? INFERENCE_METHODS.PLM
        : INFERENCE_METHODS.MEANFIELD
    }));
  }

  renderInferenceMethod() {
    return (
      <div>
        {helpHeading(
          <b>Statistical inference method</b>,
          ADV_OPTIONS_HELP_TEXTS.COUPLINGS.INFERENCE_METHOD
        )}
        <div style={{ marginTop: "1em" }}>
          <RadioGroup
            inline={true}
            name="group_inference"
            onChange={event =>
              this.handleChangeInferenceMethod(event.currentTarget.value)
            }
            selectedValue={
              this.props.params.inferenceMethod === INFERENCE_METHODS.PLM
                ? "plm"
                : "meanfield"
            }
          >
            <Radio label="Pseudo-likelihood maximization" value="plm" />
            <Radio label="Mean-field approximation" value="meanfield" />
          </RadioGroup>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column"
        }}
      >
        {this.renderInferenceMethod()}
      </div>
    );
  }
}

export class FoldingSettings extends Component {
  handleFoldingSwitch() {
    this.props.updateParams(params => ({ runFold: !params.runFold }));
  }

  renderFoldingSwitch() {
    return (
      <div>
        {helpHeading(
          <b>3D structure prediction from ECs</b>,
          ADV_OPTIONS_HELP_TEXTS.FOLD.RUN_FOLD
        )}
        <div style={{ marginTop: "1em" }}>
          <Switch
            labelElement={this.props.params.runFold ? "Enabled" : "Disabled"}
            checked={this.props.params.runFold}
            onChange={event => this.handleFoldingSwitch()}
          />
        </div>
      </div>
    );
  }

  handleNumModelChange(value) {
    this.props.updateParams(params => ({ numModels: value }));
  }

  renderNumModels() {
    return (
      <div style={{ marginTop: "2em", minWidth: "70%" }}>
        {helpHeading(
          <b>Number of generated models</b>,
          ADV_OPTIONS_HELP_TEXTS.FOLD.NUM_MODELS
        )}
        <div style={{ marginTop: "1em" }}>
          <Slider
            disabled={!this.props.params.runFold}
            min={FOLD_MODEL_BOUNDARIES.MIN}
            max={FOLD_MODEL_BOUNDARIES.MAX}
            value={this.props.params.numModels}
            onChange={value => this.handleNumModelChange(value)}
            labelStepSize={
              FOLD_MODEL_BOUNDARIES.MAX - FOLD_MODEL_BOUNDARIES.MIN
            }
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column"
        }}
      >
        {this.renderFoldingSwitch()}
        {this.renderNumModels()}
      </div>
    );
  }
}

export class ComparisonSettings extends Component {
  handleChangeCompareAlignmentMethod(selectedValue) {
    let useProfile = selectedValue === "hmmsearch";
    this.props.updateParams(params => ({
      compareAlignmentMethod: useProfile
        ? ALIGNMENT_METHODS.HMMSEARCH
        : ALIGNMENT_METHODS.JACKHMMER
    }));
  }

  handleDistanceThresholdChange(value) {
    this.props.updateParams(params => ({ compareDistanceThreshold: value }));
  }

  renderDistanceThreshold() {
    return (
      <div style={{ minWidth: "50%", width: "60%" }}>
        {helpHeading(
          <b>Contact distance cutoff</b>,
          ADV_OPTIONS_HELP_TEXTS.COMPARE.DISTANCE_THRESHOLD
        )}
        <div style={{ marginTop: "1em" }}>
          <Slider
            min={COMPARE_DISTANCE_TRESHOLD.MIN}
            max={COMPARE_DISTANCE_TRESHOLD.MAX}
            stepSize={0.1}
            labelStepSize={
              COMPARE_DISTANCE_TRESHOLD.MAX -
              COMPARE_DISTANCE_TRESHOLD.MIN
            }
            labelRenderer={value => value.toFixed(1) + "\u212b"}
            value={this.props.params.compareDistanceThreshold}
            onChange={value => this.handleDistanceThresholdChange(value)}
          />
        </div>
      </div>
    );
  }

  renderCompareAlignmentMethod() {
    return (
      <div style={{ marginTop: "2em" }}>
        {helpHeading(
          <b>PDB structure search method</b>,
          ADV_OPTIONS_HELP_TEXTS.COMPARE.ALIGNMENT_METHOD
        )}
        <div style={{ marginTop: "1em" }}>
          <RadioGroup
            inline={true}
            name="compare_align_method"
            onChange={event =>
              this.handleChangeCompareAlignmentMethod(event.currentTarget.value)
            }
            selectedValue={
              this.props.params.compareAlignmentMethod ===
              ALIGNMENT_METHODS.HMMSEARCH
                ? "hmmsearch"
                : "jackhmmer"
            }
          >
            <Radio label="Conservative" value="jackhmmer" />
            <Radio label="Sensitive" value="hmmsearch" />
          </RadioGroup>
        </div>
      </div>
    );
  }

  handleAlignmentThresholdChange(value) {
    this.props.updateParams(params => ({ compareAlignmentTreshold: value }));
  }

  renderAlignmentThreshold() {
    return (
      <div style={{ marginTop: "2em", minWidth: "50%", width: "60%", marginBottom: "1em" }}>
        {helpHeading(
          <b>Bitscore threshold for finding known homologous 3D structures</b>,
          ADV_OPTIONS_HELP_TEXTS.COMPARE.ALIGNMENT_THRESHOLD
        )}
        <div style={{ marginTop: "1em" }}>
          <Slider
            min={ALIGNMENT_THRESHOLD_BOUNDARIES.BITSCORE_MIN}
            max={ALIGNMENT_THRESHOLD_BOUNDARIES.BITSCORE_MAX}
            stepSize={0.01}
            labelStepSize={
              ALIGNMENT_THRESHOLD_BOUNDARIES.BITSCORE_MAX -
              ALIGNMENT_THRESHOLD_BOUNDARIES.BITSCORE_MIN
            }
            labelRenderer={value => (
              <div>
                {value.toFixed(2)}
                <br />
                {thresholdToBitscoreClass(value)}
              </div>
            )}
            value={this.props.params.compareAlignmentTreshold}
            onChange={value => this.handleAlignmentThresholdChange(value)}
          />
        </div>
      </div>
    );
  }

  renderIterations() {
    return (
      <div style={{ marginTop: "2em", minWidth: "40%", width: "60%" }}>
        {helpHeading(
          <b>Search iterations</b>,
          ADV_OPTIONS_HELP_TEXTS.COMPARE.ITERATIONS
        )}
        <div style={{ marginTop: "1em" }}>
          <Slider
            min={COMPARE_ALIGNMENT_ITERATIONS.MIN}
            max={COMPARE_ALIGNMENT_ITERATIONS.MAX}
            value={this.props.params.compareAlignmentIterations}
            onChange={value =>
              this.props.updateParams(params => {
                return { compareAlignmentIterations: value };
              })
            }
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column"
        }}
      >
        {this.renderDistanceThreshold()}
        {this.renderCompareAlignmentMethod()}
        {this.renderAlignmentThreshold()}
        {/*this.renderIterations()*/}
      </div>
    );
  }
}
