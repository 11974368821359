import React from "react";
import {
  Button,
  Popover,
  Position,
  PopoverInteractionKind,
} from "@blueprintjs/core";

export const makeCircle = (diameter, color, style) => {
  return (
    <div
      style={{
        borderRadius: "50%",
        width: diameter,
        height: diameter,
        background: color,
        ...style,
      }}
    >
      &nbsp;
    </div>
  );
};

export const makeColorbar = (
  colorMapper,
  vMin,
  vMax,
  direction,
  style,
  colorSteps
) => {
  if (!direction) {
    direction = "right";
  }

  if (!colorSteps) {
    colorSteps = 20;
  }

  // assemble colors
  const stepSize = (vMax - vMin) / colorSteps;
  const colors = [];
  for (let i = 0; i <= colorSteps; i++) {
    colors.push(colorMapper(vMin + i * stepSize));
  }

  const backgroundStyle = `linear-gradient(to ${direction}, ${colors.join(
    ", "
  )})`;
  return (
    <div
      style={{
        background: backgroundStyle,
        //`linear-gradient (to ${direction}, #33ccff 37%, #00cc66 79%)`,
        ...style,
      }}
    >
      &nbsp;
    </div>
  );
};

export const Legend = ({ content }) => {
  const target = (
    <Button icon="info-sign" minimal={true} title="Click to show legend">
      Legend
    </Button>
  );

  const popoverContent = <div style={{ minWidth: "300px" }}>{content}</div>;

  return (
    <Popover
      popoverClassName="bp3-popover-content-sizing"
      content={popoverContent}
      target={target}
      position={Position.TOP}
      interactionKind={PopoverInteractionKind.CLICK}
    ></Popover>
  );
};

/*
  Legend for data table (table with column headers and corresponding descriptions)
*/
export const TableLegend = ({ columnDescriptions }) => {
  const shrink = { whiteSpace: "nowrap", width: "20%" };
  const expand = { width: "80%" };

  const table = (
    <table
      className="bp3-html-table bp3-html-table-condensed"
      style={{
        verticalAlign: "top",
        width: "100%",
        tableLayout: "fixed",
        marginBottom: "1em",
      }}
    >
      <thead>
        <tr>
          <th style={shrink}>Column</th>
          <th style={expand}>Description</th>
        </tr>
      </thead>

      <tbody>
        {columnDescriptions.map((column, idx) => (
          <tr key={idx}>
            <td style={shrink}>{column.heading}</td>
            <td style={expand}>{column.legend}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return <Legend content={table} />;
};
