import React, { Component } from "react";

class SegmentGraph extends Component {
  makeDiv(width, color, content, key, spacer) {
    return (
      <div
        style={{
          width: width.toString() + "%",
          marginLeft: "0%",
          marginRight: "0%",
          padding: "0",
          backgroundColor: spacer ? "black" : color,
          height: spacer ? "1pt" : null
        }}
        key={key}
      >
        {content ? content : <span>&nbsp;</span>}
      </div>
    );
  }

  /*
     Note: assumes that domains are not overlapping (for now)
  */
  renderSegments() {
    // compute length of sequence to map domain boundaries to percentage of length
    let sequenceLength = this.props.sequenceEnd - this.props.sequenceStart + 1;

    // copy list, later shallow copy objects (cannot use JSON.stringify-based deepcopy)
    // since this will break passed functions;
    // filter anything that is outside of our range
    let segments = this.props.segments.filter(
      segment =>
        !(
          segment.end <= this.props.sequenceStart ||
          segment.start >= this.props.sequenceEnd
        )
    );

    // make shallow copies of segments
    for (let i = 0; i < segments.length; i++) {
      // shallow copy of list element
      segments[i] = Object.assign({}, segments[i]);
    }

    // segments that will be removed entirely
    let removeSegments = new Set();

    // remove segment overlaps
    for (let i = 0; i < segments.length - 1; i++) {
      // if current segment already flagged for removal, skip
      if (removeSegments.has(i)) {
        continue;
      }

      for (let j = i + 1; j < segments.length; j++) {
        // check if j-th segment overlaps with i-th segment
        if (segments[j].start <= segments[i].end) {
          // check if i-th segment overlaps j-th segment completely
          if (segments[j].end <= segments[i].end) {
            // if complete overlap, remove segment altogether
            removeSegments.add(j);
          } else {
            // otherwise, trim j-th segment to start one position after end of i-th
            segments[j].start = Math.min(segments[i].end + 1, segments[j].end);
          }
        }
      }
    }

    // now create final segment set for plotting
    segments = segments.filter((segment, i) => !removeSegments.has(i));

    // compute relative domain locations to each other (percentages of width of element)
    for (let i = 0; i < segments.length; i++) {
      // shallow copy of list element
      // segments[i] = Object.assign({}, segments[i]);
      // assign sequential index for event handler
      segments[i].index = i;

      if (i === 0) {
        // for first domain, compute distance to left boundary of container;
        // don't reach out further than start of sequence
        segments[i].marginLeft =
          Math.max(segments[i].start, this.props.sequenceStart) -
          this.props.sequenceStart;
      } else {
        // don't need to adjust here since we know end of first one needs to be inside
        // sequence range
        segments[i].marginLeft = segments[i].start - segments[i - 1].end - 1;
      }

      // relative margin
      segments[i].marginLeft = (100 * segments[i].marginLeft) / sequenceLength;

      // also very last part from end of last segment to sequence end
      if (i === segments.length - 1) {
        let lastEnd = Math.min(segments[i].end, this.props.sequenceEnd);
        segments[i].marginRight =
          (100 * (this.props.sequenceEnd - lastEnd)) / sequenceLength;
      } else {
        segments[i].marginRight = null;
      }

      // relative domain width;
      // make sure not to overflow boundaries of sequence range
      segments[i].width =
        (100 *
          (Math.min(segments[i].end, this.props.sequenceEnd) -
            Math.max(segments[i].start, this.props.sequenceStart) +
            1)) /
        sequenceLength;
    }

    let segmentComponents = [];

    // Assemble divs for rendering (with spacers in between);
    // Create individual domain components
    for (let segment of segments) {
      // spacer on left side
      if (segment.marginLeft) {
        segmentComponents.push(
          this.makeDiv(
            segment.marginLeft,
            null, //"black",
            null,
            "pre_" + segment.index,
            this.props.connectSegments
          )
        );
      }

      let segmentDiv = this.makeDiv(
        segment.width,
        segment.color,
        segment.content,
        "main_" + segment.index,
        false
      );

      segmentComponents.push(segmentDiv);

      // spacer on right side
      if (segment.marginRight) {
        segmentComponents.push(
          this.makeDiv(
            segment.marginRight,
            null, //"black",
            null,
            "post_" + segment.index,
            this.props.connectSegments
          )
        );
      }
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        {segmentComponents}
      </div>
    );
  }

  render() {
    if (this.props.segments) {
      return this.renderSegments();
    } else {
      return null;
    }
  }
}

export default SegmentGraph;
