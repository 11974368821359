import React, { useState } from "react";
import { Set, List, Map } from "immutable";
import {
  Button,
  Classes,
  Intent,
  MenuItem,
  Popover,
  PopoverPosition,
  Position,
  RadioGroup,
  Radio
} from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import "./StructureSelection.css";
import {
  STRUCTURE_CLASSES,
  STRUCTURE_CLASS_MAP_INV,
  SECONDARY_STRUCTURE_MODE,
  DISTANCE_MAP_MODE
} from "./../../utils/Structures";

/*
  Single-structure selector (predicted/user-defined structures)

  TODO: allow to scroll list in popover (same as for multiselect)
*/
/*
const SingleStructureSelection = ({
  structureData,
  selectedStructures,
  handleStructureSelect
}) => {
  const items = structureData ? structureData : [];
  const selectedItem =
    selectedStructures.size === 0
      ? null
      : items.filter(item => item.id === selectedStructures.toArray()[0])[0];
  console.log("SELECTED ITEM", selectedItem);

  return (
    <Select
      items={items}
      itemRenderer={(item, { modifiers, handleClick }) => (
        <MenuItem
          active={modifiers.active}
          key={item.id}
          onClick={handleClick}
          text={item.text}
          label={item.annotation}
          shouldDismissPopover={false}
          icon={selectedStructures.has(item.id) ? "tick" : "blank"}
        ></MenuItem>
      )}
      onItemSelect={item => handleStructureSelect(Set([item.id]))}
      itemPredicate={itemPredicate}
      popoverProps={{
        minimal: true,
        popoverClassName: "structure-selection-menu"
      }}
      noResults={<MenuItem disabled={true} text="No results." />}
    >
      <Button
        text={selectedItem ? selectedItem.text : "Click to select..."}
        minimal={true}
      ></Button>
    </Select>
  );
};
*/

const itemPredicate = (query, item) =>
  item.text.includes(query) ||
  (item.annotation && item.annotation.includes(query));

/*
  Multi-structure selector (predicted/user-defined structures)

  DONE: make searchable
  DONE: no results
  DONE: make scrollable by keys (activeItem)
  DONE: mark selected with ticks
  DONE: do not close popover when clicking (stop propagation?)
  DONE: allow to scroll list in popover
  (https://github.com/palantir/blueprint/issues/3363)

  TODO: implement event handling, including multiselect handling and tag closing cross
  TODO: how to sync state between internal selection and outside?
*/
const MultiStructureSelection = ({
  structureData,
  selectedStructures,
  handleStructureSelect,
  popoverOnTop
}) => {
  // note that items and selectedItems need to be regular JS arrays of objects
  // or MultiSelect will not work
  const items = structureData ? structureData.toJS() : [];
  const selectedItems = selectedStructures
    ? items.filter(structure => selectedStructures.includes(structure.id))
    : [];

  const clearButton =
    selectedItems.length > 0 ? (
      <Button
        icon="cross"
        minimal={true}
        onClick={() => {
          // console.log("REMOVE ALL"); // TODO: remove

          if (handleStructureSelect) {
            handleStructureSelect(List([]));
          }
        }}
      />
    ) : null;

  return (
    <MultiSelect
      items={items}
      selectedItems={selectedItems}
      itemRenderer={(item, { modifiers, handleClick }) => (
        <MenuItem
          active={modifiers.active}
          key={item.id}
          onClick={handleClick}
          shouldDismissPopover={false}
          text={item.text}
          label={item.annotation}
          icon={selectedStructures.contains(item.id) ? "tick" : "blank"}
        ></MenuItem>
      )}
      onItemSelect={item => {
        // console.log("SELECT ITEM", item.id, item.text);
        if (handleStructureSelect) {
          if (selectedStructures.includes(item.id)) {
            // console.log("::: ALREADY SELECTED", item.id);
            handleStructureSelect(
              selectedStructures.filter(id => id !== item.id)
            );
          } else {
            // console.log("::: NOT YET SELECTED", item.id);
            handleStructureSelect(selectedStructures.concat(item.id));
          }
        }
      }}
      tagRenderer={item => item.text}
      tagInputProps={{
        tagProps: { minimal: true },
        onRemove: (text, index) => {
          // console.log("REMOVE", text, index, items[index].id);
          if (handleStructureSelect) {
            handleStructureSelect(
              selectedStructures.filter(id => id !== selectedItems[index].id)
            );
          }
        },
        // handleStructureSelect(selectedItems[index].id),  // TODO: reactivate
        rightElement: clearButton
      }}
      popoverProps={{
        minimal: true,
        popoverClassName: "structure-selection-menu",
        usePortal: false,
        position: popoverOnTop
          ? PopoverPosition.TOP_LEFT
          : PopoverPosition.BOTTOM_LEFT
      }}
      fill={true}
      placeholder={"Click to select..."}
      itemPredicate={itemPredicate}
      noResults={<MenuItem disabled={true} text="No results." />}
      className="structure-selection-popover"
    ></MultiSelect>
  );
};

/*
  Wrapper for popover rendering of ContactSelectionMenu and StructureSelectionMenu
*/
const renderPopover = (target, content, onOpening, dispatch, disableApply, disabled) => {
  const popoverContent = (
    <>
      <div style={{ width: "280px" }}>{content}</div>
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}
      >
        {/* <Button
            className={Classes.POPOVER_DISMISS}
            style={{ marginRight: 10 }}
            minimal={true}
          >
            Cancel
          </Button> */}
        <Button
          disabled={disableApply}
          intent={Intent.PRIMARY}
          className={Classes.POPOVER_DISMISS}
          minimal={false}
          onClick={() => {
            if (dispatch) {
              dispatch();
            }
          }}
        >
          Apply
        </Button>
      </div>
    </>
  );

  return (
    <Popover
      popoverClassName="bp3-popover-content-sizing"
      content={popoverContent}
      target={target}
      position={Position.TOP}
      onOpening={() => {
        if (onOpening) {
          onOpening();
        }
      }}
      disabled={disabled}
    ></Popover>
  );
};

/*
  Wrapper for structure class-specific selection menu
  TODO: refactor so can be used for ContactSelectionMenu
*/
const renderCategorySelection = (
  structureClass,
  availableStructures,
  currentSelection,
  handleStructureSelect,
  popoverOnTop
) => {
  const classStructures = availableStructures
    ? availableStructures.get(STRUCTURE_CLASS_MAP_INV.get(structureClass))
    : null;

  // if nothing to show, do not render selection menu but n/a message
  if (!classStructures || classStructures.count() === 0) {
    return <p className="bp3-text-muted">None available</p>;
  }

  // TODO: extract id of all selected structures
  const selectedStructureIds = currentSelection
    ? currentSelection
        .filter(s => s.get("class") === structureClass)
        .map(s => s.get("id"))
    : List([]);

  // console.log("::: CURRENT SELECTION", JSON.stringify(selectedStructureIds));

  // TODO: remove
  /*
  console.log(
    "::: SELECTED",
    structureClass,
    JSON.stringify(selectedStructureIds)
  );
  */

  return (
    <MultiStructureSelection
      structureData={classStructures}
      selectedStructures={selectedStructureIds}
      handleStructureSelect={handleStructureSelect}
      popoverOnTop={popoverOnTop}
    />
  );
};

/*
  Menu to select structures for contacts and secondary structure display
*/
export const ContactSelectionMenu = ({
  target,
  availableStructures,
  contactSettings,
  setContactSettings,
  hasExperimentalStructures,
  hasPredictedStructures
}) => {
  // TODO: derive from props
  const [structureSelectionType, setStructureSelectionType] = useState(
    DISTANCE_MAP_MODE.EXPERIMENTAL_ALL
  );

  // TODO: derive from props
  const [secondaryStructureType, setSecondaryStructureType] = useState(
    SECONDARY_STRUCTURE_MODE.EXPERIMENTAL
  );

  const selectedStructuresProps = contactSettings.selectedExperimentalStructures
    ? contactSettings.selectedExperimentalStructures
    : List();

  // holds current structure selection in component (will be dispatched
  // to actual selection using "Apply" button)
  const [currentSelection, setCurrentSelection] = useState(
    selectedStructuresProps
  );

  // having predicted structure is proxy if predicted secondary structure is available
  // (only run in fold stage at the moment)
  const hasPredictedSecStruct = hasPredictedStructures;

  /*
  console.log(
    "::: CURRENT SELECTION",
    JSON.stringify(currentSelection),
    structureSelectionType,
    secondaryStructureType
  );
  */

  // TODO: make sure code from structure selection is reusable
  const content = (
    <>
      <h6 className="bp3-heading">Structures for residue contacts</h6>
      <RadioGroup
        onChange={event => {
          // console.log("::: VALUE", event.currentTarget.value, typeof event.currentTarget.value)
          setStructureSelectionType(
            Number.parseInt(event.currentTarget.value, 10)
          );
        }}
        selectedValue={structureSelectionType}
      >
        <Radio
          value={DISTANCE_MAP_MODE.EXPERIMENTAL_ALL}
          disabled={!hasExperimentalStructures}
        >
          All experimental structures
        </Radio>
        <Radio
          value={DISTANCE_MAP_MODE.EXPERIMENTAL_SUBSET}
          disabled={!hasExperimentalStructures}
        >
          Subset of experimental structures
        </Radio>
        <div style={{ marginBottom: "1em", marginLeft: "25px" }}>
          {renderCategorySelection(
            STRUCTURE_CLASSES.EXPERIMENTAL,
            availableStructures,
            currentSelection,
            selection => {
              setCurrentSelection(
                replaceSelectionForClass(
                  currentSelection,
                  selection,
                  STRUCTURE_CLASSES.EXPERIMENTAL
                )
              );
              // "auto-focus" to subset of experimental structures if changing the selection
              setStructureSelectionType(DISTANCE_MAP_MODE.EXPERIMENTAL_SUBSET);
            }
          )}
        </div>
        <Radio value={DISTANCE_MAP_MODE.SYNC_WITH_VIEWER}>
          Structures displayed in 3D viewer
        </Radio>
        <Radio value={DISTANCE_MAP_MODE.NO_STRUCTURE}>
          Do not show any structure contacts
        </Radio>
      </RadioGroup>
      <h6 className="bp3-heading" style={{ marginTop: "1.5em" }}>
        Secondary structure
      </h6>
      <RadioGroup
        // inline={true}
        onChange={event => {
          // console.log("::: VALUE", event.currentTarget.value, typeof event.currentTarget.value);
          setSecondaryStructureType(
            Number.parseInt(event.currentTarget.value, 10)
          );
        }}
        selectedValue={secondaryStructureType}
      >
        <Radio
          value={SECONDARY_STRUCTURE_MODE.EXPERIMENTAL}
          disabled={!hasExperimentalStructures}
        >
          {structureSelectionType === DISTANCE_MAP_MODE.EXPERIMENTAL_SUBSET
            ? "Experimental (subset of structures)"
            : "Experimental (all structures)"}
        </Radio>
        <Radio
          value={SECONDARY_STRUCTURE_MODE.PREDICTED}
          disabled={!hasPredictedSecStruct}
        >
          Predicted (PSIPRED)
        </Radio>
        <Radio value={SECONDARY_STRUCTURE_MODE.SYNC_WITH_VIEWER}>
          Structures displayed in 3D viewer
        </Radio>
      </RadioGroup>
    </>
  );

  return renderPopover(
    target,
    content,
    () => {
      // not so efficient separate state handling here...
      setCurrentSelection(selectedStructuresProps);
      setStructureSelectionType(contactSettings.distanceMapMode);
      setSecondaryStructureType(contactSettings.shownSecondaryStructure);
    },
    () => {
      if (setContactSettings) {
        // console.log("!!! PROPAGATE SELECTION"); // TODO: remove
        setContactSettings({
          distanceMapMode: structureSelectionType,
          selectedExperimentalStructures: currentSelection,
          shownSecondaryStructure: secondaryStructureType
        });
      }
    },
    structureSelectionType === DISTANCE_MAP_MODE.EXPERIMENTAL_SUBSET &&
      currentSelection.count() === 0
  );
};

/*
  Replace one structure class of structure selection 
*/
const replaceSelectionForClass = (
  currentSelection,
  newSelection,
  newSelectionStructureClass
) => {
  // remove all structures belonging to structure class that is updated
  const filteredSelection = currentSelection.filter(
    structure => structure.get("class") !== newSelectionStructureClass
  );

  // completely recreate selectiom for updated structure class
  const updatedSelection = filteredSelection.concat(
    newSelection.map(id => Map({ id: id, class: newSelectionStructureClass }))
  );

  return updatedSelection;
};

/*
  Structure selection menu for 3D viewer
  (note contact map selection menu is a different component)

  Note that shown structures is prefiltered for pool, including correct
  jobGroup and job, so can use without danger of other (outdated)
  structures showing up.

  // TODO: record internal state of selection
  // TODO: reset internal state on closing
  // TODO: dispatch overall selection on submission
*/
export const StructureSelectionMenu = ({
  target,
  availableStructures,
  shownStructures,
  dispatchStructureSelection
}) => {
  // extract current selection from props, this will be set
  // as current selection of popup on each (re-)opening of popup
  const selectedStructuresProps = shownStructures
    ? shownStructures
        .keySeq()
        .map(s => Map({ id: s.get("id"), class: s.get("class") }))
        .toList()
    : null;

  // holds current structure selection in component (will be dispatched
  // to actual selection using "Apply" button)
  const [currentSelection, setCurrentSelection] = useState(
    selectedStructuresProps
  );

  /*
  console.log(
    "::: SELECTED STRUCTURES PROPS",
    JSON.stringify(selectedStructuresProps)
  );
  */

  /*
  console.log(
    "::: CURRENT VALUE",
    List.isList(currentSelection),
    Array.isArray(currentSelection),
    currentSelection ? JSON.stringify(currentSelection) : null
  );
  */

  // console.log("::: SELECTION PROPS", selectedStructuresProps ? JSON.stringify(selectedStructuresProps) : null);
  //const handleSelect = selection =>
  //  console.log("::: HANDLE SELECT", JSON.stringify(selection));

  const content = (
    <>
      <h6 className="bp3-heading">Experimental structures</h6>
      {renderCategorySelection(
        STRUCTURE_CLASSES.EXPERIMENTAL,
        availableStructures,
        currentSelection,
        selection =>
          setCurrentSelection(
            replaceSelectionForClass(
              currentSelection,
              selection,
              STRUCTURE_CLASSES.EXPERIMENTAL
            )
          )
      )}
      <h6 className="bp3-heading" style={{ marginTop: "1.5em" }}>
        Predicted structures
      </h6>
      {renderCategorySelection(
        STRUCTURE_CLASSES.PREDICTED,
        availableStructures,
        currentSelection,
        selection =>
          setCurrentSelection(
            replaceSelectionForClass(
              currentSelection,
              selection,
              STRUCTURE_CLASSES.PREDICTED
            )
          ),
        true
      )}
      <h6 className="bp3-heading" style={{ marginTop: "1.5em" }}>
        User-uploaded structures
      </h6>
      {renderCategorySelection(
        STRUCTURE_CLASSES.USER,
        availableStructures,
        currentSelection,
        selection =>
          setCurrentSelection(
            replaceSelectionForClass(
              currentSelection,
              selection,
              STRUCTURE_CLASSES.USER
            )
          ),
          true
      )}
    </>
  );

  return renderPopover(
    target,
    content,
    () => setCurrentSelection(selectedStructuresProps),
    () => {
      // console.log("DISPATCH!!!", currentSelection);
      if (dispatchStructureSelection && currentSelection !== null) {
        dispatchStructureSelection(currentSelection);
      }
    },
    currentSelection == null,
    !shownStructures  // do not allow to open until selected structures are defined
  );
};
