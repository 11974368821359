import React, { Component } from "react";
import {
  Button,
  Classes,
  Intent,
  Popover,
  Position,
  TextArea,
  Tag
} from "@blueprintjs/core";
import { RepeatedDataFetcher } from "../../utils/Queries";
import {
  getUniprotURL,
  parseFasta,
  parseFastaHeader,
  parseSequenceBoxInput,
  verifyValidProteinSequence
} from "../../utils/Sequence";
import {
  MIN_SEQUENCE_LENGTH,
  SEQUENCE_INPUT_EXAMPLES,
  VALID_SEQUENCE_HEADER
} from "../../utils/Constants";

// TODO: clear up wrong usage of setting state based on previous state
// (https://reactjs.org/docs/state-and-lifecycle.html#state-updates-may-be-asynchronous)

class SequenceInputBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sequenceInputValue: ""
    };
    this.fetcher = new RepeatedDataFetcher();
  }

  handleProteinInputChange(event) {
    this.updateSequence(event.target.value);
  }

  updateSequence(newSequence) {
    // TODO: better streamlining of setState (don't call twice) and order
    // of external updating

    // cancel any previous sequence fetching requests that may overwrite more recent changes
    this.fetcher.cancel();

    // extract identifier or sequence from input field
    let parsedBoxValue = parseSequenceBoxInput(newSequence);

    let tooShort =
      parsedBoxValue &&
      parsedBoxValue.sequence &&
      parsedBoxValue.sequence.length < MIN_SEQUENCE_LENGTH;

    // first check if empty -> remove exported sequence
    if (!parsedBoxValue || parsedBoxValue.empty) {
      this.props.handleSequenceChange(null, null, null);
    }

    if (parsedBoxValue && parsedBoxValue.valid && !tooShort) {
      if (parsedBoxValue.identifier) {
        this.fetcher.query(
          getUniprotURL(parsedBoxValue.identifier),
          res => {
            let valid = false;
            let message = "INVALID";
            let sequence = null;

            // check if we actually got data
            if (res.data) {
              // parse downloaded FASTA sequence
              let parsedEntry = parseFasta(res.data);

              // see if we could extract a sequence
              if (parsedEntry.sequence) {
                // verify if proper amino acid sequence
                let parsedVerify = verifyValidProteinSequence(
                  parsedEntry.sequence
                );

                // check if we got a valid sequence
                if (parsedVerify.valid) {
                  valid = true;
                  message = "Valid identifier";
                  sequence = parsedEntry.sequence;
                } else {
                  valid = false;
                  // check if non-standard amino acids or bigger problem
                  if (parsedVerify.nonStandard) {
                    message =
                      "Downloaded sequence contains non-standard amino acids";
                  } else {
                    message = "Invalid symbols in sequence";
                  }
                }
              } else {
                // somehow now sequence in download
                valid = false;
                message = "Could not extract sequence from download";
              }
            } else {
              valid = false;
              message = "Invalid sequence (download contained no data)";
            }

            this.setState({
              valid: valid,
              message: message,
              sequenceDownload: res.data
            });

            // only export sequence if valid, else remove export
            if (valid) {
              this.props.handleSequenceChange(
                sequence,
                parsedBoxValue.identifier,
                true
              );
            } else {
              this.props.handleSequenceChange(null, null, null);
            }
          },
          err => {
            this.setState({
              valid: false,
              message: "Invalid identifier (not found in database)",
              sequenceDownload: "error 1"
            });
            this.props.handleSequenceChange(null, null, null);
          },
          err => {
            this.setState({
              valid: false,
              message: "Cannot retrieve sequence (server down)",
              sequenceDownload: "error 2"
            });
            this.props.handleSequenceChange(null, null, null);
          }
        );

        // intermediate message while loading... but looks jumpy
        /* this.setState({
          valid: true,
          message: "Valid identifier (loading)",
          sequenceDownload: "...loading..."
        }); */
      } else if (parsedBoxValue.sequence) {
        // export sequence to parent component
        let identifier = null;
        if (parsedBoxValue.header) {
          identifier = parseFastaHeader(parsedBoxValue.header);
        }

        // need either no identifier or valid identifier
        if(!identifier || VALID_SEQUENCE_HEADER.test(identifier)) {
          this.props.handleSequenceChange(
            parsedBoxValue.sequence,
            identifier,
            false
          );
  
          this.setState({
            valid: true,
            message: "Valid sequence",
            sequenceDownload: "(NA)"
          });
        } else {
          // if invalid identifier given, do not accept input
          this.props.handleSequenceChange(
            null, null, null
          );
  
          this.setState({
            valid: false,
            message: "Identifier may only contain letters, numbers and underscores",
            sequenceDownload: "(NA)"
          });
        }
        
      }
    } else {
      // Invalid entry -> erase exported sequence
      this.props.handleSequenceChange(null, null, null);

      // Default message if we can't be more specific
      let message = "Invalid input";

      if (tooShort) {
        message =
          "Input sequence is too short (minimum: " +
          MIN_SEQUENCE_LENGTH.toString() +
          " residues)";
      }

      if (parsedBoxValue.nonStandard) {
        message = "Sequence contains non-standard amino acid symbols";
      }

      this.setState({
        valid: false,
        message: message,
        sequenceDownload: "(NA)"
      });
    }

    this.setState({
      sequenceInputValue: newSequence,
      parsedBoxValue: parsedBoxValue
    });
  }

  renderExampleButton(text, exampleValue) {
    return (
      <Button
        className={Classes.POPOVER_DISMISS}
        text={text}
        minimal={true}
        style={{color: "white"}}
        onClick={() => {
          this.updateSequence(exampleValue);
        }}
      />
    );
  }

  renderSequenceInputValid() {
    // TODO: handle the case that input sequence is too long - after cutting by slider!
    // TODO: handle the rendering of nonstandard amino acids (currently broken)

    let empty = !this.state.parsedBoxValue || this.state.parsedBoxValue.empty;
    let message = "Please enter your protein sequence.";
    let intent = Intent.PRIMARY;
    let icon = null;

    // override default message if not empty
    if (!empty) {
      if (this.state.valid) {
        intent = Intent.SUCCESS;
        icon = "tick";
      } else {
        intent = Intent.DANGER;
        icon = "cross";
      }
      message = this.state.message;
    }

    return (
      <div
        style={{
          display: "flex",
          marginTop: "0.3em",
          justifyContent: "space-between"
        }}
      >
        <Tag intent={intent} icon={icon}>
          {message}
        </Tag>
        <Popover
          position={Position.TOP}
          targetTagName="div"
          popoverClassName={Classes.TOOLTIP}
        >
          <Button minimal={true} small={true}>
            Examples
          </Button>
          <div key="text">
            <p>Please select an example input:</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: 15
              }}
            >
              {this.renderExampleButton(
                "UniProt accession",
                SEQUENCE_INPUT_EXAMPLES.accession
              )}
              {this.renderExampleButton(
                "UniProt entry name",
                SEQUENCE_INPUT_EXAMPLES.entryName
              )}
              {this.renderExampleButton(
                "FASTA sequence",
                SEQUENCE_INPUT_EXAMPLES.fastaSequence
              )}
              {this.renderExampleButton(
                "Raw sequence",
                SEQUENCE_INPUT_EXAMPLES.rawSequence
              )}
            </div>
          </div>
        </Popover>
      </div>
    );
  }

  render() {
    return (
      <div>
        <TextArea
          large={false}
          intent={Intent.PRIMARY}
          placeholder="UniProt accession, UniProt entry name, sequence in FASTA format, or raw protein sequence"
          fill={true}
          style={{ height: "150px" }}
          onChange={this.handleProteinInputChange.bind(this)}
          value={this.state.sequenceInputValue}
          autoFocus
        />
        <div>{this.renderSequenceInputValid()}</div>
        {/* {this.state.valid && this.state.parsedBoxValue.sequence ? <div>Set first index:</div> : <div></div>}
        <div>Input Box: {this.state.sequenceInputValue}</div>
        <div>Download: {this.state.sequenceDownload}</div> */}
      </div>
    );
  }
}

export default SequenceInputBox;
