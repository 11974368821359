import React from "react";
import { Button, Icon, NonIdealState, Position, Tooltip, Menu, MenuItem, Popover } from "@blueprintjs/core";

export function helpTooltip(item, helpText) {
  return (
    <Tooltip
      content={
        <p style={{ textAlign: "left", maxWidth: "250px" }}>{helpText}</p>
      }
      position={Position.BOTTOM}
      targetClassName="div"
    >
      {item}
    </Tooltip>
  );
}

export function helpHeading(item, helpText) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
      }}
    >
      {item}
      {helpTooltip(
        <Icon icon="help" style={{ marginLeft: "0.5em" }} />,
        helpText
      )}
    </div>
  );
}

export function helpIcon(helpText) {
  return (
    <>
      {helpTooltip(
        <Icon icon="help" />,
        helpText
      )}
    </>
  );
}

export function thresholdToBitscoreClass(threshold) {
  if (threshold < 0.2) {
    return (
      <span>
        very
        <br />
        deep
      </span>
    );
  } else if (threshold < 0.4) {
    return "deep";
  } else if (threshold < 0.6) {
    return "medium";
  } else if (threshold < 0.8) {
    return "shallow";
  } else {
    return (
      <span>
        very
        <br />
        shallow
      </span>
    );
  }
}

export const ErrorRefetcher = ({ refetcher }) => (
  <NonIdealState
    title="Error loading data"
    icon="error"
    description="Click button to retry."
    action={
      <Button onClick={refetcher} minimal={true} icon="repeat">
        Retry
      </Button>
    }
  />
);

/*
  List of options, exactly one can be selected at a time
*/
export const OptionMenu = ({ options, selection, setSelection, child }) => {
  const items = options.map((option, idx) => {
    return (
      <MenuItem
        text={option.text}
        key={idx}
        icon={selection === option.value ? "tick" : "blank"}
        onClick={() => setSelection(option.value)}
      />
    );
  });

  // TODO: make child element (Button etc.) flexible
  return <Popover content={<Menu>{items}</Menu>}>{child}</Popover>;
};

