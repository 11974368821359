import React, { useState, useRef } from "react";
import useAxios from "@use-hooks/axios";
import { Button, Icon, Spinner, Tag, Intent } from "@blueprintjs/core";
import { useInterval } from "../../utils/Hooks";
import { ResultSummaryView, SummaryViewLegend } from "../summary/ResultSummary";
import { API_POLLING_INTERVAL } from "../../utils/Constants";
import { apiJobgroupEndpoint, transformJobgroupData } from "../../utils/Api";
import { JobResult } from "./JobResult";
import { getUniprotURL, parseFasta, parseFastaHeader, getAlphaFoldUrl} from "../../utils/Sequence";

/*
  Top-level component that holds job group information and
  renders overview panel or individual jobs as function
  of that state and URL
*/
export const JobResultsWrapper = ({ match }) => {
  const jobgroup = match.params.jobgroup;
  const subjob = match.params.job;
  // jobgroup data fetched from REST API
  // (need to store in state since upon automatic reload will lose response objects)
  const [data, setData] = useState(null);
  // valid job group or not?
  const [invalidJobgroup, setInvalidJobgroup] = useState(false);

  // additions for retrieval of computational structure models (AlphaFold etc.)
  // 1. make sure we have a valid uniprot identifier (and match it with sequence)
  const [uniprotAc, setUniprotAc] = useState(null);
  // 2. make sure this matches to an actual AF structure
  const [alphaFoldUrl, setAlphaFoldUrl] = useState(null);

  const { response, loading, error, reFetch } = useAxios({
    url: apiJobgroupEndpoint(jobgroup),
    trigger: jobgroup,
    customHandler: (error, response) => {
      // console.log("RESPONSE", response);
      // console.log("ERROR", error);
      // console.log("LOADING", loading);
      // check if this was an invalid access attempt (400 status code)
      // and update state accordingly, so we don't keep retrying
      if (
        error &&
        error.response &&
        error.response.status === 400 &&
        !invalidJobgroup
      ) {
        setInvalidJobgroup(true);
      }
      // store data in state if available
      if (response && response.data) {
        setData(response.data);
      }
    }
  });
  // reload data if there is hope or we need to refresh
  // because job is not completely finished
  useInterval(() => {
    if (!(data && data.final) && !invalidJobgroup) {
      // if (true) {  // TODO: change this back
      // console.log("reload!"); // TODO: remove
      reFetch();
    }
  }, API_POLLING_INTERVAL);
  // indicator if job data is available
  let hasData = data !== null;

  /* AlphaFold prediction retrieval */
  // retrieve information relevant for AlphaFold structure predictions
  const uniprotMappingResponse = useAxios({
    url: getUniprotURL(data ? data.target.sequence_id : ""),
    trigger: `${jobgroup}_${hasData}`,
    forceDispatchEffect: () => hasData && data.target,
    customHandler: (error, response) => {
      if (response && response.data) {
        const fastaParsed = parseFasta(response.data);
        const headerSplit = fastaParsed.header ? parseFastaHeader(fastaParsed.header).split("|") : null;

        // make sure we have well-formed UniProt ID header
        if (!headerSplit || headerSplit.length !== 3) return;

        // make sure we agree in full sequence (considering first index to align ranges)
        const uniprotSeqCut = fastaParsed.sequence.substring(
          data.target.first_index - 1, data.target.first_index + data.target.sequence.length
        );

        // console.log("UNIPROT", uniprotSeqCut);
        // console.log("TARGET", data.target.sequence);
        if (uniprotSeqCut !== data.target.sequence)
          return;

        // console.log("*** HEADER", headerSplit);
        setUniprotAc(headerSplit[1]);
      }
    }
  })

  const alphafoldStructureResponse = useAxios({
    url: getAlphaFoldUrl(uniprotAc),
    trigger: uniprotAc,
    forceDispatchEffect: () => (uniprotAc !== null),
    customHandler: (error, response) => {
      if (response && response.data) {
        setAlphaFoldUrl(getAlphaFoldUrl(uniprotAc));
      }
    }
  })

  /* end of AlphaFold prediction retrieval */

  // determines if subjob view rendered or not
  // (only if valid subjob selected)
  let validSubjob = false;
  if (subjob && hasData) {
    let matchingSubjobs = data.jobs.filter(job => job.id === subjob);
    validSubjob = matchingSubjobs.length === 1;
  }
  // store final subcontent to render in here
  // (unless rendering subjob panel)
  let content;
  // handle specified subjob
  if (subjob && hasData) {
    // render subjob panel if valid subjob available and we already have data loaded
    // (if job not yet finished, it will be that panel's job
    // to display that status)
    if (validSubjob) {
      return (
        <div
          style={{
            width: "95%",
            margin: "0 auto",
            marginTop: "3em"
          }}
        >
          <JobResult jobGroup={jobgroup} job={subjob} jobGroupData={data} predictedStructureUrl={alphaFoldUrl} />
        </div>
      );
    } else {
      // not a valid subjob selected, render in overview panel as
      // error message
      content = (
        <>
          <h2>Access failed</h2>
          <div className="bp3-text-large">Invalid subjob selected</div>
        </>
      );
    }
  } else {
    // no subjob selected (i.e. render job overview)
    // invalid jobgroup seletion;
    // complete failure, cannot do anything else other than display error message
    if (invalidJobgroup) {
      content = (
        <>
          <h2>Access failed</h2>
          <div className="bp3-text-large">Invalid job group identifier</div>
        </>
      );
    } else {
      // valid jobgroup, distinguish between loading and display state
      if (hasData) {
        let transformedData = transformJobgroupData(data);
        // JOB_DATA["Job123"]  // TODO: remove
        content = (
          <>
            <h2>Result overview: {data.name}</h2>
            <div className="bp3-text-muted" style={{ marginBottom: "2em" }}>
              Click on any table row to show results for respective sequence alignment depth.
              <br />
              {/* (<Icon icon="star" iconSize={12} /> = recommended result) */}
            </div>

            <ResultSummaryView
              jobGroup={jobgroup}
              selectedJob=""
              jobGroupData={transformedData}
            />
            <SummaryViewLegend />
          </>
        );
      } else {
        // still loading...
        // if connection error occurred, display explicitly and show spinner
        if (error) {
          content = (
            <>
              <Spinner size={Spinner.SIZE_MEDIUM} />
              <div className="bp3-text-large" style={{ margin: "1em" }}>
                Error connecting to server, automatic retry every{" "}
                {API_POLLING_INTERVAL / 1000} seconds.
              </div>
              <div>
                <Button minimal={false} onClick={reFetch} icon="refresh">
                  Retry
                </Button>
              </div>
            </>
          );
        } else {
          // otherwise just show loading spinner
          content = <Spinner size={Spinner.SIZE_MEDIUM} />;
        }
      }
    }
  }
  // render content sub-panel specified above
  // (all cases but subjob result display)
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        maxWidth: "700px",
        margin: "0 auto",
        marginTop: "3em"
      }}
    >
      {content}
    </div>
  );
};
export const FileUpload = ({ content, style }) => {
  const structureFileInput = useRef(null);
  return (
    <div
      style={{ ...style }}
      onClick={() => structureFileInput.current.click()}
    >
      <input
        ref={structureFileInput}
        type="file"
        style={{
          color: "transparent",
          width: "0",
          height: "0",
          opacity: "0"
        }}
      />
      {content}
    </div>
  );
};
