import { Map, Set } from "immutable";

/*

    Attempt at a generic selection reducer, written for contact map/EC panel first

    Note that pairs are treated as unsymmetric, i.e. (i, j) != (j, i). For symmetric
    cases, outside code calling reducer has to ensure i < j (or j > i) by convention.

*/
export const selectionReducer = (state, action) => {
  switch (action.action) {
    case "reset":
      return createEmptySelection();
    case "resetSecondaryStructure":
      return {
        ...state,
        secondaryStructures: createEmptySelection().secondaryStructures
      };
    case "setAll":
      return {
        ...state,
        pairs: action.pairs ? Set(action.pairs) : state.pairs,
        sites: action.sites ? Set(action.sites) : state.sites
      };
    case "togglePair":
      const currentPair = Map({
        i: action.i,
        j: action.j,
        segment_i: action.segment_i,
        segment_j: action.segment_j
      });

      const newPairs = toggleItem(currentPair, state.pairs, action.multiSelect);

      return {
        ...state,
        pairs: newPairs
      };

    case "toggleSite":
      const currentSite = Map({
        i: action.i,
        segment_i: action.segment_i
      });

      const newSites = toggleItem(currentSite, state.sites, action.multiSelect);

      // if pair table is given, toggle all incoming edges as well
      let newPairsForSite;
      if (action.pairTable) {
        // find all pairs that contain site
        const pairsForCurrentSite = getPairsForSite(
          action.pairTable,
          currentSite
        );

        // check is site was added or removed, treat edges correspondingly
        if (newSites.has(currentSite)) {
          // add all edges for site
          newPairsForSite = state.pairs.union(pairsForCurrentSite);
        } else {
          // remove all edges for that site
          newPairsForSite = state.pairs.subtract(pairsForCurrentSite);
        }
      } else {
        newPairsForSite = state.pairs;
      }

      return {
        ...state,
        sites: newSites,
        pairs: newPairsForSite
      };

    case "toggleSecondaryStructure":
      const newSecondaryStructures = toggleItem(
        action.element,
        state.secondaryStructures,
        action.multiSelect
      );

      return {
        ...state,
        secondaryStructures: newSecondaryStructures
      };

    default:
      throw new Error("Invalid action: " + JSON.stringify(action));
  }
};

/*
  Find all pairs where one interaction partner is the current site
*/
export const getPairsForSite = (pairTable, site) => {
  const i = site.get("i");
  const segment_i = site.get("segment_i");

  const sitePairs = pairTable
    .toArray()
    .filter(
      pair =>
        (pair.i === i && pair.segment_i === segment_i) ||
        (pair.j === i && pair.segment_j === segment_i)
    )
    .map(pair =>
      Map({
        i: pair.i,
        segment_i: pair.segment_i,
        j: pair.j,
        segment_j: pair.segment_j
      })
    );

  return Set(sitePairs);
};

export const toggleItem = (toggledItem, currentSelection, multiSelect) => {
  let newSelection;

  if (currentSelection.has(toggledItem)) {
    // if we already have item, remove it
    if (multiSelect) {
      // if multiselect is on, only remove current item
      newSelection = currentSelection.delete(toggledItem);
    } else {
      // otherwise, result is empty selection if selected item is only
      // one selected, otherwise set selection to this item from
      // bigger selection
      if(currentSelection.size === 1) {
        newSelection = Set();
      } else {
        newSelection = Set([toggledItem]);  
      }
    }
  } else {
    // if we don't have item, add it
    if (multiSelect) {
      // if multiselect is on, add item to other selected item
      newSelection = currentSelection.add(toggledItem);
    } else {
      // otherwise new selection is current items
      newSelection = Set([toggledItem]);
    }
  }

  return newSelection;
};

/*
  Create default empty selection
*/
export const createEmptySelection = () => {
  return {
    sites: Set(),
    pairs: Set(),
    secondaryStructures: Set()
  };
};

/*
    ---- Other reducers for reference (not used, original copies are in respective panels)
*/

/*
  use Array instead of crappy JS set, or TODO: use Immutable
*/
export const selectionReducerStronglyCoupledSites = (state, action) => {
  // console.log("REDUCER", "POSITIONS", state.positions, "ACTION", action);
  switch (action.action) {
    case "setAll":
      // console.log("DISPATCH ALL", JSON.stringify(action));
      // override selection completely with whatever is specified
      return {
        sites: action.sites != null ? Set(action.sites) : state.sites,
        pairs: action.pairs != null ? Set(action.pairs) : state.pairs
      };

    case "addSites":
      // dispatched change to site selection
      const selectedSiteIds = action.sites;

      // compute updated selection (remove any already selected sites if selected again)
      const newSelection = state.sites
        .filter(
          // keep all already selected sites if they are not in new one
          s => !selectedSiteIds.includes(s)
        )
        .concat(
          // vice versa, keep all newly selected sites if they are not in old selection
          selectedSiteIds.filter(s => !state.sites.includes(s))
        );

      return {
        ...state,
        sites: newSelection
      };

    case "reset":
      return {
        sites: [],
        pairs: []
      };

    default:
      // console.log("SHOULDNT HAPPEN!");
      return {
        sites: [],
        pairs: []
      };
  }
};

export const selectionReducerMutations = (state, action) => {
  switch (action.action) {
    case "setAll":
      return {
        sites: action.sites != null ? action.sites : state.sites,
        pairs: action.pairs != null ? action.pairs : state.pairs,
        substitutions:
          action.substitutions != null
            ? action.substitutions
            : state.substitutions,
        siteSubstitutions:
          action.siteSubstitutions != null
            ? action.siteSubstitutions
            : state.siteSubstitutions
      };

    case "addSites":
      // dispatched change to site selection
      const selectedSiteIds = action.sites;

      // compute updated selection (remove any already selected sites if selected again)
      const newSelection = state.sites
        .filter(
          // keep all already selected sites if they are not in new one
          s => !selectedSiteIds.includes(s)
        )
        .concat(
          // vice versa, keep all newly selected sites if they are not in old selection
          selectedSiteIds.filter(s => !state.sites.includes(s))
        );

      // adding a site in the use case of this panel eliminates siteSubstitutions
      return {
        ...state,
        sites: newSelection,
        siteSubstitutions: {}
      };

    case "reset":
      return {
        sites: [],
        pairs: [],
        substitutions: [],
        siteSubstitutions: {}
      };

    default:
      return {
        sites: [],
        pairs: [],
        substitutions: [],
        siteSubstitutions: {}
      };
  }
};
