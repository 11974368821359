import React, { Component } from "react";
import {
  Button,
  Intent,
  Radio,
  RadioGroup,
  HTMLSelect,
  Slider,
  Tab,
  Tabs,
  Tag
} from "@blueprintjs/core";
import {
  MAX_NUM_ALIGNMENT_THRESHOLDS,
  ALIGNMENT_THRESHOLD_BOUNDARIES,
  ADV_OPTIONS_HELP_TEXTS,
  SEQUENCE_DATABASES,
  ALIGNMENT_ITERATIONS,
  DEFAULT_IDENTITY_CUTOFF,
  SMALL_DIFF
} from "../../utils/Constants";
import { helpHeading, thresholdToBitscoreClass } from "../common/Helpers";

export class AlignmentSettings extends Component {
  render() {
    return (
      <Tabs
        animate={true}
        id="TabsAlignmentSettingsWrapper"
        vertical={false}
        defaultSelectedTabId="tab_align_sequence_search"
      >
        <Tab
          id="tab_align_sequence_search"
          title="Homology search"
          panel={
            <AlignmentSettingsSearch
              params={this.props.params}
              updateParams={this.props.updateParams}
            />
          }
        />
        <Tab
          id="tab_align_postprocessing"
          title="Sequence and position filters"
          panel={
            <AlignmentSettingsPostprocessing
              params={this.props.params}
              updateParams={this.props.updateParams}
            />
          }
        />
      </Tabs>
    );
  }
}

export class AlignmentSettingsSearch extends Component {
  // TODO: store thresholds as strings to be safe against floating point accidents?
  constructor(props) {
    super(props);
    this.state = {
      thresholdWarningMessage: null,
      thresholdErrorMessage: null,
      thresholdSliderValue: this.props.params.bitscore
        ? ALIGNMENT_THRESHOLD_BOUNDARIES.BITSCORE_INIT
        : ALIGNMENT_THRESHOLD_BOUNDARIES.EVALUE_INIT
    };
  }

  handleRemoveAlignmentThreshold(threshold) {
    this.props.updateParams(params => {
      if (params.bitscore) {
        return {
          bitscoreThresholds: params.bitscoreThresholds.filter(
            t => Math.abs(t - threshold) > SMALL_DIFF
          )
        };
      } else {
        return {
          eValueThresholds: params.eValueThresholds.filter(t => t !== threshold)
        };
      }
    });
  }

  handleAddAlignmentThreshold() {
    // this function looks like unnecessarily complicated state ping pong...
    // there must be a better way of solving this
    this.props.updateParams(params => {
      let thresholds = params.bitscore
        ? params.bitscoreThresholds
        : params.eValueThresholds;

      // callback for updating params from inside setState (need new
      // call for this since async)
      let setThresholds = newThresholds => {
        this.props.updateParams(params => {
          if (params.bitscore) {
            return {
              bitscoreThresholds: newThresholds
            };
          } else {
            return {
              eValueThresholds: newThresholds
            };
          }
        });
      };

      if (thresholds.length < MAX_NUM_ALIGNMENT_THRESHOLDS) {
        this.setState((state, props) => {
          if (
            thresholds.filter(
              t => Math.abs(t - state.thresholdSliderValue) < SMALL_DIFF
            ).length > 0
          ) {
            return {
              thresholdErrorMessage: "Inclusion threshold already selected."
            };
          } else {
            setThresholds(thresholds.concat(state.thresholdSliderValue));

            return {
              thresholdErrorMessage: null
            };
          }
        });
      } else {
        this.setState({
          thresholdWarningMessage:
            "Only up to " +
            MAX_NUM_ALIGNMENT_THRESHOLDS +
            "alignment thresholds are allowed."
        });
      }
    });
  }

  renderAlignmentThresholds() {
    let thresholds;
    let thresholdsForRendering;

    // select which tresholds to render (bitscore or E-value) and format
    if (this.props.params.bitscore) {
      thresholds = this.props.params.bitscoreThresholds.sort((a, b) => a - b);
      thresholdsForRendering = thresholds.map(threshold =>
        threshold.toFixed(2)
      );
    } else {
      thresholds = this.props.params.eValueThresholds.sort((a, b) => a - b);
      thresholdsForRendering = thresholds.map(threshold => <span>10<sup>-{threshold}</sup></span>);
    }

    // sort thresholds in ascending order
    thresholds = thresholds.sort((a, b) => a - b);

    // only make closable if more than one given
    let thresholdsRendered = thresholdsForRendering.map((threshold, index) => (
      <Tag
        onRemove={
          thresholds.length > 1
            ? // use pre-rendering version (i.e. the number) for calling event handler
              () => this.handleRemoveAlignmentThreshold(thresholds[index])
            : null
        }
        key={index}
        large={true}
        minimal={true}
        round={false}
        style={{ margin: "0.1em" }}
      >
        {threshold}
      </Tag>
    ));

    // slider to add new bitscore
    let addThresholdSlider = (
      <Slider
        min={
          this.props.params.bitscore
            ? ALIGNMENT_THRESHOLD_BOUNDARIES.BITSCORE_MIN
            : ALIGNMENT_THRESHOLD_BOUNDARIES.EVALUE_MIN
        }
        max={
          this.props.params.bitscore
            ? ALIGNMENT_THRESHOLD_BOUNDARIES.BITSCORE_MAX
            : ALIGNMENT_THRESHOLD_BOUNDARIES.EVALUE_MAX
        }
        stepSize={this.props.params.bitscore ? 0.01 : 1}
        labelStepSize={
          this.props.params.bitscore
            ? ALIGNMENT_THRESHOLD_BOUNDARIES.BITSCORE_MAX -
              ALIGNMENT_THRESHOLD_BOUNDARIES.BITSCORE_MIN
            : ALIGNMENT_THRESHOLD_BOUNDARIES.EVALUE_MAX -
              ALIGNMENT_THRESHOLD_BOUNDARIES.EVALUE_MIN
        }
        labelRenderer={
          this.props.params.bitscore
            ? value => (
                <div>
                  {value.toFixed(2)}
                  <br />
                  {thresholdToBitscoreClass(value)}
                </div>
              )
            : value => <span>10<sup>-{value}</sup></span>
        }
        value={this.state.thresholdSliderValue}
        onChange={value => {
          this.setState({
            thresholdSliderValue: value,
            thresholdErrorMessage: null
          });
        }}
      />
    );

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "2em",
          marginBottom: "2em",
          justifyContent: "center",
          alignItems: "center",
          width: "100%"
        }}
      >
        {helpHeading(
          <b>
            {this.props.params.bitscore ? "Bitscore" : "E-value"} sequence
            inclusion thresholds
          </b>,
          ADV_OPTIONS_HELP_TEXTS.ALIGN.THRESHOLD_LIST
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "left",
            marginTop: "1em"
          }}
        >
          {thresholdsRendered}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "80%",
            marginTop: "1em"
            // marginBottom: "1em"
          }}
        >
          {addThresholdSlider}
          <Button
            rightIcon="add"
            disabled={thresholds.length >= MAX_NUM_ALIGNMENT_THRESHOLDS}
            style={{ marginLeft: "2em" }}
            onClick={() => this.handleAddAlignmentThreshold()}
          >
            Add
          </Button>
        </div>
        {this.state.thresholdErrorMessage ? (
          <Tag intent={Intent.DANGER} style={{ marginTop: "2em" }}>
            {this.state.thresholdErrorMessage}
          </Tag>
        ) : null}
        {(this.props.params.bitscore &&
          this.props.params.bitscoreThresholds.length ===
            MAX_NUM_ALIGNMENT_THRESHOLDS) ||
        (!this.props.params.bitscore &&
          this.props.params.eValueThresholds.length ===
            MAX_NUM_ALIGNMENT_THRESHOLDS) ? (
          <Tag intent={Intent.WARNING} style={{ marginTop: "2em" }}>
            Only up to {MAX_NUM_ALIGNMENT_THRESHOLDS} alignment thresholds
            allowed.
          </Tag>
        ) : null}
        {(this.props.params.bitscore &&
          this.props.params.bitscoreThresholds.length === 1) ||
        (!this.props.params.bitscore &&
          this.props.params.eValueThresholds.length === 1) ? (
          <Tag intent={Intent.WARNING} style={{ marginTop: "2em" }}>
            At least one alignment threshold required.
          </Tag>
        ) : null}
      </div>
    );
  }

  handleChangeThresholdType(selectedValue) {
    let isBitscore = selectedValue === "bitscore";

    this.props.updateParams(params => {
      return { bitscore: isBitscore };
    });

    this.setState({
      thresholdSliderValue: isBitscore
        ? ALIGNMENT_THRESHOLD_BOUNDARIES.BITSCORE_INIT
        : ALIGNMENT_THRESHOLD_BOUNDARIES.EVALUE_INIT,
      thresholdErrorMessage: null
    });
  }

  renderThresholdType() {
    return (
      <div>
        {helpHeading(
          <b>Alignment threshold type</b>,
          ADV_OPTIONS_HELP_TEXTS.ALIGN.THRESHOLD_TYPE
        )}
        <div style={{ marginTop: "1em" }}>
          <RadioGroup
            inline={true}
            name="group_threshold"
            onChange={event =>
              this.handleChangeThresholdType(event.currentTarget.value)
            }
            selectedValue={this.props.params.bitscore ? "bitscore" : "evalue"}
          >
            <Radio label="Bitscore" value="bitscore" />
            <Radio label="E-value" value="evalue" />
          </RadioGroup>
        </div>
      </div>
    );
  }

  findIdentityCutoff(databaseName) {
    let selectedDatabase = SEQUENCE_DATABASES.find(
      db => db.name === databaseName
    );

    if (selectedDatabase) {
      return selectedDatabase.identityCutoff;
    } else {
      return DEFAULT_IDENTITY_CUTOFF;
    }
  }

  handleSequenceDatabaseChange(database) {
    let redundancyCutoff = this.findIdentityCutoff(database);

    this.props.updateParams(params => {
      return {
        sequenceDatabase: database,
        databaseRedundancyCutoff: redundancyCutoff,

        // sequenceIdentityFilter may not be larger than maximum
        // sequence identity in database
        sequenceIdentityFilter: redundancyCutoff,

        // for theta, update more conservatively: only fix nonsense values
        theta: params.theta > redundancyCutoff ? redundancyCutoff : params.theta
      };
    });
  }

  renderSequenceDatabase() {
    return (
      <div style={{ marginTop: "2em" }}>
        {helpHeading(
          <b>Sequence database</b>,
          ADV_OPTIONS_HELP_TEXTS.ALIGN.DATABASE
        )}
        <HTMLSelect
          minimal={true}
          value={this.props.params.sequenceDatabase}
          onChange={event =>
            this.handleSequenceDatabaseChange(event.currentTarget.value)
          }
        >
          {SEQUENCE_DATABASES.map((db, i) => (
            <option value={db.name} key={i}>
              {db.description}
            </option>
          ))}
        </HTMLSelect>
      </div>
    );
  }

  renderIterations() {
    return (
      <div style={{ marginTop: "2em", minWidth: "70%" }}>
        {helpHeading(
          <b>Search iterations</b>,
          ADV_OPTIONS_HELP_TEXTS.ALIGN.ITERATIONS
        )}
        <div style={{ marginTop: "1em" }}>
          <Slider
            min={ALIGNMENT_ITERATIONS.MIN}
            max={ALIGNMENT_ITERATIONS.MAX}
            value={this.props.params.alignmentIterations}
            onChange={value =>
              this.props.updateParams(params => {
                return { alignmentIterations: value };
              })
            }
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column"
        }}
      >
        {this.renderThresholdType()}
        {this.renderAlignmentThresholds()}
        {this.renderIterations()}
        {this.renderSequenceDatabase()}
      </div>
    );
  }
}

export class AlignmentSettingsPostprocessing extends Component {
  genericLabeledSlider(param, min, max, title, helpText, labels, topMargin) {
    let labelDiv;
    if (labels) {
      labelDiv = (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
          className="bp3-text-small"
        >
          {labels.map((l, i) => (
            <span key={l}>{l}</span>
          ))}
        </div>
      );
    }

    return (
      <div style={{ marginTop: topMargin + "em", minWidth: "70%" , width:"80%"}}>
        {helpHeading(<b>{title}</b>, helpText)}
        <div style={{ marginTop: "1em" }}>
          <Slider
            min={min}
            max={max}
            labelStepSize={max - min}
            value={this.props.params[param]}
            onChange={value => {
              this.props.updateParams(params => {
                let newObj = {};
                newObj[param] = value;
                return newObj;
              });
            }}
            labelRenderer={value =>
              (Math.abs(value - Math.round(value)) < SMALL_DIFF
                ? value.toFixed(0)
                : value.toFixed(1)) + "%"
            }
            stepSize={0.1}
            // intent={Intent.WARNING}
          />
        </div>
        {labelDiv}
      </div>
    );
  }

  renderSequenceFilter() {
    return this.genericLabeledSlider(
      "sequenceIdentityFilter",
      50,
      this.props.params.databaseRedundancyCutoff,
      "Removing similar sequences",
      ADV_OPTIONS_HELP_TEXTS.ALIGN.SEQID_FILTER,
      ["Strong filtering", "No filtering"],
      2
    );
  }

  renderTheta() {
    return this.genericLabeledSlider(
      "theta",
      50,
      this.props.params.databaseRedundancyCutoff,
      "Downweighting similar sequences",
      ADV_OPTIONS_HELP_TEXTS.ALIGN.THETA,
      ["Strong downweighting", "No downweighting"],
      2
    );
  }

  renderThetaWarning() {
    if (this.props.params.theta > this.props.params.sequenceIdentityFilter) {
      return (
        <Tag intent={Intent.WARNING} style={{ marginTop: "0.5em" }}>
          Must be smaller than sequence filtering cutoff to have an effect.
        </Tag>
      );
    } else {
      return;
    }
  }

  // gap coverage per column and sequence

  renderColumnCoverage() {
    return this.genericLabeledSlider(
      "minimumColumnCoverage",
      10,
      90,
      "Position filter",
      ADV_OPTIONS_HELP_TEXTS.ALIGN.MIN_COL_COVERAGE,
      ["Include positions with many gaps", "Exclude positions with many gaps"],
      0
    );
  }

  renderSequenceCoverage() {
    return this.genericLabeledSlider(
      "minimumSequenceCoverage",
      10,
      90,
      "Sequence fragment filter",
      ADV_OPTIONS_HELP_TEXTS.ALIGN.MIN_SEQ_COVERAGE,
      ["Include fragments", "Exclude fragments"],
      2
    );
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column"
        }}
      >
        {this.renderColumnCoverage()}
        {this.renderSequenceCoverage()}
        {this.renderSequenceFilter()}
        {this.renderTheta()}
        {this.renderThetaWarning()}
      </div>
    );
  }
}
