import React, {
  Component,
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { Set, Map } from "immutable";
import { useDebouncedCallback } from "use-debounce";
import {
  Button,
  ButtonGroup,
  Divider,
  ResizeSensor,
  NonIdealState,
} from "@blueprintjs/core";

import "./BioBlocks.css"; // hide panels / change default styles of 3D viewer
import {
  NGLComponent,
  BioblocksPDB,
  AminoAcid,
  NGLInstanceManager,
} from "bioblocks-viz";
// import { ColormakerRegistry } from "ngl";
import { saveAs } from "file-saver";
// import * as NGL from "ngl";

import {
  STRUCTURE_STATUS,
  STRUCTURE_CLASSES,
  STRUCTURE_CLASS_MAP_INV,
} from "./../../utils/Structures";

import {
  createSiteId,
  parseSiteId,
  DEFAULT_SEGMENT_ID,
} from "../../utils/Segments";
import createPanel from "./Panel";
import { StructureSelectionMenu } from "./StructureSelection";
import { RESET_SELECTION_ICON } from "../../utils/Constants";
import { ErrorRefetcher } from "../common/Helpers";

/*
 TODO: update this to be generic
*/
/*
export const selectionReducer = (state, action) => {
  // console.log("REDUCER", "POSITIONS", state.positions, "ACTION", action);
  switch (action.action) {
    case "setAll":
      // console.log("DISPATCH ALL", JSON.stringify(action));
      // override selection completely with whatever is specified
      return {
        sites: action.sites != null ? action.sites : state.sites,
        pairs: action.pairs != null ? action.pairs : state.pairs
      };

    default:
      console.log("SHOULDNT HAPPEN!");
      break;
  }
};
*/

/*
  Example color mapping function based on NGL docs
*/
export const exampleColorFunc = (atom) => {
  if (atom.serial < 1000) {
    return 0x0000ff; // blue
  } else if (atom.serial > 2000) {
    return 0xff0000; // red
  } else {
    return 0x00ff00; // green
  }
};

/*
  Register new color scheme for NGL viewer and
  return its ID for use as prop by parent component

  1) https://github.com/arose/ngl/issues/59
  2) http://nglviewer.org/ngl/api/manual/coloring.html#selection-based-coloring
*/
export const registerColorScheme = (colorFunc) => {
  var schemeId = NGLInstanceManager.instance.ColormakerRegistry.addScheme(
    function (params) {
      this.atomColor = function (atom) {
        return colorFunc(atom);
      };
    }
  );
  // console.log("+++ REGISTERED COLOR SCHEME", schemeId); // TODO: remove again
  return schemeId;
};

/*
  Make sure color value is a hexadecimal number;
  auto-convert hex strings
*/
export const ensureHexNumber = (hex) => {
  if (typeof hex === "string") {
    return parseInt(hex.replace("#", ""), 16);
  } else {
    return hex;
  }
};

/*
  Register an NGL color scheme from a per-residue color mapping
  (can contain hex strings or hex numbers). All residues that cannot
  be mapped will default to defaultColor.

  TODO: allow to use hex codes and turn into hexadecimal number
*/
export const registerColorSchemeFromResidueMap = (colorMap, defaultColor) => {
  const colorFunc = (atom) => {
    const res = atom.resno;
    if (Map.isMap(colorMap)) {
      // immutable Map
      if (colorMap.has(res)) {
        return ensureHexNumber(colorMap.get(res));
      } else {
        return ensureHexNumber(defaultColor);
      }
    } else {
      // regular object
      if (res in colorMap) {
        return ensureHexNumber(colorMap[res]);
      } else {
        return ensureHexNumber(defaultColor);
      }
    }
  };

  return registerColorScheme(colorFunc);
};

/*
  Override for Bioblocks PDB loader that does not allow to pass
  options to loader

  TODO: refactor and move to some more generic location
*/
export const loadStructure = async (file, options) => {
  const result = new BioblocksPDB();
  result.nglData = await NGLInstanceManager.instance.autoLoad(file, options);
  result.fileName = typeof file === "string" ? file : file.name;

  return result;
};

// order in which to feed structures from classes exp/pred/user into
// 3D viewer component
const STRUCTURE_CLASS_SORTING_ORDER = {};
STRUCTURE_CLASS_SORTING_ORDER[STRUCTURE_CLASSES.USER] = 0;
STRUCTURE_CLASS_SORTING_ORDER[STRUCTURE_CLASSES.EXPERIMENTAL] = 1;
STRUCTURE_CLASS_SORTING_ORDER[STRUCTURE_CLASSES.PREDICTED] = 2;

// only display coordinates in file for now (assymmetric unit),
// other options are default, BU1, BU2, ...
export const SELECTED_ASSEMBLY = "AU";

// https://blog.bitsrc.io/understanding-error-boundaries-in-react-e58f15ae1f38
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log("Structure rendering error", error, info);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Error rendering structure</h1>;
    }
    return this.props.children;
  }
}

/*
  Note that reducer called by dispatchSelection must have actions
  "addSites" and "reset"

  TODO: allow custom representations for site and line highlight
  (including residue highlight for pairs)
*/
export const StructureViewer = ({
  structures,
  selection,
  dispatchSelection,
  dispatchReset,
  dispatchSelectionOld,
  colorScheme,
  distanceStyleFunc,
  selectionColor,
  imageExportFileName,
  availableStructures,
  dispatchStructureSelection,
  superimposeStructures,
  refetchFailedStructures,
  legend,
  colorSchemeSelection,
}) => {
  if (!selectionColor) {
    // selectionColor = "#feb83f";
    selectionColor = "orange";
  }

  // any problems loading structure info (not 3D models themselves)
  const infoLoadingError =
    availableStructures && availableStructures.get("error");

  // isolate structures for display (as well as loading/failed for status display)
  const {
    structuresForDisplay,
    loadingStructures,
    failedStructures,
  } = useMemo(() => {
    if (structures) {
      // extract structures that have already been loaded;
      // also move user/experimental structures to beginning of list
      // since NGLViewer component uses first structure as basis
      // for superposition
      const validStructures = structures
        .filter((v, k) => v.get("status") === STRUCTURE_STATUS.LOADED)
        .sortBy((v, k) => STRUCTURE_CLASS_SORTING_ORDER[k.get("class")]);

      const loadingStructures = structures.filter(
        (v, k) => v.get("status") === STRUCTURE_STATUS.LOADING
      );

      const failedStructures = structures.filter(
        (v, k) => v.get("status") === STRUCTURE_STATUS.ERROR
      );

      // console.log("*** VALID", JSON.stringify(validStructures.keySeq()));

      // console.log("+++ VALID:", imageExportFileName, JSON.stringify(validStructures.keySeq()));
      // validStructures.forEach((v, k) =>
      //  console.log("+++ STRUC", JSON.stringify(k), v.get("structure"))
      // );

      return {
        structuresForDisplay: validStructures
          .map((v, k) => v.get("structure"))
          .toList()
          .toJS(),
        loadingStructures: loadingStructures.count(),
        failedStructures: failedStructures.count(),
      };
    } else {
      return {
        structuresForDisplay: [],
        // little trick to have spinner show when component first comes up but structures are not yet triggered for loading
        loadingStructures: 0,
        failedStructures: 0,
      };
    }
  }, [structures]);

  /*console.log(
    "*** STRUCTURES\n",
    structuresForDisplay.map((v, i) => i + "..." + v.nglData.name).join("\n")
  ); // TODO: remove*/

  // reference to Cytoscape component for triggering imperative effects like resize, fit, export
  // const nglRef = useRef(null);
  const [nglRef, setNglRef] = useState(null);

  // https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const nglRefCallback = useCallback((node) => {
    // nglRef.current = node;
    setNglRef(node);
  }, []);

  // cytoscape panel resize debouncer
  const [debouncedResizeCallbackNGL] = useDebouncedCallback(() => {
    // console.log("3D viewer resize triggered");
    if (nglRef && nglRef.state && nglRef.state.stage) {
      nglRef.state.stage.handleResize();
    }
  }, 200);

  // helper state if rendering error occurred in NGL
  const [nglErrorState, setNglErrorState] = useState(null);
  // const [nglViewerKey, setNglViewerKey] = useState(0);

  // dummy structure loader
  // const [structure, setStructure] = useState(null);
  /*
  useEffect(() => {
    console.log("downloading pdb");
    // BioblocksPDB.createPDB("https://files.rcsb.org/download/1ctq.pdb").then(
    // BioblocksPDB.createPDB(
    loadStructure(
      "http://localhost:5001/monomer/123a9aafeaee48edb05f25a25a1d880d/b0.1/downloads/remapped_pdb_files?item=b7df601a6d9ab8d9dd7bcb16de3b2984",
      { ext: "pdb" }
    ).then(
      x => setStructure(x),
      x => console.log("error loading structure", x)
    );
    // console.log(NGL.autoLoad("https://files.rcsb.org/download/821P.pdb"));
  }, []);
  */

  // stereo view on/off?
  const [stereoViewStatus, setStereoViewStatus] = useState(false);

  // superimposition on/off?
  const [superpositionStatus, setSuperpositionStatus] = useState(
    superimposeStructures
  );

  // toggle superimposition status based on props (only trigger if true, default is false)
  useEffect(() => {
    if (nglRef && superimposeStructures) {
      nglRef.onSuperpositionToggle();
    }
  }, [nglRef, superimposeStructures]);

  useEffect(() => {
    if (nglRef && nglRef.state && nglRef.state.stage) {
      // console.log("REGISTRY", ColormakerRegistry);  // TODO: remove
      const stage = nglRef.state.stage;

      // show pairs to highlight
      // TODO: allow to disable pair display?
      // TODO: enable/disable distance display (and use min atom dist)
      let pairsToShow;
      if (selection.pairs) {
        if (Set.isSet(selection.pairs)) {
          // new mode
          pairsToShow = selection.pairs
            .map((x) => [x.get("i"), x.get("j")])
            .toList()
            .toJS();
        } else {
          // legacy mode
          pairsToShow = selection.pairs.map((x) => [
            parseSiteId(x[0]).pos,
            parseSiteId(x[1]).pos,
          ]);
        }
      }

      // show pairs to higlight
      let sitesToShow;
      if (selection.sites) {
        if (Set.isSet(selection.sites)) {
          // new mode
          sitesToShow = selection.sites
            .map((x) => x.get("i"))
            .toList()
            .toJS();
        } else {
          // legacy mode
          sitesToShow = selection.sites.map((x) => parseSiteId(x).pos);
        }
      }

      // iterate through all structure objects
      for (const structureComponent of stage.compList) {
        // get key assigned to structure when loading
        const structureKey = structureComponent.structure.key;

        // console.log("+++ INTERNAL KEY", JSON.stringify(structureKey));

        let curColorScheme;
        // check if a color scheme was assigned
        if (colorScheme) {
          // check if color scheme per structure or one color scheme for all structures
          if (Map.isMap(colorScheme)) {
            // get scheme for current structure; note that for simplicity jobGroup and job
            // are not used in query as this is not present for user structures
            curColorScheme = colorScheme.get(
              Map({
                id: structureKey.get("id"),
                class: structureKey.get("class"),
              })
            );
          } else {
            curColorScheme = colorScheme;
          }
        } else {
          curColorScheme = "residueindex";
        }

        structureComponent.removeAllRepresentations();
        try {
          structureComponent.addRepresentation("cartoon", {
            color: curColorScheme,
            assembly: SELECTED_ASSEMBLY,
            // color: "residueindex"
            // colorScheme: curColorScheme
            // color: "residueindex",
            // colorScheme: schemeId,
            // color: "blue"
            // colorScale: [ "red", "white", "green" ]
          });

          // secondary structure highlighting
          if (selection && selection.secondaryStructures) {
            selection.secondaryStructures.forEach((sse, idx) => {
              const selection = `${sse.get("start")}-${sse.get("end")}`;
              const rep = structureComponent.addRepresentation("cartoon", {
                color: selectionColor,
                radiusScale: 2,
                sele: selection,
                assembly: SELECTED_ASSEMBLY,
              });
              rep.setParameters({ wireframe: true });
            });
          }
        } catch (e) {
          // console.log("ERROR - COULD NOT SET CARTOON", structureKey);  // TODO: remove
          setNglErrorState(structureKey);
        }

        if (sitesToShow && sitesToShow.length > 0) {
          structureComponent.addRepresentation("spacefill", {
            sele: sitesToShow.join(", "),
            // color: "residueindex"
            // colorScheme: schemeId
            color: curColorScheme ? curColorScheme : "residueindex",
            assembly: SELECTED_ASSEMBLY,
          });
        }

        if (pairsToShow && pairsToShow.length > 0) {
          // console.log("PAIRS TO SHOW", pairsToShow);
          // http://nglviewer.org/ngl/api/typedef/index.html#static-typedef-DistanceRepresentationParameters
          let distanceStyle;

          if (distanceStyleFunc) {
            // apply custom styling function if supplied
            // TODO: unclear if this actually allows to override anything in a meaningful way
            distanceStyle = distanceStyleFunc(selection.pairs);
          } else {
            distanceStyle = {
              color: selectionColor,
              labelSize: 0, // disable label
            };
          }

          structureComponent.addRepresentation("distance", {
            atomPair: pairsToShow.map((pair) => pair.map((pos) => pos + ".CA")),
            ...distanceStyle,
            assembly: SELECTED_ASSEMBLY,
          });
        }
      }
    }
  }, [
    nglRef,
    selection,
    structures,
    colorScheme,
    selectionColor,
    distanceStyleFunc,
  ]);

  const renderViewer = () => {
    let panelContent;

    if (failedStructures > 0 || infoLoadingError) {
      panelContent = (
        <ErrorRefetcher
          refetcher={() => {
            if (infoLoadingError) {
              // console.log("### REFETCH INFO"); // TODO: remove
              availableStructures.get("refetch")();
            } else {
              if (refetchFailedStructures) {
                // console.log("### REFETCH!"); // TODO: remove
                refetchFailedStructures();
              }
            }
          }}
        />
      );
    } else {
      let viewerContent;
      if (nglErrorState) {
        viewerContent = (
          <NonIdealState
            title="Error displaying structure"
            icon="error"
            description="For now, please reload page and select other structures. We are working on a fix."
            // action={
            //   <Button
            //     onClick={() => {
            //       setNglViewerKey(nglViewerKey + 1);
            //       setNglErrorState(null);
            //     }}
            //     minimal={true}
            //     icon="repeat"
            //   >
            //     Retry
            //   </Button>
            // }
          />
        );
      } else {
        viewerContent = (
          <ErrorBoundary>
            <ResizeSensor onResize={() => debouncedResizeCallbackNGL()}>
              <NGLComponent
                cardProps={{
                  iconSrc: "",
                }}
                ref={nglRefCallback}
                experimentalProteins={
                  structuresForDisplay ? structuresForDisplay : []
                }
                isDataLoading={false}
                height={"100%"}
                width={"100%"}
                hoveredResidueTooltipTextCb={(hoverInfo) => {
                  const { atom } = hoverInfo;
                  const aa = AminoAcid.fromThreeLetterCode(atom.resname);
                  const resname = aa ? aa.singleLetterCode : atom.resname;
                  const structureClass = atom.structure.key.get("class");
                  const structureClassMapped = STRUCTURE_CLASS_MAP_INV.get(
                    structureClass
                  );
                  return `${resname}${atom.resno} (${atom.structure.name}, ${structureClassMapped})`;
                  // (:${atom.chainname})
                }}
                // candidateResidues={[]}
                /* hoveredResidues={
        // currently only single segment supported, so just extract
        // pos and drop segment info
        selection.sites
          ? selection.sites.map(x => parseSiteId(x).pos)
          : null
      } */
                // addHoveredResidues={(residues) => console.log("HOVERED", residues)}
                onResidueClick={(proxy) => {
                  // TODO: make use of modifier keys for consistent selection handling across all panels
                  /*
        // All 4 are booleans.
        console.log(`alt: ${proxy.altKey}`);
        console.log(`ctrl: ${proxy.ctrlKey}`);
        console.log(`meta: ${proxy.metaKey}`);
        console.log(`shift: ${proxy.shiftKey}`);
        */

                  if (!proxy || !proxy.atom || !proxy.atom.resno) {
                    return;
                  }

                  // cf. https://github.com/cBioCenter/bioblocks-viz/blob/885eeb04ac16ea83de884372f6f2fddf5dc1cccf/src/component/NGLComponent.tsx#L533
                  const atom = proxy.atom || proxy.closestBondAtom;
                  //  addCandidateResidues([atom.resno]);
                  // console.log("clicked residue: ", atom.resno);
                  const residues = [atom.resno];

                  const selectedSiteIds = residues.map((r) =>
                    createSiteId(DEFAULT_SEGMENT_ID, r)
                  );

                  if (dispatchSelectionOld) {
                    dispatchSelectionOld({
                      action: "addSites",
                      sites: selectedSiteIds,
                    });
                  }

                  // coupling to new-style reducer
                  if (dispatchSelection) {
                    dispatchSelection(
                      residues.map((r) => ({
                        i: r,
                        segment_i: DEFAULT_SEGMENT_ID,
                      }))
                    );
                  }
                }}

                // addLockedResiduePair={residuePair =>
                //   console.log("LOCKED", residuePair)
                // }
                /*addHoveredResidues={residues => console.log(residues)}*/
                // addCandidateResidues={residues => {
                //   // note that first clicked residue is candidate,
                //   // once component has candidate via props, further
                //   // clicked residue will create a locked residue pair

                //   // console.log("ADD CANDIDATE", residues); // TODO: remove

                //   // map to site identifiers including segment (3D viewer only
                //   // has residue numbers at the moment)

                //   // new solution: move entire handling of residue selection into reducer
                //   // rather than doing here in viewer
                //   const selectedSiteIds = residues.map(r =>
                //     createSiteId(DEFAULT_SEGMENT_ID, r)
                //   );

                //   if (dispatchSelectionOld) {
                //     dispatchSelectionOld({
                //       action: "addSites",
                //       sites: selectedSiteIds
                //     });
                //   }

                //   // coupling to new-style reducer
                //   if (dispatchSelection) {
                //     dispatchSelection(
                //       residues.map(r => ({ i: r, segment_i: DEFAULT_SEGMENT_ID }))
                //     );
                //   }

                //   /*
                //   // old solution:

                //   // remove re-selected sites, add all others
                //   const newSelection = selection.sites
                //     .filter(
                //       // keep all already selected sites if they are not in new one
                //       s => !selectedSiteIds.includes(s)
                //     )
                //     .concat(
                //       // vice versa, keep all newly selected sites if they are not in old selection
                //       selectedSiteIds.filter(s => !selection.sites.includes(s))
                //     );

                //   console.log("NEW SELECTION", newSelection); // TODO: remove

                //   // dispatch new selection
                //   dispatchSelection({
                //     action: "setAll",
                //     sites: newSelection,
                //     pairs: []
                //   });
                //   */
                // }}

                /* removeAllLockedResiduePairs={() =>
        console.log("removeAllLockedResiduePairs")
      }
      removeCandidateResidues={() =>
        console.log("removeCandidateResidues")
      }
      removeHoveredResidues={() => console.log("removeHoveredResidues")}
      removeLockedResiduePair={() =>
        console.log("removeLockedResiduePair")
      }
      removeNonLockedResidues={() =>
        console.log("removeNonLockedResidues")
      } */
              />
            </ResizeSensor>
          </ErrorBoundary>
        );
      }

      panelContent = (
        <>
          {viewerContent}
          <div
            style={{
              display: infoLoadingError ? "none" : "flex",
              justifyContent: "center",
            }}
          >
            <ButtonGroup minimal={true}>
              <Button
                icon="locate"
                title="Center"
                onClick={() => {
                  if (nglRef) {
                    nglRef.centerCamera();
                  }
                }}
              ></Button>
              <Button
                icon="layers"
                title="Toggle structure superposition"
                active={superpositionStatus}
                onClick={() => {
                  if (nglRef) {
                    nglRef.onSuperpositionToggle();
                    setSuperpositionStatus(!superpositionStatus);
                  }
                }}
              ></Button>
              <Button
                icon="eye-open"
                title="Toggle stereo view"
                active={stereoViewStatus}
                onClick={() => {
                  if (nglRef) {
                    nglRef.switchCameraType();
                    setStereoViewStatus(!stereoViewStatus);
                  }
                }}
              ></Button>
              {colorSchemeSelection}
              {dispatchReset || dispatchSelectionOld ? (
                <Button
                  title="Clear selection"
                  icon={RESET_SELECTION_ICON}
                  onClick={() => {
                    if (dispatchReset) {
                      dispatchReset();
                    }
                    if (dispatchSelectionOld) {
                      dispatchSelectionOld({ action: "reset" });
                    }
                  }}
                />
              ) : null}

              {availableStructures !== undefined ? (
                <>
                  <Divider />
                  <StructureSelectionMenu
                    availableStructures={availableStructures}
                    dispatchStructureSelection={dispatchStructureSelection}
                    shownStructures={structures}
                    target={
                      <Button
                        title="Select displayed structures"
                        icon="database"
                        disabled={!structures}
                      >
                        Structures
                      </Button>
                    }
                  />
                </>
              ) : null}
              <Divider />
              <Button
                title="Export image"
                icon="import"
                onClick={() => {
                  if (
                    nglRef &&
                    nglRef.state &&
                    nglRef.state.stage &&
                    imageExportFileName
                  ) {
                    nglRef.state.stage
                      .makeImage({
                        factor: 5,
                        antialias: true,
                        trim: true,
                        transparent: true,
                      })
                      .then((x) => {
                        saveAs(x, imageExportFileName);
                      }, null);

                    /*.then(function(blob) {
                    NGL.download( blob, "screenshot.png" );
                  });*/
                  }
                }}
              />
              {legend ? (
                <>
                  <Divider />
                  {legend}
                </>
              ) : null}
            </ButtonGroup>
          </div>
        </>
      );
    }

    /*
    <div
        style={{
          display: "flex",
          // flexGrow: "1",
          flexDirection: "column",
          alignItems: "center",
          //flexGrow: "1",
          // marginRight: "1em",
          // backgroundColor: "#EEEEEE",
          textAlign: "center",
          minWidth: "500px",
          width: "35vw",
          height: "75vh",
          minHeight: "500px",
          marginBottom: "1em",
          overflow: "auto",
          resize: "horizontal",
          borderWidth: "1pt",
          borderStyle: "solid",
          borderColor: "#efefef"
        }}
      >*/

    const isLoading =
      availableStructures &&
      !availableStructures.get("error") &&
      ((availableStructures && availableStructures.get("loading")) ||
        loadingStructures > 0);

    return createPanel(
      panelContent,
      {
        justifyContent: "space-between",
        alignItems: "stretch",
        width: "32vw",
        // flexShrink: "0"
        // textAlign: "center"
      },
      isLoading
    );
  };

  return renderViewer();
};

export default StructureViewer;
