import React, { useEffect, useState, useReducer, useMemo } from "react";
import { Map, Range, List } from "immutable";
import {
  Button,
  ButtonGroup,
  Divider,
  Icon,
  Intent,
  Menu,
  NonIdealState,
  MenuItem,
  Popover,
  Position,
  ResizeSensor,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import { useDebouncedCallback } from "use-debounce";
import { StructureViewer } from "./StructureViewer";
import {
  createSequenceColorMapperPerClass,
  getColorSchemePerModel,
  COLOR_MAP_PER_CLASS,
  COLOR_MAP_PER_CLASS_SINGLE_HUE,
} from "../common/StructureColors";

import DataTable from "./DataTable";
import createPanel from "./Panel";
import {
  ContactMap,
  extractSecondaryStructureSegments,
  SCALE_COUPLINGS_POINT_SIZE,
} from "./ContactMap";
import { CouplingsNetwork } from "./CouplingsNetwork";
import { NO_SITES_WARNING } from "./StronglyCoupledSites"; // TODO: refactor to somewhere else
import { ErrorRefetcher, OptionMenu } from "../common/Helpers";
import { Legend, makeCircle } from "../common/Legend";
import { ContactSelectionMenu } from "./StructureSelection";
import { computePrecision, addDistances } from "./../../utils/Couplings";
import {
  DISTANCE_MAP_MODE,
  SECONDARY_STRUCTURE_MODE,
} from "./../../utils/Structures";
import { DEFAULT_SEGMENT_ID, createSegmentPrefix } from "../../utils/Segments";
import { selectionReducer, createEmptySelection } from "./Reducers";
import { saveImage } from "../external/Plotly";
import { RESET_SELECTION_ICON } from "../../utils/Constants";

const WINDOW_RESIZE_DEBOUNCE = 5;

// unique plot div id for saving figure from outside component,
// see https://github.com/plotly/react-plotly.js/issues/111
const CONTACTMAP_DIV_ID = "plotly_contact_map";

// default residue color for residues not covered by alignment/model
const DEFAULT_STRUCTURE_COLOR = "#FFFFFF";

export const NO_DATA_WARNING = (
  <NonIdealState
    icon="warning-sign"
    title="No couplings or contacts available"
    description="Relax couplings filtering criteria in settings panel or select contacts to display."
  />
);

/*
  TODO: make sure pairs filt contains also pairs with score < 0 if selected 
  (filtered by default for cumulative couplings)
  TODO: cache any computations for improved performance (if necessary)
  TODO: add filters
  TODO: add coupling to 3D structure viewer
  TODO: use unfiltered list as input to CouplingContainer
  TODO: error handling if EC or structure data is missing
*/
export const EvolutionaryCouplingsPanel = ({
  jobGroup,
  job,
  couplingsData,
  pairsFilt,
  cumulativeCouplings,
  targetSequence,
  refetchCouplings,
  contactDistanceThreshold,
  mergedDistanceMap,
  contactsFromDistanceMap,
  // monomerContacts, // TODO: move down into here
  // multimerContacts, // TODO: move down into here
  // secondaryStructure,
  contactSettings,
  setContactSettings,
  experimentalStructureData,
  predictedStructureData,
  mergedSecondaryStructure,
  showContactMap,
  showViewer,
  showTable,
  showNetwork,
  structures,
  availableStructures,
  dispatchStructureSelection,
  hasExperimentalStructures,
  hasPredictedStructures,
  refetchFailedStructures,
  isLoading,
  jobData,
}) => {
  // check if any data for contact map / EC table failed during loading
  const hasLoadingError =
    (couplingsData && couplingsData.error) ||
    (experimentalStructureData && experimentalStructureData.error) ||
    (predictedStructureData && predictedStructureData.error);

  // helper function to trigger all necessary refetches (contact map, pair table)
  const triggerRefetch = () => {
    if (couplingsData && couplingsData.error && couplingsData.refetch) {
      // console.log("### ... refetch couplings"); // TODO: remove
      couplingsData.refetch();
    }

    if (
      experimentalStructureData &&
      experimentalStructureData.error &&
      experimentalStructureData.refetch
    ) {
      // console.log("### ... refetch experimental data"); // TODO: remove
      experimentalStructureData.refetch();
    }

    if (
      predictedStructureData &&
      predictedStructureData.error &&
      predictedStructureData.refetch
    ) {
      // console.log("### ... refetch predicted data"); // TODO: remove
      predictedStructureData.refetch();
    }
  };

  // use webGL for contact map rendering?
  const useWebGL = true;

  // selected positions / edges
  const [selection, dispatchSelection] = useReducer(
    selectionReducer,
    createEmptySelection()
  );

  // if secondary structure type changes, reset selection or otherwise old selection
  // will be rendered on top of new secondary structure (can look out of sync);
  // note that condition to execute purposefully excludes selection
  useEffect(() => {
    // only dispatch if something is selected
    if (
      selection.secondaryStructures &&
      selection.secondaryStructures.count() > 0
    ) {
      dispatchSelection({ action: "resetSecondaryStructure" });
    }
  }, [contactSettings.shownSecondaryStructure]);

  // Dispatch reset of selections if job/subjob changes
  // Note that exclusion of selection from useEffect condition is on purpose
  useEffect(() => {
    dispatchSelection({ action: "reset" });
  }, [jobGroup, job]);

  // control button settings
  const [enableZooming, setEnableZooming] = useState(false);
  const [showMultimerContacts, setShowMultimerContacts] = useState(true);
  const [showStructureCoverage, setShowStructureCoverage] = useState(true);
  const [showTruePositiveContacts, setShowTruePositiveContacts] = useState(
    false
  );
  const [scaleEcSize, setScaleEcSize] = useState(
    SCALE_COUPLINGS_POINT_SIZE.PROBABILITY
  );

  // since we cannot get direct access to resize method, just trigger
  // resize event artificially
  const [debouncedResizeWindowCallback] = useDebouncedCallback(() => {
    // console.log("trigger resize");
    window.dispatchEvent(new Event("resize"));
  }, WINDOW_RESIZE_DEBOUNCE);

  // create color schemes for structure viewer, depending on user selection
  const [structureColorScheme, setStructureColorScheme] = useState(
    COLOR_MAP_PER_CLASS
  );

  const colorSchemes = useMemo(() => {
    return createSequenceColorMapperPerClass(
      targetSequence,
      structureColorScheme
    );
  }, [targetSequence, structureColorScheme]);

  // create map from individual structure to color scheme
  const colorSchemesPerModel = getColorSchemePerModel(structures, colorSchemes);

  /*
    Derive contacts for contact map plot
  */
  let monomerContacts = null;
  let multimerContacts = null;
  // override for monomer style (if null, will use default in contact map)
  // TODO: would be much better style to define everything on the same level
  let monomerStyle = null;

  // structure coverage information
  let structureCoverage = null;

  if (contactSettings.distanceMapMode === DISTANCE_MAP_MODE.SYNC_WITH_VIEWER) {
    monomerContacts = contactsFromDistanceMap;
    multimerContacts = null;

    // synchronized structure - use "green lawn"
    monomerStyle = {
      color: "#D4D472",
      size: 10,
    };

    structureCoverage = mergedDistanceMap
      ? mergedDistanceMap.structureCoverage
      : null;
    // console.log("+++ COVERAGE IN BROWSER", JSON.stringify(structureCoverage));  // TODO: remove
  } else {
    if (contactSettings.distanceMapMode !== DISTANCE_MAP_MODE.NO_STRUCTURE) {
      monomerContacts = experimentalStructureData
        ? experimentalStructureData.monomerContacts
        : null;
      multimerContacts = experimentalStructureData
        ? experimentalStructureData.multimerContacts
        : null;

      structureCoverage =
        experimentalStructureData &&
        experimentalStructureData.data &&
        experimentalStructureData.data.meta &&
        experimentalStructureData.data.meta.structure_coverage &&
        // only if actually defined (may not be the case for older runs,
        // any structure should have some coverage)
        experimentalStructureData.data.meta.structure_coverage.monomer &&
        experimentalStructureData.data.meta.structure_coverage.monomer.length >
          0
          ? experimentalStructureData.data.meta.structure_coverage.monomer
          : null;
      // console.log("+++ COVERAGE", structureCoverage); // TODO: remove
    }
  }

  /*
    Extract secondary structure for rendering
  */
  let secondaryStructure;

  switch (contactSettings.shownSecondaryStructure) {
    case SECONDARY_STRUCTURE_MODE.EXPERIMENTAL:
      // if we have custom structure selection use it, otherwise default to all structures
      secondaryStructure = experimentalStructureData
        ? experimentalStructureData.secondaryStructure
        : null;
      break;
    case SECONDARY_STRUCTURE_MODE.PREDICTED:
      secondaryStructure = predictedStructureData
        ? predictedStructureData.secondaryStructure
        : null;
      break;
    case SECONDARY_STRUCTURE_MODE.SYNC_WITH_VIEWER:
      secondaryStructure = mergedSecondaryStructure;
      break;
    default:
      throw Error("Invalid selection");
  }

  /*
    Dynamically recompute distances for EC table
  */

  // select whatever is currently relevant distance information
  const selectedDistanceInfo =
    contactSettings.distanceMapMode === DISTANCE_MAP_MODE.SYNC_WITH_VIEWER
      ? mergedDistanceMap
      : experimentalStructureData;

  const pairsFiltWithDist = useMemo(() => {
    // if no structure shown, do not add distances (and remove if present by default)
    if (contactSettings.distanceMapMode === DISTANCE_MAP_MODE.NO_STRUCTURE) {
      let pairsFiltNoDist = pairsFilt;
      if (!pairsFiltNoDist) {
        return pairsFiltNoDist;
      }
      if (pairsFiltNoDist.hasSeries("dist")) {
        pairsFiltNoDist = pairsFiltNoDist.dropSeries("dist");
      }
      if (pairsFiltNoDist.hasSeries("precision")) {
        pairsFiltNoDist = pairsFiltNoDist.dropSeries("precision");
      }
      return pairsFiltNoDist;
    }

    return addDistances(
      pairsFilt,
      selectedDistanceInfo,
      contactSettings.distanceMapMode === DISTANCE_MAP_MODE.SYNC_WITH_VIEWER,
      showMultimerContacts
    );
  }, [
    pairsFilt,
    contactSettings.distanceMapMode,
    selectedDistanceInfo,
    showMultimerContacts,
  ]);

  // console.log("yyy", JSON.stringify(mergedDistanceMap));

  const pairsFiltWithPrec = useMemo(() => {
    return computePrecision(pairsFiltWithDist, contactDistanceThreshold);
  }, [pairsFiltWithDist, contactDistanceThreshold]);

  const renderCountTag = () =>
    pairsFiltWithDist ? (
      <Tag minimal={true} intent={Intent.NONE} style={{ margin: "6px" }}>
        {pairsFiltWithDist.count() + " ECs"}
      </Tag>
    ) : null;

  const renderContactMap = () => {
    let cmContent;

    // extract format for viewer from input secondary structure dataframe
    let secondaryStructureElements;
    if (secondaryStructure) {
      secondaryStructureElements = extractSecondaryStructureSegments(
        secondaryStructure,
        DEFAULT_SEGMENT_ID
      ).secondaryStructureElements;
    }

    const nothingToShow =
      !isLoading &&
      pairsFiltWithDist &&
      pairsFiltWithDist.count() === 0 &&
      (!monomerContacts || monomerContacts.count() === 0);

    let cm;
    if (nothingToShow) {
      cm = NO_DATA_WARNING;
    } else {
      cm = (
        <ResizeSensor onResize={() => debouncedResizeWindowCallback()}>
          <ContactMap
            useWebGL={useWebGL}
            plotDivId={CONTACTMAP_DIV_ID}
            // couplings={pairsFilt}
            couplings={pairsFiltWithDist}
            monomerContacts={monomerContacts}
            multimerContacts={showMultimerContacts ? multimerContacts : null}
            structureCoverage={showStructureCoverage ? structureCoverage : null}
            monomerStyle={monomerStyle}
            secondaryStructure={secondaryStructureElements}
            selectedSecondaryStructure={selection.secondaryStructures}
            selectedPairs={selection.pairs}
            targetSequence={targetSequence}
            disableZooming={!enableZooming}
            symmetric={true}
            scaleCouplings={scaleEcSize}
            highlightTruePositives={showTruePositiveContacts}
            boundaries={"union"}
            contactSettings={contactSettings}
            alignmentCoverage={
              jobData
                ? jobData.result_summary.alignment_coverage_segments
                : null
            }
            handleSecondaryStructureClick={(
              pos,
              elementIdx,
              element,
              modifiers
            ) =>
              dispatchSelection({
                action: "toggleSecondaryStructure",
                element: element,
                elementIndex: elementIdx,
                multiSelect: modifiers.shift || modifiers.meta,
              })
            }
            handlePairClick={(
              i,
              j,
              isCouplingsPair,
              isMonomerContactPair,
              isMultimerContactPair,
              modifiers
            ) =>
              dispatchSelection({
                action: "togglePair",
                // convention: for symmetric pairs, always set i < j so reducer
                // does not need to treat two cases (symmetric and unsymmetric)
                i: Math.min(i, j),
                j: Math.max(i, j),
                segment_i: DEFAULT_SEGMENT_ID,
                segment_j: DEFAULT_SEGMENT_ID,
                multiSelect: modifiers.shift || modifiers.meta,
              })
            }
          />
        </ResizeSensor>
      );
    }

    // rescaling logic based on http://www.dwuser.com/education/content/creating-responsive-tiled-layout-with-pure-css/
    cmContent = (
      <div
        style={{
          width: "95%",
          height: 0,
          paddingBottom: "95%",
          // backgroundColor: "#aaaabb",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            // backgroundColor: "#aa0000",
            position: "absolute",
          }}
        >
          {cm}
        </div>
      </div>
    );

    /* console.log("### TABLES", pairsFiltWithDist ? pairsFiltWithDist.count() : "---", monomerContacts? monomerContacts.count() : "---");
    if ((pairsFiltWithDist && pairsFiltWithDist.count() === 0) && (!monomerContacts || monomerContacts.count() === 0)) {
      cmContent = <div>Empty</div>;
    }*/

    const imageSaveWrapper = (format, scale) =>
      saveImage(CONTACTMAP_DIV_ID, {
        // https://plot.ly/javascript/configuration-options/#customize-download-plot-options
        format: format, // TODO: allow user to select
        width: 800,
        height: 800,
        scale: scale, // Multiply title/legend/axis/canvas sizes by this factor
        filename: `EVcouplings_3Dstructure_${jobGroup}_${job}_contactmap`,
      });

    const renderImageDownload = () => {
      if (useWebGL) {
        return (
          <Button
            icon="import"
            title="Export image"
            onClick={() => imageSaveWrapper("png", 2)}
          ></Button>
        );
      } else {
        return (
          <Popover
            content={
              <Menu>
                <MenuItem
                  text="svg format (vector)"
                  onClick={() => imageSaveWrapper("svg", 1)}
                />
                <MenuItem
                  text="png format (bitmap)"
                  // use scale=2 for png to increate bitmap dpi
                  onClick={() => imageSaveWrapper("png", 2)}
                />
              </Menu>
            }
            position={Position.TOP}
          >
            <Button icon="import" title="Save image..." />
          </Popover>
        );
      }
    };

    const renderScalingSettings = () => {
      return (
        <Popover
          content={
            <Menu>
              <MenuItem
                text="Scale by probability"
                icon={
                  scaleEcSize === SCALE_COUPLINGS_POINT_SIZE.PROBABILITY
                    ? "tick"
                    : "blank"
                }
                onClick={() =>
                  setScaleEcSize(SCALE_COUPLINGS_POINT_SIZE.PROBABILITY)
                }
              />
              <MenuItem
                text="Scale by score"
                icon={
                  scaleEcSize === SCALE_COUPLINGS_POINT_SIZE.SCORE
                    ? "tick"
                    : "blank"
                }
                onClick={() => setScaleEcSize(SCALE_COUPLINGS_POINT_SIZE.SCORE)}
              />
              <MenuItem
                text="No scaling"
                icon={
                  scaleEcSize === SCALE_COUPLINGS_POINT_SIZE.NONE
                    ? "tick"
                    : "blank"
                }
                onClick={() => setScaleEcSize(SCALE_COUPLINGS_POINT_SIZE.NONE)}
              />
            </Menu>
          }
          position={Position.TOP}
        >
          <Button
            icon="heatmap"
            title="Change EC point scaling settings"
            active={scaleEcSize !== SCALE_COUPLINGS_POINT_SIZE.NONE}
          />
        </Popover>
      );
    };

    /*
      TODO: should link colors to actual styles in ContactMap.js (means styles have
        to be moved outside of component)
    */
    const renderLegend = () => {
      const colWidth = "60px";
      const legendContent = (
        <>
          <h6 className="bp3-heading">Evolutionary couplings</h6>
          <table
            className="bp3-html-table bp3-html-table-condensed"
            style={{
              verticalAlign: "top",
              width: "100%",
              tableLayout: "fixed",
              marginBottom: "1em",
            }}
          >
            <tbody>
              <tr>
                <td style={{ width: colWidth, verticalAlign: "middle" }}>
                  {makeCircle("10px", "black")}
                </td>
                <td>
                  Evolutionarily coupled residue pair (EC, use{" "}
                  <Icon icon="cog" /> to adjust number)
                </td>
              </tr>
              <tr>
                <td style={{ width: colWidth, verticalAlign: "middle" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "flex-end",
                    }}
                  >
                    {makeCircle("5px", "black", { margin: "1px" })}{" "}
                    {makeCircle("8px", "black", { margin: "1px" })}{" "}
                    {makeCircle("15px", "black", { margin: "1px" })}
                  </div>
                </td>
                <td>
                  Probability/strength of evolutionary coupling (use{" "}
                  <Icon icon="heatmap" /> to change display)
                </td>
              </tr>
              <tr>
                <td style={{ width: colWidth, verticalAlign: "middle" }}>
                  {makeCircle("10px", "red")}
                </td>
                <td>
                  EC pair is in contact in 3D <br />
                  (use <Icon icon="resolve" /> to toggle display)
                </td>
              </tr>
              <tr>
                <td style={{ width: colWidth, verticalAlign: "middle" }}>
                  <div
                    style={{
                      width: "90%",
                      height: "7px",
                      backgroundColor: "#525F69",
                    }}
                  >
                    &nbsp;
                  </div>
                </td>
                <td>Residue segments with alignment/EC coverage</td>
              </tr>
            </tbody>
          </table>

          <h6 className="bp3-heading">3D structure contacts</h6>
          <table
            className="bp3-html-table bp3-html-table-condensed"
            style={{
              verticalAlign: "top",
              width: "100%",
              tableLayout: "fixed",
            }}
          >
            <tbody>
              <tr>
                <td style={{ width: colWidth, verticalAlign: "middle" }}>
                  {makeCircle("10px", "#b6d4e9")}
                </td>
                <td>
                  Residue pair is monomer contact in experimental structure
                  (distance threshold: {contactDistanceThreshold.toFixed(1)}
                  &#8491;, use <Icon icon="cog" /> to change)
                </td>
              </tr>
              <tr>
                <td style={{ width: colWidth, verticalAlign: "middle" }}>
                  {makeCircle("10px", "rgba(181, 151, 123, 1)")}
                </td>
                <td>
                  Residue pair is multimer contact in experimental structure
                  (use <Icon icon="grid-view" /> to toggle display)
                </td>
              </tr>
              <tr>
                <td style={{ width: colWidth, verticalAlign: "middle" }}>
                  {makeCircle("10px", "#D4D472")}
                </td>
                <td>
                  Residue pair is contact in structure shown in 3D viewer (use{" "}
                  <Icon icon="database" /> to select)
                </td>
              </tr>
              <tr>
                <td style={{ width: colWidth, verticalAlign: "middle" }}>
                  <div
                    style={{
                      width: "90%",
                      height: "70%",
                      backgroundColor: "#eeeeee", // actual color: "#efefef" (use slightly darker color to improve contrast here)
                    }}
                  >
                    &nbsp;
                  </div>
                </td>
                <td>
                  Area of contact map lacks 3D structure coverage (use{" "}
                  <Icon icon="grid" /> to toggle display)
                </td>
              </tr>
            </tbody>
          </table>
        </>
      );

      return <Legend content={legendContent} />;
    };

    let panelContent;

    if (hasLoadingError) {
      panelContent = <ErrorRefetcher refetcher={() => triggerRefetch()} />;
    } else {
      panelContent = (
        <>
          <div
            /* This centers contact map vertically on page */
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {cmContent}
          </div>
          {/*
          Add the following options:
          * show multimer or not: icon=grid-view
          * scale by probability or score (by multiple click on score button?)
          * image download
          /*/}
          <ButtonGroup minimal={true}>
            <Button
              title="Show homo-multimeric interactions"
              icon="grid-view"
              active={showMultimerContacts}
              onClick={() => setShowMultimerContacts(!showMultimerContacts)}
            />
            <Button
              title="Show structure coverage"
              icon="grid"
              active={showStructureCoverage}
              onClick={() => setShowStructureCoverage(!showStructureCoverage)}
            />
            {renderScalingSettings()}
            {/* <Button
            title={
              scaleEcSize === SCALE_COUPLINGS_POINT_SIZE.NONE
                ? "Do not scale by score/probability (toggle to switch)"
                : scaleEcSize === SCALE_COUPLINGS_POINT_SIZE.PROBABILITY
                ? "Scale EC size by probability"
                : "Scale EC size by score"
            }
            icon="heatmap"
            active={scaleEcSize > SCALE_COUPLINGS_POINT_SIZE.NONE}
            onClick={() =>
              setScaleEcSize(
                scaleEcSize < SCALE_COUPLINGS_POINT_SIZE.SCORE
                  ? scaleEcSize + 1
                  : SCALE_COUPLINGS_POINT_SIZE.NONE
              )
            }
          /> */}
            <Button
              title="Highlight ECs in contact in 3D"
              // icon="intersection"
              icon="resolve"
              active={showTruePositiveContacts}
              onClick={() =>
                setShowTruePositiveContacts(!showTruePositiveContacts)
              }
            />
            <Button
              title="Enable zooming/panning"
              icon="search"
              active={enableZooming}
              onClick={() => setEnableZooming(!enableZooming)}
            />
            <Button
              title="Clear selection"
              icon={RESET_SELECTION_ICON}
              onClick={() => dispatchSelection({ action: "reset" })}
            />
            <Divider />
            <ContactSelectionMenu
              hasExperimentalStructures={hasExperimentalStructures}
              hasPredictedStructures={hasPredictedStructures}
              availableStructures={availableStructures}
              // dispatchStructureSelection={dispatchStructureSelection}
              contactSettings={contactSettings}
              setContactSettings={setContactSettings}
              // TODO: set shown structures to structures selected for contact display
              shownStructures={structures}
              target={
                <Button title="Select displayed structures" icon="database">
                  Structures
                </Button>
              }
            />
            <Divider />
            {renderImageDownload()}
            <Divider />
            {renderLegend()}
            {renderCountTag()}
          </ButtonGroup>
        </>
      );
    }

    // create outer panel and return;
    return createPanel(
      panelContent,
      {
        width: "58vh",
        minWidth: "550px", // give some extra width due to large icon bar underneath
        maxWidth: "78vh",
      },
      isLoading
    );
  };

  // TODO: refactor for less redundancy with StronglyCoupledSites.js
  const renderNetwork = () => {
    // TODO: make less redundant with strongly coupled sites table
    let networkContent;
    let showingNetwork = false;

    if (pairsFilt && cumulativeCouplings) {
      // only render network if we have nodes
      if (cumulativeCouplings.count() > 0) {
        networkContent = (
          <CouplingsNetwork
            pairsFilt={pairsFilt}
            // TODO: update this to allow pairs with score < 0
            // pairsFilt={pairsFilt.where(row => row.score >= 0)}
            cumulativeCouplings={cumulativeCouplings}
            selection={selection}
            // new-style dispatch
            dispatchSelection={dispatchSelection}
            dispatchReset={() => dispatchSelection({ action: "reset" })}
            exportFilename={`EVcouplings_network_${jobGroup}_${job}.png`}
            showSegments={false}
          />
        );
        showingNetwork = true;
      } else {
        // nothing left after filtering
        networkContent = NO_SITES_WARNING;
      }
    } else {
      if (couplingsData.error) {
        networkContent = <ErrorRefetcher refetcher={refetchCouplings} />;
      } else {
        // networkContent = <div style={{position: "absolute", top: "50%", left: "50%"}}><Spinner /></div>;
        // networkContent = <div><Spinner /></div>;
        networkContent = <Spinner />;
      }
    }

    // TODO: refactor to use createPanel()
    return (
      <div
        style={{
          display: "flex",
          // flexGrow: "1",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: showingNetwork ? "space-between" : "center",
          // width: "30%",
          width: "35vw",
          minWidth: "500px",
          height: "78vh",
          // height: "100%",
          minHeight: "500px",
          resize: "horizontal",
          borderWidth: "1pt",
          borderStyle: "solid",
          borderColor: "#efefef",
          overflow: "auto",
          marginRight: "1em",
          marginBottom: "1em",
          // avoid spurious scrollbars
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        {networkContent}
      </div>
    );
  };

  const renderCouplingsTable = (pairsFilt) => {
    const alignRight = (text) => (
      <div style={{ textAlign: "right" }}>{text}</div>
    );

    // create map from pair to index in data table
    const allPairsToIndex = pairsFilt
      ? Map(
          pairsFilt.toArray().map((r, i) => [
            Map({
              segment_i: r.segment_i,
              segment_j: r.segment_j,
              i: r.i,
              j: r.j,
            }),
            i,
          ])
        )
      : Map();

    // reverse mapping
    const indexToPair = allPairsToIndex.flip();

    // missing entries would return "undefined" in get, so filter
    // them out after map
    const selectedRows =
      selection && selection.pairs
        ? selection.pairs
            .map((pair) => allPairsToIndex.get(pair))
            .toList()
            .filter((x) => x !== undefined)
            .toJS()
        : [];

    const columns = [
      {
        heading: "i",
        headingStyle: { textAlign: "right" },
        width: 50,
        renderer: (r, i) => r.i,
        style: { textAlign: "right" },
        legend: "Sequence index of first position",
      },
      {
        heading: (
          <>
            A<sub>i</sub>
          </>
        ),
        width: 33,
        renderer: (r, i) => r.A_i,
        legend: "Amino acid at first position",
      },
      {
        heading: "j",
        headingStyle: { textAlign: "right" },
        width: 50,
        renderer: (r, i) => r.j,
        style: { textAlign: "right" },
        legend: "Sequence index of second position",
      },
      {
        heading: (
          <>
            A<sub>j</sub>
          </>
        ),
        width: 33,
        renderer: (r, i) => r.A_j,
        legend: "Amino acid at second position",
      },
      {
        heading: "Score",
        headingStyle: { textAlign: "right" },
        width: 60,
        renderer: (r, i) => r.score.toFixed(2),
        style: { textAlign: "right" },
        legend: "Coupling score between positions i and j",
      },
      {
        heading: "Prob",
        headingStyle: { textAlign: "right" },
        width: 55,
        renderer: (r, i) => r.probability.toFixed(2),
        style: { textAlign: "right" },
        legend: "Probability of positions i and j being coupled",
      },

      // TODO: Distance, Precision
    ];

    if (pairsFilt && pairsFilt.hasSeries("dist")) {
      columns.push({
        heading: "Dist",
        headingStyle: { textAlign: "right" },
        width: 60,
        renderer: (r, i) => (r.dist ? r.dist.toFixed(2) : "-"),
        style: { textAlign: "right" },
        legend:
          "Minimum atom distance (closest pair of atoms, in ångström [Å]) in 3D structure(s) between positions i and j, minimum value if multiple structures selected",
      });
    }

    if (pairsFilt && pairsFilt.hasSeries("precision")) {
      columns.push({
        heading: "PPV",
        headingStyle: { textAlign: "right" },
        width: 45,
        renderer: (r, i) =>
          Number.isFinite(r.precision) ? r.precision.toFixed(2) : "-",
        style: { textAlign: "right" },
        legend:
          "Precision / positive predictive value of all ECs up to the current one being in contact in 3D at selected distance threshold",
      });
    }

    // compute total width of table based on columns
    const totalWidth = columns.map((col) => col.width).reduce((a, b) => a + b);
    const widthStr = totalWidth + 50 + "px";

    let tableContent;
    if (hasLoadingError) {
      tableContent = <ErrorRefetcher refetcher={() => triggerRefetch()} />;
    } else {
      tableContent = (
        <DataTable
          data={pairsFilt}
          columns={columns}
          exportFilename={`EVcouplings_ECs_${jobGroup}_${job}.csv`}
          selectedRows={selectedRows}
          onSelection={(newRows) => {
            // console.log("ON SELECTION", JSON.stringify(newRows.map(row => indexToPair.get(row))))
            dispatchSelection({
              action: "setAll",
              pairs: newRows.map((row) => indexToPair.get(row)),
            });
          }}
          dispatchReset={() => dispatchSelection({ action: "reset" })}
          enableRowHeader={true}
          customAnnotations={renderCountTag()}
        />
      );
    }
    return createPanel(
      tableContent,
      {
        minWidth: widthStr,
        maxWidth: widthStr,
        resize: "none",
      },
      isLoading
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      {showContactMap ? renderContactMap() : null}
      {showNetwork ? renderNetwork() : null}
      {showTable ? renderCouplingsTable(pairsFiltWithPrec) : null}
      {showViewer ? (
        <StructureViewer
          structures={structures}
          // structures={List()}
          availableStructures={availableStructures}
          dispatchStructureSelection={dispatchStructureSelection}
          selection={selection}
          dispatchSelection={(residues) => {
            // currently only returns single residue upon selec
            dispatchSelection({
              action: "toggleSite",
              i: residues[0].i,
              segment_i: residues[0].segment_i,
              pairTable: pairsFilt, // if this is defined, will also add pairs containing site upon selection
              multiSelect: true,
            });
          }}
          dispatchReset={() => dispatchSelection({ action: "reset" })}
          imageExportFileName={`EVcouplings_3Dstructure_${jobGroup}_${job}_couplings.png`}
          colorScheme={colorSchemesPerModel}
          refetchFailedStructures={refetchFailedStructures}
          colorSchemeSelection={
            <OptionMenu
              selection={structureColorScheme}
              setSelection={(value) => setStructureColorScheme(value)}
              options={[
                {
                  text:
                    "Gradient with multiple colors (similar for experimental/predicted)",
                  value: COLOR_MAP_PER_CLASS,
                },
                {
                  text: "Different monochrome gradients for experimental/predicted",
                  value: COLOR_MAP_PER_CLASS_SINGLE_HUE,
                },
              ]}
              child={
                <Button title="Select structure color scheme" icon="tint" />
              }
            />
          }
        />
      ) : null}
    </div>
  );
};

export default EvolutionaryCouplingsPanel;
