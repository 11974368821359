import React from "react";
import { Spinner } from "@blueprintjs/core";
/*
    Resizable div with border around result components

    Different settings for different components:
    
    * 3D viewer:
      - disable marginRight
      - disable justifyContent?
      - textAlign: "center"
      - width: "35vw"
    * contact map:
       - width: "75vh",
       - maxWidth: "75vh", // avoid overstretching of contact map
    * table (sites):
        - minWidth: "320px",
        - maxWidth: "320px",


    Overlay for spinner: https://stackoverflow.com/questions/50190632/div-overlay-other-divs-in-a-flex-container

*/
export const createPanel = (child, styleOverride, isLoading) => {
  // display laoding indicator
  const loader = isLoading ? (
    <div
      style={{
        display: "flex",
        position: "absolute",
        zIndex: 2,
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        pointerEvents: "none",
        height: "78vh",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          padding: "2em",
          flexGrow: 1
        }}
      >
        <Spinner />
      </div>
    </div>
  ) : null;

  return (
    <div
      style={{
        // flexbox settings
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

        // div resizing
        overflow: "auto",
        resize: "horizontal",

        // border
        borderWidth: "1pt",
        borderStyle: "solid",
        borderColor: "#efefef",

        // margins
        marginRight: "1em",
        marginBottom: "1em",

        // size
        height: "78vh",
        width: "35vw",
        minHeight: "500px",
        minWidth: "500px",

        // disable scroll bars in Safari
        overflowX: "hidden",
        overflowY: "hidden",
        position: "relative",

        ...styleOverride
      }}
    >
      {loader}
      {child}
    </div>
  );
};

export default createPanel;
