import React, { useState } from "react";
import { Map } from "immutable";
import PlotlyWrapper from "../external/Plotly";
import {
  SecondaryStructureAxis,
  AuxiliaryAxis,
  Bioblocks1DSection,
  ColorMapper
} from "bioblocks-viz";

import { SECONDARY_STRUCTURE_MODE } from "./../../utils/Structures";

// number of decimal places for score, probabilities and distances in hover labels
const NUM_DECIMAL_PLACES = 2;
const NUM_DECIMAL_PLACES_DIST = 1;

// options for scaling of couplings by score/probability
export const SCALE_COUPLINGS_POINT_SIZE = {
  NONE: 0,
  PROBABILITY: 1,
  SCORE: 2
};

export const SECSTRUCT_LABEL_MAP = {
  H: "Helix",
  E: "Strand",
  C: "Coil"
};

// https://github.com/plotly/react-plotly.js/blob/master/README.md#customizing-the-plotlyjs-bundle
// https://github.com/plotly/plotly.js/blob/master/dist/README.md#plotlyjs-gl2d
// customizable method: use your own `Plotly` object
// import "./Plotly.css"; // hide panels / change default styles of 3D viewer
// import Plotly from "plotly.js-gl2d-dist";
// import createPlotlyComponent from "react-plotly.js/factory";
// const Plot = createPlotlyComponent(Plotly);

/*
function revisionReducer(state, action) {
  switch (action.type) {
    case "increment":
      return { revision: state.revision + 1 };
    default:
      throw new Error();
  }
}
*/

/*
  Create list of secondary structure segments from per-position mapping dictionary
*/
export const extractSecondaryStructureSegments = (
  secondaryStructure,
  segment
) => {
  let lastElement = null;
  let secondaryStructureElements = [];
  let posToSegmentIndex = {};

  secondaryStructure.toArray().forEach((row, i) => {
    const curElement = row.sec_struct_3state;
    const curPos = row.i;
    // check if we continue the same type of element or need
    // to create a new element
    if (curElement !== lastElement) {
      secondaryStructureElements.push(
        // new Bioblocks1DSection(curElement, curPos, curPos)
        Map({
          type: curElement,
          start: curPos,
          end: curPos,
          segment: segment
        })
      );
    } else {
      // extend current element
      secondaryStructureElements[
        secondaryStructureElements.length - 1
      ] = secondaryStructureElements[
        secondaryStructureElements.length - 1
      ].updateIn(["end"], value => value + 1);
    }
    lastElement = curElement;

    posToSegmentIndex[curPos] = secondaryStructureElements.length - 1;
  });

  return {
    secondaryStructureElements: secondaryStructureElements,
    posToElementIndex: posToSegmentIndex
  };
};

/*
  Settings for additional secondary structure axis via deriveAxisParams:
  // const derivedLayout = new PlotlyChart().deriveAxisParams(plotlyData);
  // console.log("DERIVED", derivedLayout);

  {
  {
  "xaxis2": {
    "domain": [
      0.95,
      1
    ],
    "fixedrange": true,
    "visible": false
  },
  "yaxis2": {
    "domain": [
      0.95,
      1
    ],
    "fixedrange": true,
    "visible": false
  },
  "xaxis": {
    "domain": [
      0,
      0.9
    ],
    "zeroline": false
  },
  "yaxis": {
    "domain": [
      0,
      0.9
    ],
    "zeroline": false
  }
}
*/

/*
  Determine boundaries of contact map plot. If boundaries not specified, returns "union".
  Currently does not offer manual boundary override

  Cf. EVcouplings Python implementation:
  https://github.com/debbiemarkslab/EVcouplings/blob/develop/evcouplings/visualize/pairs.py#L706
*/
export const findBoundaries = (
  boundaries,
  couplings,
  monomerContacts,
  multimerContacts,
  symmetric
) => {
  // helper function to find position limits on axis
  const findPos = axis => {
    let couplingsPos = new Set([]);
    let monomerPos = new Set([]);
    let multimerPos = new Set([]);

    if (couplings && couplings.count() > 0) {
      if (symmetric) {
        couplingsPos = new Set(
          couplings
            .getSeries("i")
            .concat(couplings.getSeries("j"))
            .distinct()
            .toArray()
        );
      } else {
        couplingsPos = new Set(
          couplings
            .getSeries(axis)
            .distinct()
            .toArray()
        );
      }
    }

    // experimental ontacts have (i, j) and (j, i) in symmetric case so no
    // need to handle this case explicitly
    if (monomerContacts) {
      monomerPos = new Set(monomerContacts.getSeries(axis).toArray());
    }

    if (multimerContacts) {
      multimerPos = new Set(multimerContacts.getSeries(axis).toArray());
    }

    // compute union of monomer and multimer contacts to define
    // full set of structure positions
    // /seriously no set logic functions with Sets in JS in 2019?!)
    const structurePos = new Set([...monomerPos, ...multimerPos]);

    // maximum ranges spanned by structure or ECs
    // if any of the sets is not given, revert to
    // the other set of positions in else case
    // (in these cases, union and intersection will
    // be trivially the one set that is actually defined)
    let minCouplings;
    let maxCouplings;
    let minStructure;
    let maxStructure;

    if (couplingsPos.size > 0) {
      minCouplings = Math.min(...couplingsPos);
      maxCouplings = Math.max(...couplingsPos);
    } else {
      minCouplings = Math.min(...structurePos);
      maxCouplings = Math.max(...structurePos);
    }

    if (structurePos.size > 0) {
      minStructure = Math.min(...structurePos);
      maxStructure = Math.max(...structurePos);
    } else {
      minStructure = Math.min(...couplingsPos);
      maxStructure = Math.max(...couplingsPos);
    }

    let minVal;
    let maxVal;

    switch (boundaries) {
      case "union":
        minVal = Math.min(minCouplings, minStructure);
        maxVal = Math.max(maxCouplings, maxStructure);
        break;
      case "intersection":
        minVal = Math.max(minCouplings, minStructure);
        maxVal = Math.min(maxCouplings, maxStructure);
        break;
      case "ecs":
        minVal = minCouplings;
        maxVal = maxCouplings;
        break;
      case "structure":
        minVal = minStructure;
        maxVal = maxStructure;
        break;
      default:
        // return union
        minVal = Math.min(minCouplings, minStructure);
        maxVal = Math.max(maxCouplings, maxStructure);
        break;
    }

    return [minVal, maxVal];
  };

  // TODO: implement manual boundary override here?
  const [minX, maxX] = findPos("i");
  const [minY, maxY] = findPos("i");

  return {
    xMin: minX,
    xMax: maxX,
    yMin: minY,
    yMax: maxY
  };
};

/*
  Encode site pair as string
*/
const createPairId = (source, target) => source + "___" + target;

/*
  Create pair set for quick lookups
*/
export const createPairMap = (pairTable, symmetric, isCouplingsTable) => {
  let pairs = Map();

  if (!pairTable) {
    return pairs;
  }

  const pairTableArray = pairTable.toArray();

  const getMapEntries = (firstIndex, secondIndex) => {
    return pairTableArray.map((r, i) => {
      let mapValue;

      if (isCouplingsTable) {
        mapValue = {
          score: r.score,
          probability: r.probability,
          dist: r.dist // may or may not be defined
        };
      } else {
        mapValue = {
          dist: r.dist
        };
      }

      // create key-value-list for map creation
      return [createPairId(r[firstIndex], r[secondIndex]), mapValue];
    });
  };

  pairs = pairs.merge(getMapEntries("i", "j"));

  if (symmetric) {
    pairs = pairs.merge(getMapEntries("j", "i"));
  }

  return pairs;
};

/*
    TODO: implement state management (https://github.com/plotly/react-plotly.js#state-management)
    TODO: triggering selection events... only ECs or also structure?   
    TODO: eventually, segment handling

    Examples:
    - many traces: https://plot.ly/javascript/webgl-vs-svg/
    - https://plot.ly/javascript/configuration-options/#scroll-and-zoom
    - https://plot.ly/python/hover-text-and-formatting/
    - https://plot.ly/javascript/hover-text-and-formatting/
    - https://community.plot.ly/t/disable-double-click-unzoom/2630


    Multiple axis:
    - https://plot.ly/python/multiple-axes/

    State management:
    - ultimately, if necessary use mutated state without update operation
    - https://github.com/plotly/react-plotly.js/issues/90
    - https://github.com/plotly/react-plotly.js/issues/139

    ----------
    Implementation todos: 
    - allow to set/toggle axis labels
    - allow to set logic for boundary determination, or fixed number range (x, y separately)

    ----------
    DONE: hide grid
    DONE: space around plot
    DONE: try event handling
    DONE: data display on hover
    DONE: disable help texts popping up
    DONE: try drawing secondary structure
    DONE: flip y axis?
*/
export const ContactMap = ({
  couplings,
  monomerContacts,
  multimerContacts,
  structureCoverage,
  secondaryStructure,
  targetSequence,
  boundaries,
  alignmentCoverage,
  couplingsStyle,
  monomerStyle,
  multimerStyle,
  structureCoverageStyle,
  secStructStyle,
  alignmentCoverageStyle,
  highlightStyle,
  scaleCouplings,
  highlightTruePositives,
  symmetric,
  axisLabel,
  disableZooming,
  useWebGL,
  selectedPairs,
  selectedSecondaryStructure,
  handlePairClick,
  handlePairHover,
  handlePairUnhover,
  handleSecondaryStructureClick,
  handleSecondaryStructureHover,
  handleSecondaryStructureUnhover,
  plotDivId,
  contactSettings
}) => {
  // store plot revision for updates
  const [uiRevision, setUiRevision] = useState(1);

  const scatterPlotType = useWebGL ? "scattergl" : "scatter";

  // console.log("CONTACT MAP RENDERS..."); // TODO: remove

  // make sure scale couplings is 0 if undefined or null (will keep 0 here)
  if (!scaleCouplings) {
    scaleCouplings = SCALE_COUPLINGS_POINT_SIZE.NONE;
  }

  /*
    Determine axis boundaries
  */
  const { xMin, xMax, yMin, yMax } = findBoundaries(
    boundaries,
    couplings,
    monomerContacts,
    multimerContacts,
    symmetric
  );

  /*
    Set pair styles unless defined; note that for transparency,
    use rgba color as expected by plotly (e.g. "rgba(255, 0, 0, 0.5)")
  */
  if (!couplingsStyle) {
    // TODO: change max size if true positive highlighting is on (could go
    // to same size as for structure contacts)?
    couplingsStyle = {
      color: "black",
      // colorTrue: "#f4511e",
      colorTrue: "red",
      size: 9
    };
  }

  if (!monomerStyle) {
    monomerStyle = {
      color: "#b6d4e9",
      size: 10
    };
  }

  if (!multimerStyle) {
    multimerStyle = {
      color: "rgba(181, 151, 123, 0.3)", // color: "#B58D7B",
      // color: "rgba(252,140,59, 0.3)", // original pipeline colors, equals "#fc8c3b"
      size: 10
    };
  }

  // highlighting of pairs / secondary structure
  if (!highlightStyle) {
    highlightStyle = {
      color: "orange",
      width: 5
    };
  }

  /*
    Set secondary structure style unless specified
  */
  if (!secStructStyle) {
    secStructStyle = {
      H: "black",
      E: "black",
      C: "black"
    };
  }

  if (!structureCoverageStyle) {
    structureCoverageStyle = {
      missing: "#efefef",
      covered: "#ffffff"
    };
  }

  if (!alignmentCoverageStyle) {
    alignmentCoverageStyle = {
      color: "#525F69",
      width: 10 // make large enough to cover entire domain of axis
    };
  }

  // color for selected/hovered secondary structure elements
  const secStructStyleHighlighted = {
    H: highlightStyle.color,
    E: highlightStyle.color,
    C: highlightStyle.color
  };

  /*
    Encode ECs/contacts for coloring/event handling lookups;
    contact tables contain (i, j) and (j, i) if symmetric so no
    need to handle explicitly
  */
  const couplingsMap = createPairMap(couplings, symmetric, true);
  const monomerContactsMap = createPairMap(monomerContacts, false, false);
  const multimerContactsMap = createPairMap(multimerContacts, false, false);

  // lookup table which pairs are selected
  let selectedPairsSet = new Set();
  if (selectedPairs) {
    selectedPairs.forEach((pair, index) => {
      selectedPairsSet.add(createPairId(pair.get("i"), pair.get("j")));
      if (symmetric) {
        selectedPairsSet.add(createPairId(pair.get("j"), pair.get("i")));
      }
      /*
      // old implementation with array of 2-element arrays
      selectedPairsSet.add(createPairId(pair[0], pair[1]));
      if (symmetric) {
        selectedPairsSet.add(createPairId(pair[1], pair[0]));
      }
      */
    });
  }

  // add shapes for structure coverage if given
  let coverageShapes = null;
  if (structureCoverage) {
    // draw background color first... unfortunately cannot use plot_bgcolor,
    // which will also change background color of secondary structure axis
    coverageShapes = [
      {
        type: "rect",
        xref: "x",
        yref: "y",
        x0: xMin,
        y0: yMin,
        x1: xMax,
        y1: yMax,
        fillcolor: structureCoverageStyle.missing,
        line: {
          width: 0
        },
        layer: "below"
      }
    ];

    structureCoverage.forEach(
      // iterate through all individual structures
      (covPerStructure, idx) => {
        // iterate through all pairs of segments
        covPerStructure.segments_i.forEach(segmentI => {
          covPerStructure.segments_j.forEach(segmentJ => {
            // create new coverage rectable
            coverageShapes.push({
              type: "rect",
              xref: "x",
              yref: "y",
              x0: segmentI.start,
              y0: segmentJ.start,
              x1: segmentI.end,
              y1: segmentJ.end,
              fillcolor: structureCoverageStyle.covered,
              line: {
                width: 0
              },
              layer: "below"
            });
          });
        });
      }
    );
  }

  /*
    Generic settings for plotly graph
  */
  let plotlyData = [];

  let plotlyLayout = {
    // https://community.plotly.com/t/how-to-select-pan-as-default-tool/3152
    dragmode: "pan",
    shapes: coverageShapes,
    autosize: true,
    xaxis: {
      showline: true,
      showgrid: false,
      range: [xMin, xMax],
      rangemode: "nonnegative",
      fixedrange: disableZooming, // if true, no zooming
      title: axisLabel,
      domain: [0, 0.95],
      zeroline: false,
      mirror: true // show axis line on opposite side of plot too
    },
    yaxis: {
      showline: true,
      showgrid: false,
      // autorange: "reversed",
      range: [yMax, yMin],
      rangemode: "nonnegative",
      fixedrange: disableZooming, // if true, no zooming
      title: axisLabel,
      domain: [0, 0.95],
      zeroline: false,
      mirror: true // show axis line on opposite side of plot too
    },
    xaxis2: {
      domain: [0.97, 1],
      visible: false,
      // autorange: true,
      fixedrange: true
    },
    yaxis2: {
      domain: [0.97, 1],
      visible: false,
      // autorange: true,
      fixedrange: true
    },
    hovermode: "closest",
    hoverlabel: { bgcolor: "#333333" },
    showlegend: false,
    margin: {
      b: 30,
      l: 30,
      r: 30,
      t: 30
    },
    uirevision: uiRevision // keep UI across rerenders... somehow doesn't work upon no 1
  };

  // axis for plotting alignment / EC coverage
  const axis3 = {
    domain: [0.955, 0.962],
    visible: false,
    type: "linear",
    fixedrange: true,
    range: [-1, 1]
    // autorange: true
    // autorange: true
    // range: [-1, 1],
    // autorange: true,
    // fixedrange: true,
    // showline: false,
    // showgrid: false,
  };
  // only add if alignment coverage actually defined
  if (alignmentCoverage) {
    plotlyLayout["xaxis3"] = axis3;
    plotlyLayout["yaxis3"] = axis3;
  }

  let plotlyConfig = {
    displayModeBar: false,
    scrollZoom: true,
    doubleClick: false
  };

  /*
    Derive label text for set of ECs/contacts
  */
  const createMarkerText = (xData, yData, xSymbols, ySymbols) => {
    // TODO: where to get AA sequence for experimental contacts?
    return xData.map((x, idx) => {
      const y = yData[idx];
      const pairID = createPairId(x, y);
      const xSymbol = xSymbols
        ? xSymbols[idx]
        : targetSequence
        ? targetSequence.get(parseInt(x, 10))
        : "";
      const ySymbol = ySymbols
        ? ySymbols[idx]
        : targetSequence
        ? targetSequence.get(parseInt(y, 10))
        : "";

      // add info about score and distances (where applicable) - will be null if key not present
      const couplingsInfo = couplingsMap.get(pairID);
      const monomerInfo = monomerContactsMap.get(pairID);
      const multimerInfo = multimerContactsMap.get(pairID);

      // TODO: round numbers and substitute
      const probStr = couplingsInfo
        ? `<br />Probability: ${couplingsInfo.probability.toFixed(
            NUM_DECIMAL_PLACES
          )}`
        : "";
      const scoreStr = couplingsInfo
        ? `<br />Score: ${couplingsInfo.score.toFixed(NUM_DECIMAL_PLACES)}`
        : "";

      // progressive override of distance info by priority (EC-based < multimer-based < monomer-based);
      // (this is intentionally written in Python style instead of ternary operator mess)
      let dist;
      if (couplingsInfo && couplingsInfo.dist) {
        dist = couplingsInfo.dist;
      }
      if (multimerInfo && multimerInfo.dist) {
        dist = multimerInfo.dist;
      }
      if (monomerInfo && monomerInfo.dist) {
        dist = monomerInfo.dist;
      }

      const distStr = dist
        ? `<br />Distance: ${dist.toFixed(NUM_DECIMAL_PLACES_DIST)} Å`
        : "";

      return `<b>${xSymbol}${x} – ${ySymbol}${y}</b>${probStr}${scoreStr}${distStr}`;
      /*
      // old version that distinguishes monomer and multimer labels
      const monomerDistStr = monomerInfo
        ? `<br />Distance: ${monomerInfo.dist.toFixed(
            NUM_DECIMAL_PLACES_DIST
          )} Å`
        : "";
      const multimerDistStr = multimerInfo
        ? `<br />Distance<sub>multimer</sub>: ${multimerInfo.dist.toFixed(
            NUM_DECIMAL_PLACES_DIST
          )} Å`
        : "";
        return `<b>${xSymbol}${x} – ${ySymbol}${y}</b>${probStr}${scoreStr}${monomerDistStr}${multimerDistStr}`;
      */
    });
  };

  /*
    Plots monomer and multimer contacts
  */
  const plotContacts = (contactTable, style) => {
    /*
    const xData = contactTable.getSeries("i").toArray();
    const yData = contactTable.getSeries("j").toArray();
    const pairIds = xData.map((x, idx) => createPairId(x, yData[idx]));
    */

    // return if nothing to plot
    if (contactTable.count() < 1) {
      return;
    }

    // sort selected pairs to top so marker border is not overlaid
    // by other points
    contactTable = contactTable
      .withSeries({
        pairId: contactTable =>
          contactTable.select(row => createPairId(row.i, row.j))
      })
      .orderBy(row => selectedPairsSet.has(row.pairId));

    const xData = contactTable.getSeries("i").toArray();
    const yData = contactTable.getSeries("j").toArray();
    const pairIds = contactTable.getSeries("pairId").toArray();

    plotlyData.push({
      x: xData,
      y: yData,
      type: scatterPlotType,
      mode: "markers",
      text: createMarkerText(xData, yData),
      hoverinfo: "text",
      marker: {
        // TODO: dynamic
        size: style.size,
        color: style.color,
        line: {
          color: highlightStyle.color,
          // will display all selected pairs except those that are EC-only and not a contact;
          // this will ensure line does not overlay part of the data but has widest necessary
          // radius; as contact point radius should be >= EC radius
          width: pairIds.map(id =>
            selectedPairsSet.has(id) // && !couplingsMap.has(id)
              ? highlightStyle.width
              : 0
          )
        }
      }
    });
  };

  /*
    Data setup for drawing
  */
  if (multimerContacts) {
    plotContacts(multimerContacts, multimerStyle);
  }

  if (monomerContacts) {
    plotContacts(monomerContacts, monomerStyle);
  }

  /*
    Plots ECs
  */
  const plotCouplings = (firstColumn, secondColumn, style) => {
    // if table is empty, do not plot anything (will crash otherwise)
    if (couplings.count() === 0) {
      return;
    }

    // just like contacts, sort ECs so selected pairs are properly plotted on top so
    // selection can be properly seen
    const couplingsSorted = couplings
      .withSeries({
        pairId: couplings =>
          couplings.select(row =>
            createPairId(row[firstColumn], row[secondColumn])
          )
      })
      .orderBy(row => selectedPairsSet.has(row.pairId));

    const xData = couplingsSorted.getSeries(firstColumn).toArray();
    const yData = couplingsSorted.getSeries(secondColumn).toArray();
    const xSymbols = couplingsSorted.getSeries("A_" + firstColumn).toArray();
    const ySymbols = couplingsSorted.getSeries("A_" + secondColumn).toArray();
    // const pairIds = xData.map((x, idx) => createPairId(x, yData[idx]));
    const pairIds = couplingsSorted.getSeries("pairId").toArray();

    // color of EC marker - either one color for all, or distinguish
    // true and false positives
    let markerColors;
    if (highlightTruePositives) {
      markerColors = pairIds.map(id =>
        monomerContactsMap.has(id) || multimerContactsMap.has(id)
          ? style.colorTrue
          : style.color
      );
    } else {
      markerColors = style.color;
    }

    const PROBABILITY_SIZE_BASE = 0.05;
    const SCORE_SIZE_BASE = 0.01;

    // determine size of EC markers
    let markerSizes;
    switch (scaleCouplings) {
      case SCALE_COUPLINGS_POINT_SIZE.NONE:
        markerSizes = style.size;
        break;
      case SCALE_COUPLINGS_POINT_SIZE.PROBABILITY:
        // smallest size set to > 0 by 0.05 + prob
        markerSizes = couplingsSorted
          .getSeries("probability")
          .toArray()
          .map(
            prob =>
              ((PROBABILITY_SIZE_BASE + prob) / (1 + PROBABILITY_SIZE_BASE)) *
              style.size
          );
        // scaling area rather than radius (should work better in terms of perception but does not really highlight differences):
        //.map(prob => Math.sqrt((PROBABILITY_SIZE_BASE + prob) / (1 + PROBABILITY_SIZE_BASE)) * style.size);
        break;
      case SCALE_COUPLINGS_POINT_SIZE.SCORE:
        const scores = couplingsSorted.getSeries("score");
        const maxScore = Math.max(0, scores.max());
        // similarly, put lower bound on point size for score
        markerSizes = scores.toArray().map(
          // scaling radius:
          // score => (Math.max(0, score) + SCORE_SIZE_BASE * maxScore) / (maxScore + SCORE_SIZE_BASE * maxScore) * style.size
          // scaling area rather than radius:
          score =>
            Math.sqrt(
              (Math.max(0, score) + SCORE_SIZE_BASE * maxScore) /
                (maxScore + SCORE_SIZE_BASE * maxScore)
            ) * style.size
        );
        break;
      default:
        markerSizes = style.size;
    }

    plotlyData.push({
      x: xData,
      y: yData,
      type: scatterPlotType,
      mode: "markers",
      text: createMarkerText(xData, yData, xSymbols, ySymbols),
      hoverinfo: "text", // disable trace info (note: cannot and do not need to use hovertemplate)
      marker: {
        size: markerSizes,
        color: markerColors,
        line: {
          color: highlightStyle.color,
          // only display for ECs that are not a contact, since contact point size must always be >= EC point size
          // (note: disabled that feature again... otherwise points may be overlapped and very hard to see)
          width: pairIds.map(id =>
            selectedPairsSet.has(id) // &&
              ? //!monomerContactsMap.has(id) &&
                // !multimerContactsMap.has(id)
                highlightStyle.width
              : 0
          )
        }
      }
    });
  };

  if (couplings) {
    // TODO: need to synchronize point labels of true positive contacts
    plotCouplings("i", "j", couplingsStyle);

    // couplings table only contains i < j, so if looking at symmtric intra contacts,
    // need to plot inverse set of points too;
    if (symmetric) {
      plotCouplings("j", "i", couplingsStyle);
    }
  }

  /* 
    plot secondary structure; assume this is already sorted in increasing order by position
    
    TODO: note this assumes symmetric secondary structure along x- and y-axis, so cannot
    use to plot inter-ECs at the moment
  */

  // mapping from sequence position to element index
  let posToElementIndex = Map();

  if (secondaryStructure) {
    // create mapping from sequence position to sec struct element index
    secondaryStructure.forEach((element, elementIndex) => {
      for (let pos = element.get("start"); pos <= element.get("end"); pos++) {
        posToElementIndex = posToElementIndex.set(pos, elementIndex);
      }
    });

    // console.log("### SECSTRUCT", secondaryStructure);
    // console.log("### POS TO ELEMENT", JSON.stringify(posToElementIndex));
    // secondaryStructure.forEach((elem, idx) => console.log("###", idx, elem));

    // below is drawing logic as used in contactmap.org site, not really necessary here since only one axis
    const secondaryStructures = [
      secondaryStructure,
      selectedSecondaryStructure
    ];
    const secondaryStructureStyles = [
      secStructStyle,
      secStructStyleHighlighted
    ];

    const addSecStructText = (axes, axisIndex) => {
      let secondaryStructureType = null;
      switch (contactSettings.shownSecondaryStructure) {
        case SECONDARY_STRUCTURE_MODE.EXPERIMENTAL:
          secondaryStructureType = "Experimental";
          break;
        case SECONDARY_STRUCTURE_MODE.PREDICTED:
          secondaryStructureType = "Predicted";
          break;
        case SECONDARY_STRUCTURE_MODE.SYNC_WITH_VIEWER:
          secondaryStructureType = "From 3D viewer";
          break;
        default:
          throw Error("Invalid selection");
      }
      const secStructTypeStr = secondaryStructureType
        ? `<br />${secondaryStructureType}`
        : null;

      return axes.map(plotItem => ({
        ...plotItem,
        hoverinfo: "text",
        text: plotItem[axisIndex].map(idx => {
          const residueText = targetSequence ? targetSequence.get(idx) : "";
          const segment = posToElementIndex.has(idx)
            ? secondaryStructure[posToElementIndex.get(idx)]
            : null;
          const segmentStr = segment
            ? `<br />${SECSTRUCT_LABEL_MAP[segment.get("type")]} (${segment.get(
                "start"
              )}-${segment.get("end")})`
            : null;
          return `<b>${residueText}${idx}</b>${segmentStr}${secStructTypeStr}`;
        })
      }));
    };

    secondaryStructures.forEach((currentSecStruct, index) => {
      if (currentSecStruct) {
        const currentSecStructBioblocks = currentSecStruct.map(
          element =>
            new Bioblocks1DSection(
              element.get("type"),
              element.get("start"),
              element.get("end")
            )
        );

        // const axis = new SecondaryStructureAxis(secondaryStructure, 3, index + 2, secondaryStructureColors);
        // returned axis object is a list of objects, so need to unpack before pushing
        const axis = new SecondaryStructureAxis(
          currentSecStructBioblocks,
          3, // minimum secondary structure length
          2, // index + 2, // determines x/y axis for plotly trace (e.g. "xaxis2")
          new ColorMapper(Map(Object.entries(secondaryStructureStyles[index])))
        );

        // console.log("### XAXIS", axis.xAxes); // TODO: remove
        // console.log("### YAXIS", axis.yAxes); // TODO: remove

        // patch secondary structure label text into axis
        const xAxes = addSecStructText(axis.xAxes, "x");
        const yAxes = addSecStructText(axis.yAxes, "y");

        plotlyData.push(...xAxes, ...yAxes);
      }
    });
  }

  /*
  Plot alignment coverage

  Only plot once other elements are present to avoid weird axis effects
  */
  if (alignmentCoverage && plotlyData.length > 0) {
    const positions = [];
    const otherAxisPositions = [];
    const positionLabels = [];
    alignmentCoverage.forEach(coverageSegment => {
      for (let pos = coverageSegment.start; pos <= coverageSegment.end; pos++) {
        positions.push(pos);
        otherAxisPositions.push(0);

        const residueText = targetSequence ? targetSequence.get(pos) : "";
        const segmentStr = `<br />EC coverage: ${coverageSegment.start}-${
          coverageSegment.end
        }<br />Segment length: ${coverageSegment.end -
          coverageSegment.start +
          1}`;
        positionLabels.push(`<b>${residueText}${pos}</b>${segmentStr}`);
      }

      // push null value at end of each segment so using connectgaps (see below)
      // interrupts line
      positions.push(null);
      otherAxisPositions.push(null);
      positionLabels.push(null);
    });

    const basicCoverageData = {
      type: "scatter",
      mode: "lines",
      line: alignmentCoverageStyle,
      connectgaps: false,
      showlegend: false,
      hoverinfo: "text",
      text: positionLabels
    };

    // push for both axes
    plotlyData.push({
      ...basicCoverageData,
      x: positions,
      y: otherAxisPositions,
      xaxis: "x",
      yaxis: "y3"
    });

    plotlyData.push({
      ...basicCoverageData,
      x: otherAxisPositions,
      y: positions,
      xaxis: "x3",
      yaxis: "y"
    });
  }

  // const derivedLayout = new PlotlyChart().deriveAxisParams(plotlyData);
  // console.log("DERIVED", derivedLayout);

  const handleEvent = (event, handlePairFunc, handleSecondaryStructureFunc) => {
    if (event && event.points && event.points.length > 0) {
      const point = event.points[0];
      const x = point.x;
      const y = point.y;
      const xAxis = point.xaxis._id;
      const yAxis = point.yaxis._id;

      // keys pressed while event was triggered
      const modifiers = event.event
        ? {
            ctrl: event.event.ctrlKey,
            shift: event.event.shiftKey,
            meta: event.event.metaKey,
            alt: event.event.altKey
          }
        : null;

      if (xAxis === "x" && yAxis === "y") {
        // check what type of pair clicked point is (EC, structure contact)
        const isCouplingsPair = couplingsMap.has(createPairId(x, y));
        const isMonomerContactPair = monomerContactsMap.has(createPairId(x, y));
        const isMultimerContactPair = multimerContactsMap.has(
          createPairId(x, y)
        );

        if (handlePairFunc) {
          handlePairFunc(
            x,
            y,
            isCouplingsPair,
            isMonomerContactPair,
            isMultimerContactPair,
            modifiers
          );
        }
      }

      if (xAxis === "x" && yAxis === "y2") {
        // use coordinate in x
        if (handleSecondaryStructureFunc && secondaryStructure) {
          handleSecondaryStructureFunc(
            x,
            posToElementIndex.get(x),
            secondaryStructure[posToElementIndex.get(x)],
            modifiers
          );
        }
        /* console.log(
          "AXIS CLICK y2 ... use x", x, y
        ); */
      }

      if (xAxis === "x2" && yAxis === "y") {
        // use coordinate in y
        if (handleSecondaryStructureFunc && secondaryStructure) {
          handleSecondaryStructureFunc(
            y,
            posToElementIndex.get(y),
            secondaryStructure[posToElementIndex.get(y)],
            modifiers
          );
        }
        /*
        console.log(
          "AXIS CLICK x2 ... use y", x, y
        ); */
      }
    }
  };

  return (
    <PlotlyWrapper
      divId={plotDivId} // div id for figure saving from outside
      data={plotlyData}
      layout={plotlyLayout}
      config={plotlyConfig}
      useResizeHandler={true}
      style={{ width: "100%", height: "100%" }}
      onClick={event =>
        handleEvent(event, handlePairClick, handleSecondaryStructureClick)
      }
      onHover={event =>
        handleEvent(event, handlePairHover, handleSecondaryStructureHover)
      }
      onUnhover={event =>
        handleEvent(event, handlePairUnhover, handleSecondaryStructureUnhover)
      }
      onDoubleClick={
        // manual function for resetting state on double click, since plotly solutions do not work
        event => {
          // console.log("### DOUBLE CLICK");
          setUiRevision(uiRevision + 1);
        }
      }
      // revision={state.revision}
      // frames={plotState.frames}
      // onInitialized={figure => {
      //   // console.log("** INIT STORE**");
      // }}
      // onUpdate={figure => {
      //  // console.log("**SET STORE**");
      // }}
      // onUpdate={(figure) => console.log("FIGURE", JSON.stringify(figure))}
      // onRelayout={() => dispatch({ type: "increment" })}
      // onClick={() => dispatch({type: "increment"})}
      // examples for event handling
      //onRelayout={event => {
      // TODO: Restrict zooming behaviour here
      //  console.log("RELAYOUT", revision, event);
      //  setRevision(revision + 1);
      //  console.log("...end...");
      // }}
    />
  );
};

export default ContactMap;
