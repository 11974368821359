import React, { useEffect, useState, useReducer, useMemo } from "react";

const EVZOOM_URL = "https://evzoom.org/?data=";

export const ParametersPanel = ({ parameterEndpointUrl }) => {
  // create encoded URL 
  const uri = EVZOOM_URL + encodeURIComponent(parameterEndpointUrl);

  return (
    <div>
      <a
        className="bp3-button bp3-icon-share bp3-minimal bp3-large"
        role="button"
        tabIndex={0}
        target="_blank"
        rel="noopener noreferrer"
        href={uri}
      >
        Open in evzoom.org
      </a>
    </div>
  );
};
