import React from "react";
import ReactTooltip from "react-tooltip";

// see re alignment of rows in flexbox (and truncation thereof)
// https://stackoverflow.com/questions/33454533/cant-scroll-to-top-of-flex-item-that-is-overflowing-container

export const DEFAULT_CELL_WIDTH = "10px";
export const DEFAULT_CELL_HEIGHT = "10px";
export const SELECTION_COLOR = "#dddddd";
// export const SELECTION_COLOR = "#ffd700";

/*
  HTML div-based heatmap (slow rendering, but allows
  flexbox to wrap heatmap to multiple lines)

  TODO:
  - dynamic coloring mapping from data to color
  - add styling properties (height, width, margin)
  - dynamic rendering of labels
  - allow selection of rows and columns, render highlight
*/
export const Heatmap = ({
  data,
  xLabels,
  yLabels,
  colorMap,
  labelMap,
  cellWidth,
  cellHeight,
  handleColumnClick,
  handleRowClick,
  handleCellClick,
  handleDeselect,
  selectedRows,
  selectedCols,
  selectedCells,
  cellSelectionStyle
}) => {
  if (!cellWidth) {
    cellWidth = DEFAULT_CELL_WIDTH;
  }

  if (!cellHeight) {
    cellHeight = DEFAULT_CELL_HEIGHT;
  }

  if (!cellSelectionStyle) {
    // cellSelectionStyle = "2px solid #E8B10C";
    cellSelectionStyle = "2px solid crimson";
  }
  
  // compute fixed width of x-axis labels so doesn't jitter
  // e.g. when switching to bold rendering for highlighting
  const labelLengthMax = Math.max(...xLabels.map(l => l.length));
  const xLabelHeight = (labelLengthMax * 10) + "px";

  const renderColumnBase = (i, columnContent, xLabel) => {
    return (
      <div
        key={i}
        style={{
          display: "flex",
          flexDirection: "column",
          width: cellWidth,
          marginBottom: "1em"
        }}
      >
        {columnContent}
        <div
          className="bp3-text-small bp3-text-muted"
          onClick={event => {
            // console.log("HANDLE XLABEL CLICK", i, xLabel);
            if (handleColumnClick) {
              handleColumnClick(i, xLabel);
            }
            event.stopPropagation();
          }}
          style={{
            height: xLabelHeight,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            writingMode: "vertical-lr",
            transform: "rotate(180deg)",
            paddingBottom: cellHeight,
            fontWeight:
              selectedCols && selectedCols.includes(i) ? "bold" : null,
            backgroundColor:
              selectedCols && selectedCols.includes(i) ? SELECTION_COLOR : null,
            cursor: "default"
          }}
        >
          {xLabel}
        </div>
      </div>
    );
  };

  const renderYLabels = () => {
    if (yLabels) {
      const columnContent = yLabels.map((label, j) => {
        return (
          <div
            key={"ylabel_" + j}
            onClick={event => {
              // console.log("HANDLE YLABEL CLICK", label, j);
              if (handleRowClick) {
                handleRowClick(j, label);
              }
              event.stopPropagation();
            }}
            className="bp3-text-small bp3-text-muted"
            style={{
              height: cellHeight,
              lineHeight: cellHeight,
              textAlign: "center",
              verticalAlign: "middle",
              fontWeight:
                selectedRows && selectedRows.includes(j) ? "bold" : null,
              backgroundColor:
                selectedRows && selectedRows.includes(j)
                  ? SELECTION_COLOR
                  : null,
              cursor: "default"
            }}
          >
            {label}
          </div>
        );
      });

      return renderColumnBase(-1, columnContent);
    } else {
      return <></>;
    }
  };

  const renderDataColumn = (column, i) => {
    const columnContent = column.map((cellValue, j) => (
      <div
        key={i + "_" + j}
        onClick={event => {
          // console.log("HANDLE ENTRY CLICK", i, j, cellValue);
          if (handleCellClick) {
            handleCellClick(i, j, cellValue);
          }
          event.stopPropagation();
        }}
        // note data-tip must be a string, so serialize object
        data-tip={JSON.stringify({ i: i, j: j })}
        data-for="heatmapLabel"
        style={{
          width: cellWidth,
          height: cellHeight,
          textAlign: "justify",
          backgroundColor: colorMap(cellValue),
          border:
            selectedCells && i in selectedCells && selectedCells[i].includes(j)
              ? cellSelectionStyle
              : null
        }}
      />
    ));
    return renderColumnBase(i, columnContent, xLabels[i]);
  };

  return (
    <>
      <ReactTooltip
        id="heatmapLabel"
        getContent={dataTip => (dataTip ? labelMap(JSON.parse(dataTip)) : null)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "top"
        }}
        onClick={() => {
          if (handleDeselect) {
            handleDeselect();
          }
        }}
      >
        {renderYLabels()}
        {data.map((column, i) => renderDataColumn(column, i))}
      </div>
    </>
  );
};

export default Heatmap;
