import React, { Component } from "react";
import {
  Icon,
  Intent,
  RangeSlider,
  Spinner,
  Tag,
  Text,
} from "@blueprintjs/core";
import { RANGE_SELECTION_HELP_TEXT } from "../../utils/Constants";
import PfamDomainGraph from "../common/PfamDomainGraph";
import { helpTooltip } from "../common/Helpers";

class DomainSelectionSlider extends Component {
  renderLabel = val => {
    let symbol;
    if (this.props.sequence && this.props.sequenceStart) {
      symbol = this.props.sequence.charAt(val - this.props.sequenceStart);
      return symbol + val.toString();
    } else {
      return val;
    }
  };

  renderSlider() {
    return (
      <div
        style={{
          display: "flex",
          marginTop: "0.5em",
          marginBottom: "0.5em",
          flexDirection: "row"
        }}
      >
        <RangeSlider
          min={this.props.sequenceStart}
          max={this.props.sequenceEnd}
          stepSize={1}
          labelStepSize={this.props.sequenceEnd - this.props.sequenceStart}
          disabled={false}
          value={[this.props.rangeStart, this.props.rangeEnd]}
          onChange={this.props.handleValueChange}
          labelRenderer={this.renderLabel}
        />
      </div>
    );
  }

  renderMessage() {
    return (
      <div
        style={{
          display: "flex",
          marginBottom: "0.5em",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "30px"
        }}
      >
        {this.props.domainsLoading ? (
          <Spinner size={Spinner.SIZE_SMALL} />
        ) : null}
        <Text>
          &emsp;
          {this.props.domainsMessage}
        </Text>
      </div>
    );
  }

  renderStatus() {
    return (
      <div
        style={{
          display: "flex",
          marginTop: "0.5em",
          justifyContent: "space-between"
        }}
      >
        {this.props.invalidSelectionMessage ? (
          <Tag
            intent={
              this.props.invalidSelectionMessage ? Intent.DANGER : Intent.NONE
            }
            icon={this.props.invalidSelectionMessage ? "cross" : null}
          >
            {this.props.invalidSelectionMessage
              ? this.props.invalidSelectionMessage
              : "Please select input range (optional)"}
          </Tag>
        ) : (
          <div />
        )}

        {helpTooltip(<Icon icon="help" />, RANGE_SELECTION_HELP_TEXT)}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.props.domainsMessage ? (
          this.renderMessage()
        ) : (
          <PfamDomainGraph
            sequenceStart={this.props.sequenceStart}
            sequenceEnd={this.props.sequenceEnd}
            domains={this.props.domains}
            handleValueChange={this.props.handleValueChange}
            domainOverhang={this.props.domainOverhang}
            connectSegments={true}
          />
        )}
        {this.renderSlider()}
        {this.renderStatus()}
      </div>
    );
  }
}

export default DomainSelectionSlider;
