import React from "react";
import { Colors } from "@blueprintjs/core";
import Dropzone from "react-dropzone";

const getDropZoneStyle = (isDragActive, isDragAccept, isDragReject) => {
  // TODO: move this somewhere else
  const FILE_DROP_STYLE = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    // borderColor: '#eeeeee',
    borderColor: Colors.GRAY4,
    borderStyle: "dashed",
    // backgroundColor: '#fafafa',
    // color: '#bdbdbd',
    outline: "none",
    transition: "border .24s ease-in-out"
  };

  let style = {
    ...FILE_DROP_STYLE,
    ...(isDragActive ? { borderColor: Colors.BLUE3 } : {}),
    ...(isDragAccept ? { borderColor: Colors.BLUE3 } : {}),
    ...(isDragReject ? { borderColor: Colors.RED3 } : {})
  };
  return style;
};

/*
  Thin wrapper around Dropzone component dealing with all the render prop stuff
*/
const DropzoneWrapper = ({onDrop, content}) => {
  return (
    <Dropzone onDrop={onDrop}>
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
      }) => (
        <section>
          <div
            {...getRootProps({
              style: getDropZoneStyle(isDragActive, isDragAccept, isDragReject)
            })}
          >
            <input {...getInputProps()} />
            {content}
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default DropzoneWrapper;
