import { Colors } from "@blueprintjs/core";

/*
export const API_BASE_URL = "http://localhost:5001/monomer/";
export const API_DOMAIN_URL = "http://localhost:5001/domains/";
export const API_SERVER_URL = "http://localhost:5001";
*/
// use process.env.NODE_ENV === 'production' / 'developnent'
/*
export const API_BASE_URL = "https://dev.api.evcouplings2.hms.harvard.edu/monomer/";
export const API_DOMAIN_URL = "https://dev.api.evcouplings2.hms.harvard.edu/domains/";
export const API_SERVER_URL = "https://dev.api.evcouplings2.hms.harvard.edu";
*/

export const API_BASE_URL = "https://api.evcouplings2.hms.harvard.edu/monomer/";
export const API_DOMAIN_URL = "https://api.evcouplings2.hms.harvard.edu/domains/";
export const API_SERVER_URL = "https://api.evcouplings2.hms.harvard.edu";

export const API_POLLING_INTERVAL = 20000;
export const DOMAIN_SCAN_POLLING_INTERVAL = 1000;

export const DOMAIN_COLORS = [
  Colors.COBALT5,
  Colors.LIME5,
  Colors.GOLD5,
  Colors.FOREST5,
  Colors.BLUE5,
  Colors.ORANGE5,
  Colors.VERMILION5,
  Colors.TURQUOISE5,
  Colors.INDIGO5,
  Colors.SEPIA5,
  Colors.GRAY5
];

export const RESET_SELECTION_ICON = "delete"; // other suitable icon options: trash, delete, cross

// same as https://github.com/debbiemarkslab/EVcouplings/blob/develop/evcouplings/utils/database.py
export const JOB_STATUS = {
  INIT: "initialized",
  PEND: "pending",
  RUN: "running",
  DONE: "done",
  FAIL: "failed", // job failed due to bug
  TERM: "terminated", // job was terminated externally
  BAILOUT: "bailout", // pipeline stopped itself
};

export const DEFAULT_EC_FILTERING_SETTINGS = {
  useScoreFilter: false,
  scoreFilterThreshold: 0,

  useProbabilityFilter: false,
  probabilityFilterThreshold: 0.9,

  useRankFilter: true,
  rankFilterThreshold: 1.0,

  useSeqDistFilter: true,
  seqDistFilterThreshold: 6
};

// never allow to show more ECs than this for performance reasons
export const MAX_EC_RANK_THRESHOLD = 5;

export const INFERENCE_METHODS = {
  PLM: "plm",
  MEANFIELD: "meanfield"
};

export const ALIGNMENT_METHODS = {
  HMMSEARCH: "hmmsearch",
  JACKHMMER: "jackhmmer"
};

export const SMALL_DIFF = 0.00001;

// identifiers accepted by backend - maybe better of in Api.js?
export const VALID_SEQUENCE_HEADER = /^\w+$/;

export const MIN_SEQUENCE_LENGTH = 30;
export const MAX_SEQUENCE_LENGTH = 700;
export const MAX_NUM_ALIGNMENT_THRESHOLDS = 6;

export const ALIGNMENT_THRESHOLD_BOUNDARIES = {
  BITSCORE_MIN: 0.05,
  BITSCORE_MAX: 1.5,
  BITSCORE_INIT: 0.05,
  EVALUE_MIN: 3,
  EVALUE_MAX: 100,
  EVALUE_INIT: 3
};

export const ALIGNMENT_ITERATIONS = {
  MIN: 1,
  MAX: 7
};

export const COMPARE_ALIGNMENT_ITERATIONS = {
  MIN: 1,
  MAX: 3
};

export const COMPARE_DISTANCE_TRESHOLD = {
  MIN: 2,
  MAX: 12
};

export const FOLD_MODEL_BOUNDARIES = {
  MIN: 5,
  MAX: 20
};

export const MAX_CUSTOM_MUTATIONS = 10000;

/* export const DEFAULT_PARAMS = {
  align: {
    bitscore: true,
    bitscoreThresholds: [0.1, 0.3, 0.4, 0.5, 0.6, 0.7],
    eValueThresholds: [3, 5, 10, 20, 40, 80]
  },
  couplings: {},
  fold: {},
  compare: {}
};
 */

export const DEFAULT_PARAMS = {
  bitscore: true,
  bitscoreThresholds: [0.1, 0.3, 0.5, 0.7],
  eValueThresholds: [3, 5, 20, 40, 80],
  sequenceDatabase: "uniref90",
  databaseRedundancyCutoff: 90,
  alignmentIterations: 5,
  theta: 80,
  sequenceIdentityFilter: 90,
  minimumColumnCoverage: 70,
  minimumSequenceCoverage: 50,
  inferenceMethod: INFERENCE_METHODS.PLM,
  runFold: false,
  numModels: 10,
  compareDistanceThreshold: 5,
  compareAlignmentMethod: ALIGNMENT_METHODS.JACKHMMER,
  compareAlignmentIterations: 1,
  compareAlignmentTreshold: 0.5
};

export const SEQUENCE_DATABASES = [
  {
    name: "uniref90",
    description: "UniRef90",
    identityCutoff: 90
  },
  {
    name: "uniref100",
    description: "UniRef100",
    identityCutoff: 100
  },
  {
    name: "mgnify",
    description: "MGnify+UniProt",
    identityCutoff: 100
  }
];

export const DEFAULT_IDENTITY_CUTOFF = 100;

export const SEQUENCE_INPUT_EXAMPLES = {
  accession: "P01112",
  entryName: "RASH_HUMAN",
  fastaSequence:
    ">RASH_HUMAN\nMTEYKLVVVGAGGVGKSALTIQLIQNHFVDEYDPTIEDSYRKQVVIDGETCLLDILDTAGQEEYSAMRDQYMRTGEGFLCVFAINNTKSFEDIHQYREQIKRVKDSDDVPMVLVGNKCDLAARTVESRQAQDLARSYGIPYIETSAKTRQGVEDAFYTLVREIRQHKLRKLNPPDESGPGCMSCKCVLS",
  rawSequence:
    "MTEYKLVVVGAGGVGKSALTIQLIQNHFVDEYDPTIEDSYRKQVVIDGETCLLDILDTAGQEEYSAMRDQYMRTGEGFLCVFAINNTKSFEDIHQYREQIKRVKDSDDVPMVLVGNKCDLAARTVESRQAQDLARSYGIPYIETSAKTRQGVEDAFYTLVREIRQHKLRKLNPPDESGPGCMSCKCVLS"
};

// TODO: refactor/rename/remove
export const RANGE_SELECTION_HELP_TEXT = `Limiting the input sequence range 
to the domain(s) of interest can increase alignment quality,
since current alignment programs may fail on multidomain proteins and
on long insertions. You can modify the input range manually by adjusting
the slider, or by clicking on any of the domains. To get full domain coverage,
it is recommended to add a small overhang of 5-10 residues on either side of the domain(s).`;

export const FIRST_INDEX_HELP_TEXT = `If your sequence is cut out of a longer protein, 
you can use this setting to adjust the numbering. This number will be assigned to the
first residue of your sequence.`;

export const ARCHIVE_HELP_TEXT = `Download a .zip archive file with all results
 for the respective alignment threshold.`;

export const THRESHOLD_HELP_TEXT = `Sequence alignments were created from deeper to shallower evolutionary depths.
 You can look at each of the results by clicking on the thresholds below. For your analysis, select the alignment
 that gives both sufficient coverage of your target sequence, enough sequences and reliable evolutionary couplings (see quality score column).`;

export const COVERAGE_HELP_TEXT = `Blue bars represent for which parts of your target sequence a reliable alignment could be generated.
 Positions with too many gaps (as defined in advanced settings on the input page) are excluded from the evolutionary couplings analysis and show up as thin
 black line. If you cannot get coverage, try stricter alignment inclusion thresholds, a different input region, or a less rigorous parameter value
 for the maximum percentage of gaps per column.`;

export const MEFF_HELP_TEXT = `Number of identified homologs of target sequence at given sequence alignment cutoff, after 
downweighting similar sequences according to the threshold selected in advanced settings ("number of effective sequences", "Meff").`;

export const MEFFOVERL_HELP_TEXT = `Number of redundancy-reduced identified homologs (see column to the left) divided by the number of confidently aligned positions in the alignment ("L").
Since this number corrects for different protein lengths, it is better suited to compare sequence numbers between proteins of different lengths ("Meff/L").`;

export const STATISTICS_HELP_TEXT = `Judge the quality of the results using the run quality score Q (from 0 (worst) to 10 (best). Result quality is judged using
a simple machine learning model of the evolutionary couplings calculated for the respective run.`;

export const JOB_FAIL_HELP_TEXT = `Your job failed due to technical problems. Please contact the developers using the email icon 
 to resolve this issue.`;

export const JOB_TERM_HELP_TEXT = `Job termination typically occurs if the computation exceeds the resources of the server 
 (takes too long or too much memory). To avoid this, use stricter alignment inclusion thresholds (higher bitscore / lower E-value)
 to reduce the number of sequences in the alignment, and consider shortening the target sequence region that you are running.
 `;

export const JOB_BAILOUT_HELP_TEXT = `EVcouplings stopped execution because too few homologous sequences could be found.
Please set a more inclusive bitscore or E-value threshold in advanced settings to find more sequences.`;

export const SUPPORT_EMAIL = "support@evcouplings.org";

export const ADV_OPTIONS_HELP_TEXTS = {
  ALIGN: {
    THRESHOLD_TYPE: `Type of threshold used for determining if sequences are similar enough
    to the target sequence to be included in the sequence alignment. Since E-value thresholds
    depend on the length of the protein and database size, they are not comparable between
    different proteins or sequence databases. We therefore recommend using length-normalized
    bitscore thresholds, as these serve as a comparable measure of evolutionary distance.
    `,
    THRESHOLD_LIST: `The EVcouplings server runs multiple sequence alignments for your target sequence,
    since the optimal choice of sequence inclusion treshold can vary substantially between protein
    families and also depends on your research question. The default bitscore threshold settings cover
    a wide range of values that should be sufficient for most cases. You can however add, remove or 
    modify thresholds as needed.
    `,
    DATABASE: `Sequence database that will be queried for homologs of the target sequence. Unless 
    investigating protein families with highly similar sequences (such as most viral proteins, or 
    ubiquitin), using a redundancy-reduced database such as UniRef90 can significantly speed up the 
    running time of your computation.`,
    ITERATIONS: `Maximum number of sequence search iterations when generating the sequence alignment.`,
    SEQID_FILTER: `After the sequence search, remove redundant sequences from the alignment that
    have a sequence identity higher than this percentage to other sequences in the alignment. Using this setting
    can speed up evolutionary couplings inference significantly, at the danger of removing informative
    sequences from the alignment. If possible, use a pre-filtered and pre-clustered sequence database
    such as UniRef90 instead, as this will additionally speed up the sequence search.`,
    THETA: `Cluster sequences with a pairwise sequence identity higher than this percentage, to decrease
    the influence of highly redundant sequences in the statistical inference. All n sequences
    in a cluster will be reweighted to contribute with weight 1/n during inference.
    Unlike filtering (previous setting), this setting does not remove redundant sequences entirely, but 
    adjusts their relative contribution to each other.`,
    MIN_COL_COVERAGE: `Only use alignment columns/sequence positions with at least this percentage of residues rather 
    than gaps during statistical inference (e.g., if set to 70%, a maximum of 30% gaps would be accepted). 
    Excluding positions with too many gaps helps to avoid spurious correlations between positions that lack
    evolutionary information in most identified homologs.
    `,
    MIN_SEQ_COVERAGE: `Only retain sequences in the alignment that cover at least this percentage
    of the target sequence length (i.e., eliminating shorter sequence fragments that cover only part of the 
    target sequence). Removing short fragments can lead to improved quality of the inferred evolutionary
    couplings.`
  },
  COUPLINGS: {
    INFERENCE_METHOD: `Approximation method used for solving the statistical optimization problem
    of inferring evolutionary couplings from a sequence alignment. In many (but not all) cases, using
    pseudo-likelihood maximization will give you more accurate structural contact predictions.
    Using the mean-field approximation can be beneficial for investigating conserved and highly coupled
    sites in protein families.`
  },
  FOLD: {
    RUN_FOLD: `Determines if 3D structure models will be computed from evolutionary couplings.
    If models are not needed, disabling this option will shorten the running time of your job.`,
    NUM_MODELS: `Determines how many candidate models will be sampled per set of EC-derived distance
    restraints. By increasing this number, you will increase the
    chance of converging to a good solution at the expense of longer
    running times. By decreasing the number of models, your job will finish more quickly.`
  },
  COMPARE: {
    DISTANCE_THRESHOLD: `Pairs of residues where the distance between any two atoms is below this 
    cutoff ("minimum atom distance") will be used as residue-residue contacts for the evaluation of 
    evolutionary couplings. Note that this setting is mainly for the downloadable result archive with
    static files. In the web result viewer, you will be able to explore different distance thresholds 
    interactively.`,
    ALIGNMENT_METHOD: `Search for homologous solved structures of the target sequence starting from
    the target sequence alone (conservative), or using the sequence profile from the alignment used for 
    evolutionary couplings inference (sensitive). The 'sensitive' option may lead to finding
    evolutionarily more distant structures. However it also increases the danger that these 
    structures are not representative of the target sequence anymore, if the sequence alignment is 
    too deep.`,
    ALIGNMENT_THRESHOLD: `Sequence inclusion threshold that determines how similar proteins
    with solved structures have to be to the target sequence/profile for inclusion in the 
    comparison of evolutionary couplings to known structures.`,
    ITERATIONS: `Maximum number of alignment iterations when searching homologous 
    structures of the target sequence.`
  }
};

export const RESULT_SETTINGS_HELP_TEXTS = {
  CUSTOM_STRUCTURE_UPLOAD: `Note that residue numbering in the uploaded structure must be exactly the same
  as in the EVcouplings target sequence (missing positions are not a problem), as no alignment will be 
  performed between the two. Acceptable structure formats include PDB and mmCIF.`,

  CUSTOM_MUTATIONS_UPLOAD: `Predict a custom set of single and higher-order mutations using EVmutation. 
  The input text file must contain exactly one mutant per line, with higher-order substitutions 
  separated by commas (e.g., Y4W for single mutant or Y4W,A102D for double mutant).`
};

// TODO: temporary
export const exampleDomains = [
  {
    start: 15,
    end: 65,
    name: "Amino_transp_2",
    identifier: "PF00019",
    score: 1.5e-10,
    numSequences: 42425
  },
  {
    start: 100,
    end: 240,
    name: "7tm_1",
    identifier: "PF00001",
    score: 5.4e-60,
    numSequences: 94122
  },
  {
    start: 270,
    end: 310,
    name: "SH3333333",
    identifier: "PF00018",
    score: 5.4e-60,
    numSequences: 94122
  }
  /* {
    start: 313,
    end: 400,
    name: "SH2",
    identifier: "PF00021",
    score: 5.4e-60,
    numSequences: 94122
  } */
];

// DLG4_RAT
export const exampleDomains2 = [
  {
    identifier: "PF10608",
    name: "MAGUK_N_PEST",
    start: 29,
    end: 64,
    score: "38.50",
    eValue: "3.3e-06",
    numSequences: "n/a"
  },
  {
    identifier: "PF00595",
    name: "PDZ",
    start: 65,
    end: 149,
    score: "76.90",
    eValue: "2.9e-18",
    numSequences: "n/a"
  },
  {
    identifier: "PF00595",
    name: "PDZ",
    start: 160,
    end: 244,
    score: "73.50",
    eValue: "3.5e-17",
    numSequences: "n/a"
  },
  {
    identifier: "PF10600",
    name: "PDZ_assoc",
    start: 245,
    end: 312,
    score: "94.60",
    eValue: "1.2e-23",
    numSequences: "n/a"
  },
  {
    identifier: "PF00595",
    name: "PDZ",
    start: 313,
    end: 391,
    score: "76.50",
    eValue: "3.8e-18",
    numSequences: "n/a"
  },
  {
    identifier: "PF00018",
    name: "SH3_1",
    start: 434,
    end: 490,
    score: "30.60",
    eValue: "0.00047",
    numSequences: "n/a"
  },
  {
    identifier: "PF00625",
    name: "Guanylate_kin",
    start: 533,
    end: 711,
    score: "229.00",
    eValue: "8.3e-65",
    numSequences: "n/a"
  }
];

export const EXAMPLE_SEGMENTS = {
  "0.3": [
    {
      start: 45,
      end: 240,
      color: Colors.BLUE1
    },
    {
      start: 258,
      end: 398,
      color: Colors.BLUE1
    }
  ],
  "0.5": [
    {
      start: 40,
      end: 408,
      color: Colors.BLUE1
    }
  ],
  "0.7": [
    {
      start: 35,
      end: 423,
      color: Colors.BLUE1
    }
  ]
};

export const JOB_DATA = {
  Job123: {
    pipeline: "monomer",
    jobGroupName: "Job123",
    bitscore: true,
    query: {
      sequence: "",
      sequenceStart: 1,
      sequenceEnd: 714,
      domains: exampleDomains2
    },
    jobs: [
      {
        bitscore: true,
        threshold: "0.1",
        status: JOB_STATUS.RUN,
        message: "couplings (2/5)",
        segments: null
      },
      {
        bitscore: true,
        threshold: "0.3",
        numSequences: 41123,
        status: JOB_STATUS.DONE,
        segments: EXAMPLE_SEGMENTS["0.3"],
        archiveURL:
          "https://marks.hms.harvard.edu/evmutation/data/effects/P30443_PF00129_15-213.csv",
        qualityScore: 7,
        neffOverL: 54.14123,
        significantECPercentage: 0.72
      },
      {
        bitscore: true,
        threshold: "0.5",
        numSequences: 22983,
        status: JOB_STATUS.DONE,
        segments: EXAMPLE_SEGMENTS["0.5"],
        archiveURL:
          "https://marks.hms.harvard.edu/evmutation/data/effects/P30443_PF00129_15-213.csv",
        qualityScore: 10,
        neffOverL: 17.1234,
        significantECPercentage: 1.01
      },
      {
        bitscore: true,
        threshold: "0.7",
        status: JOB_STATUS.FAIL,
        message: "Something went wrong...",
        segments: EXAMPLE_SEGMENTS["0.7"]
      }
    ]
  }
};
