import axios from "axios";

/* Repeatedly fetch data, cancel all previous requests that may still be ongoing */
export class RepeatedDataFetcher {
  /*
        Cancel any previous requests
    */
  cancel() {
    if (typeof this._source != typeof undefined) {
      this._source.cancel("Cancelled due to new request");
    }
  }

  /*
      Retrieve data from URL, cancelling all previous requests made through instance
      of this object
    */
  query(url, handleSuccess, handleResponseError, handleRequestError, config) {
    // check if there is a previous request ongoing
    this.cancel();

    // save the new request for cancellation
    this._source = axios.CancelToken.source();

    // then start new request
    axios({url: url, ...config, cancelToken: this._source.token })
      .then(handleSuccess)
      .catch(error => {
        if (error.response) {
          // response with non-2XX status code
          // handleResponseError(error);
          handleResponseError("Response Error");
        } else if (error.request) {
          // no response
          // handleRequestError(error);
          handleRequestError("Request Error");
        }
      });
  }
}
