/*
    Utilities related to structure loading
*/

import { Map, List, Set } from "immutable";
import { BioblocksPDB, NGLInstanceManager } from "bioblocks-viz";
// import * as NGL from "ngl";
import { apiServerAddBaseUrl } from "./Api";
import { makeDataFrame } from "./Helpers";
import {
  computeDistanceMap,
  aggregateDistanceMaps,
  DISTANCE_TYPE,
  extractSecondaryStructure
} from "./PDB";

export const STRUCTURE_CLASSES = {
  EXPERIMENTAL: 1,
  PREDICTED: 2,
  USER: 3
};

export const STRUCTURE_CLASS_MAP = {
  experimental: STRUCTURE_CLASSES.EXPERIMENTAL,
  predicted: STRUCTURE_CLASSES.PREDICTED,
  user: STRUCTURE_CLASSES.USER
};

export const STRUCTURE_CLASS_MAP_INV = Map(STRUCTURE_CLASS_MAP).flip();

export const STRUCTURE_STATUS = {
  LOADING: 0,
  LOADED: 1,
  ERROR: 2
};

export const DEFAULT_STRUCTURE_CLASS_ORDER = [
  "experimental",
  "user",
  "predicted"
];

export const FOLDING_PANEL_STRUCTURE_CLASS_ORDER = [
  "predicted",
  "experimental",
  "user"
];

// export const DEFAULT_STRUCTURE_CLASS_ORDER = ["user", "experimental", "predicted"];
// export const DEFAULT_STRUCTURE_CLASS_ORDER = ["predicted", "experimental", "user"];

export const EMPTY_STRUCTURE_POOL = Map();

export const DISTANCE_MAP_MODE = {
  EXPERIMENTAL_ALL: 1,
  EXPERIMENTAL_SUBSET: 2,
  SYNC_WITH_VIEWER: 3,
  NO_STRUCTURE: 4
};

export const SECONDARY_STRUCTURE_MODE = {
  EXPERIMENTAL: 1,
  PREDICTED: 2,
  SYNC_WITH_VIEWER: 3
};

const STRUCTURE_LOADING_STRATEGY = {
  PARALLEL: 1,
  SEQUENTIAL_INDIVIDUAL_DISPATCHES: 2,
  SEQUENTIAL_BATCH_DISPATCH: 3
};

// kept here since not quite an option that should be configurable
const SELECTED_STRUCTURE_LOADING_STRATEGY =
  STRUCTURE_LOADING_STRATEGY.SEQUENTIAL_BATCH_DISPATCH;

const SELECTED_DISTANCE_MAP_STRATEGY =
  STRUCTURE_LOADING_STRATEGY.SEQUENTIAL_BATCH_DISPATCH;

/*
  Collect all structure information in one joint, consistent
  data structure
*/
export const gatherAvailableStructures = (
  experimentalStructureData,
  predictedStructureData,
  userStructures,
  loadingExperimentalStructures,
  loadingPredictedStructures
) => {
  let experimentalStructureInfo;
  let predictedStructureInfo;
  let userStructureInfo;

  // experimental structures
  if (
    experimentalStructureData &&
    experimentalStructureData.data &&
    experimentalStructureData.data.structures
  ) {
    const expInfo = experimentalStructureData.data.structures.map(s =>
      Map({
        id: s.id,
        text: `${s.pdb_id}:${s.pdb_chain} / ${s.coord_start}-${s.coord_end}`,
        annotation: "E=" + s.e_value.toExponential(1),
        url: s.links.coords,
        allData: s
      })
    );
    experimentalStructureInfo = List(expInfo);
  }

  // predicted structures
  if (
    predictedStructureData &&
    predictedStructureData.data &&
    predictedStructureData.data.models
  ) {
    const predInfo = predictedStructureData.data.models.map(s =>
      Map({
        id: s.id,
        text: s.model_name_short,
        // annotation: `#${s.model_rank} (${s.ranking_score.toFixed(2)})`,
        // AF modification:
        annotation: null,
        url: s.links.coords,
        allData: s
      })
    );
    predictedStructureInfo = List(predInfo);
  }

  // user-uploaded structures
  if (userStructures && userStructures.structures) {
    const userInfo = userStructures.structures.map(s =>
      Map({
        id: s.id,
        text: s.name,
        annotation: null,
        allData: s
      })
    );

    userStructureInfo = List(userInfo);
  }

  return Map({
    experimental: experimentalStructureInfo,
    predicted: predictedStructureInfo,
    user: userStructureInfo,

    // record if structure state is final (fully loaded, if available)
    experimentalIsFinal:
      experimentalStructureData && experimentalStructureData.data,
    predictedIsFinal: predictedStructureData && predictedStructureData.data,

    loading: loadingExperimentalStructures || loadingPredictedStructures,
    error:
      (experimentalStructureData && experimentalStructureData.error) ||
      (predictedStructureData && predictedStructureData.error),
    refetch: () => {
      // console.log("### TRIGGER REFETCH", experimentalStructureData, predictedStructureData);  // TODO: remove
      
      if (
        experimentalStructureData &&
        experimentalStructureData.error &&
        experimentalStructureData.refetch
      ) {
        experimentalStructureData.refetch();
      }

      if (
        predictedStructureData &&
        predictedStructureData.error &&
        predictedStructureData.refetch
      ) {
        predictedStructureData.refetch();
      }
    }
  });
};

export const StructureSelectionReducer = (state, action) => {
  switch (action.action) {
    case "setAll":
      return action.selection;
    case "setPool":
      return state.set(action.pool, action.selection);
    case "reset":
      return null;
    default:
      return state;
  }
};

/*
  Determine if all selected structures are still available, otherwise remove them from selection
*/
export const removeInvalidStructures = (
  availableStructures,
  selectedStructures,
  dispatchStructureSelection
) => {
  if (!selectedStructures) {
    return;
  }

  // check what structures (per pool) are still valid (i.e., clean selection)
  // nested structure, first level is pool, second selections
  const validSelectedStructures = selectedStructures.map(
    // s => console.log("::: selected", JSON.stringify(s), s.get("id"), s.get("class"))
    (poolSelection, poolName) =>
      poolSelection.filter(s => {
        const currentClass = s.get("class");
        const currentId = s.get("id");

        const structures = availableStructures.get(
          STRUCTURE_CLASS_MAP_INV.get(currentClass)
        );

        // check if we have structures and there is at least one structure with the identifier of s
        return (
          structures &&
          structures.filter(s2 => s2.get("id") === currentId).size > 0
        );
      })
  );

  // perform equality check and dispatch corrected selection if we lost any selected structures
  if (!selectedStructures.equals(validSelectedStructures)) {
    // console.log("::: INVALID - DISPATCH!");
    dispatchStructureSelection({
      action: "setAll",
      selection: validSelectedStructures
    });
  }
};

// example for structure loading
export const EXAMPLE_POOLS = Map({
  couplings: List([
    Map({
      id: 219,
      class: STRUCTURE_CLASSES.EXPERIMENTAL
    }),
    Map({
      id: 0,
      class: STRUCTURE_CLASSES.PREDICTED
    })
  ]),
  sites: List([
    Map({
      id: 173,
      class: STRUCTURE_CLASSES.EXPERIMENTAL
    })
  ])
});

/*
  Pick default selected structure per pool
*/
export const initializePools = (availableStructures, pools) => {
  // find first structure clas that has a suitable structure, and take first from that list
  // (assumes ordering as returned by REST API means first is best)
  const findCandidate = classOrderingParams => {
    let chosenCandidates = List([]);

    const classOrdering = List(classOrderingParams.order);
    for (let i = 0; i < classOrdering.size; i++) {
      const structureClass = classOrdering.get(i);
      const structureClassIndex = STRUCTURE_CLASS_MAP[structureClass];
      const candidates = availableStructures.get(structureClass);
      // console.log(":::", structureClass, structureClassIndex);

      if (candidates && List.isList(candidates) && candidates.size > 0) {
        chosenCandidates = chosenCandidates.concat(
          candidates
            .slice(0, 1)
            .map(cand =>
              Map({ id: cand.get("id"), class: structureClassIndex })
            )
        );

        // console.log(":::...could work", JSON.stringify(chosenCandidates));

        // check if we have enough candidates; if so, break loop and return
        if (chosenCandidates.count() >= classOrderingParams.count) {
          break;
        }
      }
    }

    return chosenCandidates;
  };

  const selection = Map(pools).map((classOrdering, poolName) => {
    // console.log(":::", poolName, classOrdering);
    return findCandidate(classOrdering);
  });
  /*console.log(
    "::: DEFAULT POOLS",
    Map.isMap(selection),
    JSON.stringify(selection)
  );*/

  return selection;
};

const createStructureValue = (
  structure,
  distances,
  status,
  secondaryStructure
) =>
  Map({
    structure: structure,
    distances: distances,
    status: status,
    secondaryStructure: secondaryStructure
  });

/*
  Trigger distance map calculation for selected structure pools
  from loaded 3D structures
*/
export const updateDistanceMapPool = (
  poolsToProcess,
  selectedStructures,
  distanceMapPool,
  dispatchDistanceMapPool,
  structurePool,
  userStructures,
  jobGroup,
  job
) => {
  if (!selectedStructures) {
    // console.log("/// NOTHING TO DO");
    return;
  }

  // include user-uploaded structures in joint pool for easier structure selection
  // when starting distance map calculation
  const unifiedPool = createUnifiedPool(
    structurePool,
    userStructures,
    jobGroup,
    job
  );

  // cast array/list of relevant pools into immutable List
  poolsToProcess = List(poolsToProcess);

  // extract structure pools for which distance maps should be calculated
  const selectedPoolsForDistMaps = selectedStructures.filter(
    (selections, poolId) => poolsToProcess.contains(poolId)
  );

  // create flat set of all currently selected structures (in all pools);
  // important: need to filter to structures that are already loaded or otherwise
  // cannot trigger distance map calculation
  const selectedStructuresFlat = Set.union(selectedPoolsForDistMaps.values());

  const selectedStructuresFlatFiltered = selectedStructuresFlat.filter(s => {
    const key = Map({
      id: s.get("id"),
      class: s.get("class"),
      jobGroup: jobGroup,
      job: job
    });

    // console.log("/// KEY", JSON.stringify(key), unifiedPool.has(key), JSON.stringify(unifiedPool.keySeq()));

    return (
      unifiedPool.has(key) &&
      unifiedPool.get(key).get("status") === STRUCTURE_STATUS.LOADED
    );
  });

  // console.log("/// FLAT", JSON.stringify(selectedStructuresFlatFiltered));

  // subdivide distance maps in those already calculated and those
  // that still need to be calculated
  const distanceMapsToCalculate = partitionStructures(
    selectedStructuresFlatFiltered,
    distanceMapPool,
    dispatchDistanceMapPool,
    null,
    jobGroup,
    job,
    true // include user structures
  );

  // TODO: remove
  /*
  console.log(
    "/// UPDATE DISTANCE MAPS",
    JSON.stringify(selectedStructures),
    "...",
    JSON.stringify(distanceMapsToCalculate)
  );
  */

  // console.log("/// KEYS", unifiedPool.map((v, k) => console.log("/// KEY...", JSON.stringify(k))));

  if (
    SELECTED_DISTANCE_MAP_STRATEGY ===
      STRUCTURE_LOADING_STRATEGY.SEQUENTIAL_INDIVIDUAL_DISPATCHES ||
    SELECTED_DISTANCE_MAP_STRATEGY ===
      STRUCTURE_LOADING_STRATEGY.SEQUENTIAL_BATCH_DISPATCH
  ) {
    // console.log("*** DISTMAP SEQUENTIAL");
    (async () => {
      // gather distmaps for batch update in here
      let batchStructureUpdate = new Map();

      for (let [k, v] of distanceMapsToCalculate) {
        const model = unifiedPool.get(k).get("structure").nglData;

        let update;
        try {
          const x = await computeDistanceMap(
            model,
            DISTANCE_TYPE.MINIMUM_ATOM,
            false,
            false
          );
          // console.log("/// FINISHED DISTMAP", JSON.stringify(k));
          update = createStructureValue(null, x, STRUCTURE_STATUS.LOADED, null);
        } catch (error) {
          update = createStructureValue(
            null,
            null,
            STRUCTURE_STATUS.ERROR,
            null
          );
        }

        if (
          SELECTED_DISTANCE_MAP_STRATEGY ===
          STRUCTURE_LOADING_STRATEGY.SEQUENTIAL_INDIVIDUAL_DISPATCHES
        ) {
          dispatchDistanceMapPool({
            action: "updateSingle",
            key: k,
            value: update
          });
        } else {
          // console.log("*** BATCH UPDATE");
          batchStructureUpdate = batchStructureUpdate.set(k, update);
        }
      }

      // if we collected distance maps for batch update, dispatch now
      if (
        SELECTED_DISTANCE_MAP_STRATEGY ===
        STRUCTURE_LOADING_STRATEGY.SEQUENTIAL_BATCH_DISPATCH
      ) {
        if (batchStructureUpdate.count() > 0) {
          /*console.log(
            "*** DISTMAP -- DISPATCH! --",
            batchStructureUpdate.count()
          );*/
          dispatchDistanceMapPool({
            action: "updateMultiple",
            batchUpdate: batchStructureUpdate
          });
        }
      }
    })();
  } else {
    // trigger distance map calculation for all missing structures
    distanceMapsToCalculate.forEach((v, k) => {
      // since candidates are already filtered for loaded structures at the beginning,
      // can simply get model from dict here
      const model = unifiedPool.get(k).get("structure").nglData;

      computeDistanceMap(model, DISTANCE_TYPE.MINIMUM_ATOM, false, false).then(
        x => {
          // console.log("/// FINISHED DIST MAP", x);
          dispatchDistanceMapPool({
            action: "updateSingle",
            key: k,
            value: createStructureValue(null, x, STRUCTURE_STATUS.LOADED, null)
          });
        },
        x => {
          // console.log("/// FAILED DIST MAP", x);
          dispatchDistanceMapPool({
            action: "updateSingle",
            key: k,
            value: createStructureValue(
              null,
              null,
              STRUCTURE_STATUS.ERROR,
              null
            )
          });
        }
      );
    });
  }
};

/*
  Create one joint distance map per pool/panel
*/
export const mergeDistanceMapsPerPanel = (
  distanceMapPanels,
  selectedStructures,
  distanceMapPool,
  jobGroup,
  job
) => {
  if (!selectedStructures || !distanceMapPanels) {
    return;
  }

  // cast array/list of relevant pools into immutable List
  distanceMapPanels = List(distanceMapPanels);

  // function to aggregate distance maps for one selection
  const createAggregation = poolSelection => {
    const relevantDistMaps = distanceMapPool
      .filter(
        (v, s) =>
          s.get("jobGroup") === jobGroup &&
          s.get("job") === job &&
          poolSelection.contains(
            Map({ id: s.get("id"), class: s.get("class") })
          ) &&
          v.get("status") === STRUCTURE_STATUS.LOADED
      )
      .map((v, s) => v.get("distances"))
      .valueSeq();

    if (relevantDistMaps.count() === 0) {
      // console.log("/// NO DISTMAP YET");
      return null;
    } else {
      // console.log("/// MERGING DISTMAPS");
      return aggregateDistanceMaps(relevantDistMaps);
    }

    /*
    console.log(
      "/// REMAINING",
      JSON.stringify(poolSelection),
      JSON.stringify(relevantDistMaps)
    ); //, jobGroup, job, relevantDistMaps.keySeq()); //JSON.stringify(relevantDistMaps.keySeq()))

    // TODO: aggregate if not empty, else return null
    // poolSelection.map(
    //  s => console.log("/// NAME", JSON.stringify(s))
    //);
    return 456;
    */
  };

  const poolToDistanceMap = selectedStructures
    .filter((poolSelection, poolName) => distanceMapPanels.contains(poolName))
    .map((poolSelection, poolName) => createAggregation(poolSelection));

  /*
  console.log(
    "/// UPDATE MERGED MAPS",
    JSON.stringify(distanceMapPanels),
    JSON.stringify(poolToDistanceMap)
  );
  */

  return poolToDistanceMap;
};

/*
  Hacky function to ... this should eventually be inverse to createResidueKey()
  in PDB.js

  TODO: create proper generic function
*/
export const extractPairId = pairStr => {
  const split = pairStr.split("_");
  return {
    i: Number.parseInt(split[0], 10),
    j: Number.parseInt(split[1], 10)
  };
};

/*
  Turn distance map dictionary in dataframe
  of contacts (distance <= treshold) with columns
  i, j and dist; adds symmetric pair (j, i) for 
  each pair (i, j).
*/
export const getContactsFromDistanceMap = (
  mergedDistanceMaps,
  contactDistanceThreshold
) => {
  if (!mergedDistanceMaps) {
    return null;
  }

  const contacts = mergedDistanceMaps.map((distMap, panel) => {
    if (!distMap) {
      return null;
    }

    const filteredPairs = distMap.filter(
      (dist, pair) => dist <= contactDistanceThreshold
    );

    const transformedPairs = filteredPairs
      .entrySeq()
      .map(
        // pair is list ["i_j", dist]
        pair => {
          const { i, j } = extractPairId(pair[0]);
          return {
            i: i,
            j: j,
            dist: pair[1]
          };
        }
      )
      .toJSON();

    // add inverse pair too
    const transformedPairsInv = transformedPairs.map(pair => ({
      i: pair.j,
      j: pair.i,
      dist: pair.dist
    }));
    // console.log("/// AAA", JSON.stringify(transformedPairs)); // TODO: remove

    // turn into dataframe
    const contactTable = makeDataFrame(
      transformedPairs.concat(transformedPairsInv)
    );

    // console.log("/// TABLE", contactTable.tail(5).toString()); // TODO: remove

    return contactTable;
  });

  return contacts;
};

/*
  Subdivide structures/distance maps into these
  which are already loaded/calculated and need to be kept,
  and those which need to be loaded/caluclated.
*/
const partitionStructures = (
  selectedStructuresFlat,
  structurePool,
  dispatchStructurePool,
  infoIsLoaded,
  jobGroup,
  job,
  includeUserStructures // necessary for distance map calculations
) => {
  // first, determine which structures to load for current selection
  // (only experimental and predicted, not user structures since not fetched from REST API)
  // note: we have to wait until structure info for that class is available
  // (not an issue unless structure selection is pre-specified before data becomes available)
  const load = selectedStructuresFlat.filter(
    s =>
      !structurePool.has(
        Map({
          id: s.get("id"),
          class: s.get("class"),
          jobGroup: jobGroup,
          job: job
        })
      ) &&
      (includeUserStructures || s.get("class") !== STRUCTURE_CLASSES.USER) &&
      (!infoIsLoaded || infoIsLoaded(s.get("class")))
  );

  /*console.log(
    "--- LOAD ---",
    JSON.stringify(load),
    infoIsLoaded ? infoIsLoaded(STRUCTURE_CLASSES.EXPERIMENTAL) : null,
    infoIsLoaded ? infoIsLoaded(STRUCTURE_CLASSES.PREDICTED) : null
  );*/

  // second, determine which structure to keep in current structure pool
  const keep = structurePool.filter(
    (value, s) =>
      s.get("jobGroup") === jobGroup &&
      s.get("job") === job &&
      selectedStructuresFlat.has(
        Map({ id: s.get("id"), class: s.get("class") })
      )
  );

  // console.log("--- KEEP ---", JSON.stringify(keep), Map.isMap(keep));

  // then, add loading structures (for now, null, until set after loading)
  const loadingStructures = Map(
    load.map(s => [
      Map({
        id: s.get("id"),
        class: s.get("class"),
        jobGroup: jobGroup,
        job: job
      }),
      createStructureValue(null, null, STRUCTURE_STATUS.LOADING)
    ])
  );

  // console.log("--- FOR LOADING", loadingStructures);
  // console.log("--- FOR LOADING ---", loadingStructures.size, JSON.stringify(loadingStructures));

  const newStructurePool = keep.merge(loadingStructures);
  // console.log(" --- NEW ---", newStructurePool.size, JSON.stringify(newStructurePool));

  // only dispatch pool update if new structures to be loaded or old ones to be removed
  if (loadingStructures.size > 0 || keep.size !== structurePool.size) {
    // console.log("---ACTUALLY START LOADING...", loadingStructures.size);
    dispatchStructurePool({ action: "setPool", pool: newStructurePool });
  } else {
    // console.log("--- DO NOT LOAD...");
  }

  return loadingStructures;
};

/*
  Helper to extract structure REST API URL
*/
const getStructureUrlAndName = (k, availableStructures) => {
  const id = k.get("id");
  const structureClass = k.get("class");
  // console.log("--- TRIGGER", id, structureClass);

  const structureInfoForClass =
    structureClass === STRUCTURE_CLASSES.EXPERIMENTAL
      ? availableStructures.get("experimental")
      : availableStructures.get("predicted");

  // console.log("--- INFOAVAIL", structureClass, infoIsLoaded(structureClass)); //, JSON.stringify(structureInfoForClass));

  // no need to check class here since structure info subsection for that class already extracted
  const target = structureInfoForClass.filter(s => s.get("id") === id);

  if (target.size !== 1) {
    throw new Error("List must contain exactly one entry for structure");
  }

  const selTarget = target.get(0);
  // console.log("+++ TARGET", JSON.stringify(selTarget.get("text")));
  
  // original version before AF modification
  //const url = apiServerAddBaseUrl(selTarget.get("url"));
  
  // new version for AF modification: allow to load external URLs
  const url = (structureClass === STRUCTURE_CLASSES.PREDICTED && selTarget.get("url") && selTarget.get("url").startsWith("http")) ? 
    selTarget.get("url") : 
    apiServerAddBaseUrl(selTarget.get("url"));
  // console.log("### URL", selTarget.get("url"), selTarget.get("url").startsWith("http"));  // TODO: remove
  // end AF modification

  return { url: url, text: selTarget.get("text") };
};

/*
  Note this has side effects on structure
*/
const prepareStructure = (structure, key, name, fileName) => {
  // add fields to NGL object, modify in place...
  // attach key to structure so this info is available e.g. inside NGL viewer
  structure["key"] = key;

  // attach name for showing in hover
  structure["name"] = name;

  const result = new BioblocksPDB();
  result.nglData = structure;

  // set unique structure identifier
  result.uuid = fileName;

  // extract secondary structure and store with PDB
  const secStruct = extractSecondaryStructure(result.nglData);

  return {
    structure: result,
    secStruct: secStruct
  };
};

export const updateStructurePool = (
  availableStructures,
  selectedStructures,
  structurePool,
  dispatchStructurePool,
  jobGroup,
  job
) => {
  // console.log("--- AVAILABLE", availableStructures.toJS());
  // console.log("--- LOADING SELECTED ---", JSON.stringify(selectedStructures));
  // console.log("--- CURRENT:", JSON.stringify(structurePool));

  // console.log("--- FLAT ---", JSON.stringify(allSelectedStructures));

  // check if info for predicted/experimental structure already available
  const infoIsLoaded = structureClass =>
    structureClass === STRUCTURE_CLASSES.EXPERIMENTAL
      ? List.isList(availableStructures.get("experimental"))
      : List.isList(availableStructures.get("predicted"));

  // create flat set of all currently selected structures (in all pools)
  const selectedStructuresFlat = Set.union(selectedStructures.values());

  // joint logic for structure pool/distance map pool updates; refactored out for both cases
  const loadingStructures = partitionStructures(
    selectedStructuresFlat,
    structurePool,
    dispatchStructurePool,
    infoIsLoaded,
    jobGroup,
    job
  );

  if (
    SELECTED_STRUCTURE_LOADING_STRATEGY ===
      STRUCTURE_LOADING_STRATEGY.SEQUENTIAL_INDIVIDUAL_DISPATCHES ||
    SELECTED_STRUCTURE_LOADING_STRATEGY ===
      STRUCTURE_LOADING_STRATEGY.SEQUENTIAL_BATCH_DISPATCH
  ) {
    // console.log("*** SEQUENTIAL");
    (async () => {
      // gather structures for batch update in here
      let batchStructureUpdate = new Map();

      // cannot forEach with async/await, so use classical for loop instead
      for (let [k, v] of loadingStructures) {
        const { url, text } = getStructureUrlAndName(k, availableStructures);
        // console.log("*** LOADING", url);

        let update;
        try {
          const x = await NGLInstanceManager.instance.autoLoad(url, {
            ext: "pdb"
          });
          const { structure, secStruct } = prepareStructure(x, k, text, url);

          update = createStructureValue(
            structure,
            null,
            STRUCTURE_STATUS.LOADED,
            secStruct
          );
        } catch (error) {
          update = createStructureValue(
            null,
            null,
            STRUCTURE_STATUS.ERROR,
            null
          );
        }

        if (
          SELECTED_STRUCTURE_LOADING_STRATEGY ===
          STRUCTURE_LOADING_STRATEGY.SEQUENTIAL_INDIVIDUAL_DISPATCHES
        ) {
          // console.log("*** LOADED", url); // TODO: remove

          dispatchStructurePool({
            action: "updateSingle",
            key: k,
            value: update
          });
        } else {
          // console.log("*** BATCH UPDATE");
          batchStructureUpdate = batchStructureUpdate.set(k, update);
        }
      }

      // if we collected structures for batch update, dispatch now
      if (
        SELECTED_STRUCTURE_LOADING_STRATEGY ===
        STRUCTURE_LOADING_STRATEGY.SEQUENTIAL_BATCH_DISPATCH
      ) {
        if (batchStructureUpdate.count() > 0) {
          dispatchStructurePool({
            action: "updateMultiple",
            batchUpdate: batchStructureUpdate
          });
        }
      }
    })();
  } else {
    // console.log("*** PARALLEL");
    // dispatch loading jobs for all structures that need to be fetched
    loadingStructures.forEach((v, k) => {
      const { url, text } = getStructureUrlAndName(k, availableStructures);
      // console.log("*** LOADING", url);

      NGLInstanceManager.instance.autoLoad(url, { ext: "pdb" }).then(
        x => {
          const { structure, secStruct } = prepareStructure(x, k, text, url);

          // console.log("*** LOADED", url); // TODO: remove
          dispatchStructurePool({
            action: "updateSingle",
            key: k,
            value: createStructureValue(
              structure,
              null,
              STRUCTURE_STATUS.LOADED,
              secStruct
            )
          });
        },
        x => {
          // console.log("*** error loading structure", x); // TODO: remove
          dispatchStructurePool({
            action: "updateSingle",
            key: k,
            value: createStructureValue(
              null,
              null,
              STRUCTURE_STATUS.ERROR,
              null
            )
          });
        }
      );
    });
  }
};

/*
  Reducer for updating structure pool
*/
export const StructureLoadingReducer = (state, action) => {
  switch (action.action) {
    case "setPool":
      // console.log("--- SETTING POOL ---");
      return action.pool;
    case "setLoadedStructure":
      // console.log("*** UPDATE STRUCTURE ---", action);
      return state.set(
        action.key,
        createStructureValue(
          action.structure,
          action.distances,
          STRUCTURE_STATUS.LOADED,
          action.secondaryStructure
        )
      );
    case "updateSingle":
      // console.log("*** UPDATE SINGLE --- ");
      return state.set(action.key, action.value);
    case "updateMultiple":
      // console.log("*** UPDATE MULTIPLE ---");
      return state.merge(action.batchUpdate);
    case "failLoadedStructure":
      // console.log("--- FAIL STRUCTURE ---", action);
      return state.set(
        action.key,
        createStructureValue(null, null, STRUCTURE_STATUS.ERROR)
      );
    case "removeFailedStructures":
      // remove failed structures from loaded pool (will trigger automatic reloading)
      // console.log("### STATE", state); // TODO: remove
      return state.filter((v, k) => v.get("status") !== STRUCTURE_STATUS.ERROR);
    case "reset":
      // console.log("--- structure pool reset");
      return Map();
    default:
      return state;
  }
};

/*
  Create joint structure pool from loaded structures and user structures
*/
const createUnifiedPool = (structurePool, userStructures, jobGroup, job) => {
  const userPool = Map(
    userStructures.structures.map(s => [
      Map({
        id: s.id,
        class: STRUCTURE_CLASSES.USER,
        jobGroup: jobGroup,
        job: job
      }),
      createStructureValue(
        s.model,
        null,
        STRUCTURE_STATUS.LOADED,
        s.secondaryStructure
      )
    ])
  );

  // merge pools
  const unifiedPool = userPool.merge(structurePool);

  return unifiedPool;
};

/*
  Get list of models for 3D viewer component
*/
export const extractPoolStructures = (
  selectedStructures,
  structurePool,
  userStructures,
  jobGroup,
  job
) => {
  // console.log("::: USER", userStructures);
  // turn user structures into same pool format as loaded structures for easier merging in next step
  /*const userPool = Map(
    userStructures.structures.map(s => [
      Map({
        id: s.id,
        class: STRUCTURE_CLASSES.USER,
        jobGroup: jobGroup,
        job: job
      }),
      createStructureValue(s.model, null, STRUCTURE_STATUS.LOADED)
    ])
  );

  // merge pools
  const unifiedPool = userPool.merge(structurePool);*/

  const unifiedPool = createUnifiedPool(
    structurePool,
    userStructures,
    jobGroup,
    job
  );

  const poolToStructures = selectedStructures.map((poolSelection, poolName) =>
    // get all structures from overall structure pool that are in current pool selection
    unifiedPool.filter((value, s) => {
      /*console.log(
        "::: candidate",
        s.get("id"),
        s.get("class"),
        s.get("jobGroup"),
        s.get("job"),
        JSON.stringify(poolSelection)
      );
      console.log(
        "::: candidate test",
        poolSelection.includes(Map({ id: s.get("id"), class: s.get("class") }))
      );*/
      return (
        poolSelection.includes(
          Map({ id: s.get("id"), class: s.get("class") })
        ) &&
        s.get("jobGroup") === jobGroup &&
        s.get("job") === job
      );
    })
  );

  return poolToStructures;
};

/*
  Create one secondary structure table per pool/panel
*/
export const mergeSecStructPerPanel = (
  secondaryStructurePanels,
  structurePool,
  userStructures,
  selectedStructures,
  jobGroup,
  job
) => {
  if (!selectedStructures || !secondaryStructurePanels) {
    return;
  }

  const unifiedPool = createUnifiedPool(
    structurePool,
    userStructures,
    jobGroup,
    job
  );

  // cast array/list of relevant pools into immutable List
  secondaryStructurePanels = List(secondaryStructurePanels);

  // TODO: remove
  const toSecStructList = secStruct => {
    return secStruct
      .entrySeq()
      .map(([residue, secStructType]) => ({
        i: residue.get("residueNumber"),
        sec_struct_3state: secStructType
      }))
      .toJSON();
  };

  const mergeSecStructs = secStructs => {
    // create a list of maps from position to secondary structure at position
    const secStructMaps = secStructs.map(m =>
      Map(
        m
          .entrySeq()
          .map(([residueInfo, secStructType]) => [
            residueInfo.get("residueNumber"),
            secStructType
          ])
      )
    );

    // create map from position -> string of all secondary structure characters at that position
    const reducedMap = secStructMaps.reduce(
      (acc, curValue) =>
        acc.mergeWith((oldVal, newVal) => oldVal + newVal, curValue),
      Map()
    );

    // find most frequent secondary structure state per position;
    // priority if equal: H > E > C (consistent with pipeline)
    const mergedMap = reducedMap.map((v, k) => {
      // quick and dirty:
      const countH = v.length - v.replace(/H/g, "").length;
      const countE = v.length - v.replace(/E/g, "").length;
      const countC = v.length - v.replace(/C/g, "").length;
      const maxCount = Math.max(countH, countE, countC);

      // console.log("xxx ....", k, v, countH, countE, countC, maxCount);

      if (countH === maxCount) return "H";
      if (countE === maxCount) return "E";
      if (countC === maxCount) return "C";
    });

    // console.log("xxx REDUCED", JSON.stringify(reducedMap));
    // console.log("xxx MERGED", JSON.stringify(mergedMap));

    return mergedMap;
  };

  /*
    create secondary structure agggregation for one pool:
    select all structures in that pool, then merge their secondary
    structures
  */
  const createAggregation = poolSelection => {
    // extract all selected structures
    const relevantStructures = unifiedPool
      .filter(
        (v, s) =>
          s.get("jobGroup") === jobGroup &&
          s.get("job") === job &&
          poolSelection.contains(
            Map({ id: s.get("id"), class: s.get("class") })
          ) &&
          v.get("status") === STRUCTURE_STATUS.LOADED
      )
      .map((v, s) => v.get("secondaryStructure"))
      .valueSeq();

    // check if there is something to be merged
    if (relevantStructures.count() === 0) {
      return null;
    } else {
      // merge secondary structures and create list of dictionaries {i, sec_struct_3state}
      const merged = mergeSecStructs(relevantStructures)
        .entrySeq()
        .map(([i, secStructType]) => ({
          i: i,
          sec_struct_3state: secStructType
        }))
        .toJSON();
      // console.log("xxx MERGED", JSON.stringify(merged));

      // final dataframe has columns "i" and sec_struct_3state
      const df = makeDataFrame(
        // toSecStructList(relevantStructures.get(0))
        merged
      ).orderBy(row => row.i);

      // console.log("xxx DATAFRAME", df.head(5).toString());
      return df;
    }
  };

  // extract pools relevant for secondary structure and create
  // separate aggregation for each
  const poolToSecStruct = selectedStructures
    .filter((poolSelection, poolName) =>
      secondaryStructurePanels.contains(poolName)
    )
    .map((poolSelection, poolName) => createAggregation(poolSelection));

  return poolToSecStruct;
};
