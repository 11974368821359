import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { AnchorButton } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import JobSubmissionForm from "./components/submission/JobSubmissionForm";
import { JobResultsWrapper } from "./components/results/JobResultsWrapper";
import logo1x from "./static/images/evcouplings_logo_1x.png";
import logo2x from "./static/images/evcouplings_logo_2x.png";
import darpa_logo from "./static/images/funding_darpa.png";
import czi_logo from "./static/images/funding_czi.png";
import nih_logo from "./static/images/funding_nih.png";

// based on https://reacttraining.com/react-router/web/example/basic

// basename={"/evcouplings"}
const PageRouter = () => (
  <Router basename={"/"}>
    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <nav className="bp3-navbar bp3-dark">
        {/* <div style={{ margin: "0 auto", width: "95%" }}> */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="bp3-navbar-group bp3-align-left">
            <AnchorButton
              href="//evcouplings.org"
              minimal={true}
              className="bp3-navbar-heading"
            >
              EVcouplings
            </AnchorButton>
          </div>
          <div className="bp3-navbar-group bp3-align-right">
            <Link
              className="bp3-button bp3-minimal bp3-icon-circle-arrow-right"
              to="/"
            >
              Submit new job
            </Link>
            {/*  <Link
              className="bp3-button bp3-minimal bp3-icon-projects"
              to="/resources"
            >
              Resources
            </Link>

            <Link className="bp3-button bp3-minimal bp3-icon-help" to="/help">
              Help / FAQ
            </Link> */}
            <AnchorButton
              href="//marks.hms.harvard.edu/sars-cov-2"
              icon={IconNames.VIRUS}
              minimal={true}
              target="_blank"
              rel="noopener noreferrer"
            >
              SARS-CoV-2 predictions
            </AnchorButton>
          </div>
        </div>
      </nav>

      <Switch>
        <Route exact path="/" component={SubmissionPage} />
        {/* <Route
          exact
          path="/results/:jobgroup"
          component={ResultsOverviewPage}
        /> 
        <Route path="/results/:jobgroup/:jobid" component={ResultsPage} />*/}
        <Route path="/results/:jobgroup/:job?" component={JobResultsWrapper} />
        <Route path="/resources" component={UnderConstruction} />
        <Route path="/help" component={UnderConstruction} />
        <Route component={WrongTurn404} />
      </Switch>
    </div>
  </Router>
);

const SubmissionPage = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      flex: "1 0 auto",
    }}
  >
    <div
      style={{
        textAlign: "center",
        maxWidth: "550px",
        margin: "0 auto",
        marginTop: "5em",
        padding: "0.5em",
        flex: "1",
      }}
    >
      <img
        src={logo1x}
        alt="EVcouplings logo"
        srcSet={`${logo1x} 1x, ${logo2x} 2x`}
        style={{ width: "60%", marginBottom: "3em" }}
      ></img>
      <JobSubmissionForm />
    </div>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        background: "#EBF1F5",
        height: "50px",
        marginTop: "2em",
      }}
    >
      <span className="bp3-text-muted bp3-text-small">Supported by</span>
      <a
        href="//www.darpa.mil"
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginLeft: "1em", height: "44px" }}
      >
        <img src={darpa_logo} alt="DARPA logo" style={{ height: "44px" }}></img>
      </a>
      <a
        href="//chanzuckerberg.com"
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginLeft: "1em", height: "44px" }}
      >
        <img src={czi_logo} alt="CZI logo" style={{ height: "44px" }}></img>
      </a>
      <a
        href="//nih.gov"
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginLeft: "1em", marginRight: "1.5em", height: "44px" }}
      >
        <img src={nih_logo} alt="DARPA logo" style={{ height: "44px" }}></img>
      </a>
    </div>
  </div>
);

const UnderConstruction = () => (
  <div style={{ display: "flex", justifyContent: "center", marginTop: "2em" }}>
    <h2>Under construction</h2>
  </div>
);

const WrongTurn404 = () => (
  <div style={{ display: "flex", justifyContent: "center", marginTop: "2em" }}>
    <h2>404: Page not found</h2>
  </div>
);

export default PageRouter;
