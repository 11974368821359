/*
    Custom mutation prediction helpers
*/
import { OrderedMap, Map } from "immutable";
import { saveAs } from "file-saver";
import { makeDataFrame } from "./Helpers";
import { MAX_CUSTOM_MUTATIONS } from "./Constants";
import { AMINO_ACID_SEQ_REGEXP } from "./Sequence";
import { apiPollMutationPrediction } from "./Api";

// character separating multiple substitutions in higher-order substitution
export const MUTATION_SEPARATOR_CHAR = ",";

export const EMPTY_CUSTOM_MUTATION_SET = OrderedMap();
export const MUTATION_PREDICTION_STATUS = {
  RUNNING: 1,
  FINISHED: 2,
  FAILED: 3
};

/*
  Actual prediction of custom mutations
*/
const predictCustomMutations = (
  mutations,
  targetSequence,
  fileName,
  dispatchMutations,
  errorToaster
) => {
  // remove whitespace, remove empty lines
  const mutationsCleaned = mutations
    .map(m => m.replace(/\s/g, ""))
    .filter(m => m);

  // avoid excessive prediction load and abort if necessary
  if (MAX_CUSTOM_MUTATIONS && mutationsCleaned.length > MAX_CUSTOM_MUTATIONS) {
    errorToaster(
      `Only up to ${MAX_CUSTOM_MUTATIONS} mutations allowed per submission`
    );
    return;
  }

  // avoid empty input file
  if (mutationsCleaned.length === 0) {
    errorToaster(`Input file is empty (must contain at least one mutant)`);
    return;
  }

  // verify correctness of mutations:
  // 1. correct format,
  // 2. wild-type residue agrees with target sequence (and position is valid)
  // 3. substitution is a valid amino acid character
  let invalid = [];

  mutationsCleaned.forEach(m => {
    // store problems with current mutation here
    const currentInvalid = [];

    // separate out higher-order substitutions
    const mSplit = m.split(MUTATION_SEPARATOR_CHAR);

    mSplit.forEach(mSub => {
      // first check that overall mutation format is correct
      const matchesFormat = /^[A-Z](\d+)[A-Z]$/.test(mSub);
      if (!matchesFormat) {
        currentInvalid.push(
          `${m}: Mutation ${mSub.substr(
            0,
            100
          )} does not adhere to format <wildtype><position><substitution>`
        );
        return;
      }

      // verify individual parts are correct
      const wt = mSub.substr(0, 1);
      const pos = Number.parseInt(mSub.substr(1, mSub.length - 2));
      const subs = mSub.substr(-1, 1);

      // check if this is a position in target sequence
      if (targetSequence.has(pos)) {
        const targetWt = targetSequence.get(pos);
        if (targetWt !== wt) {
          currentInvalid.push(
            `${m}: WT residue ${wt} for position ${pos} does not match target sequence residue ${targetWt}`
          );
        }
      } else {
        currentInvalid.push(
          `${m}: Position ${pos} not contained in target sequence range`
        );
      }

      // check substitution is a valid character
      if (!AMINO_ACID_SEQ_REGEXP.test(subs)) {
        currentInvalid.push(
          `${m}: Mutation ${mSub} contains invalid substitution character (only standard amino acid characters allowed)`
        );
      }
    });

    invalid = invalid.concat(currentInvalid);
  });

  // if we have any errors in mutation file, abort
  if (invalid.length > 0) {
    errorToaster(
      `File ${fileName} contains invalid mutations (${invalid.length} errors). First error: ${invalid[0]}`
    );
    return;
  }

  // otherwise proceed to submission...
  if (dispatchMutations) {
    dispatchMutations(mutationsCleaned, fileName, errorToaster);
  }
};

/*
    Handle uploaded custom mutation files
  */
export const handleCustomMutations = (
  files,
  targetSequence,
  dispatchMutations,
  errorToaster
) => {
  // need target sequence for mutation validation, abort if not available
  if (!targetSequence) {
    errorToaster(
      "Cannot predict custom mutations before target sequence has loaded, please try again shortly."
    );
  }

  // go through uploaded files
  files.forEach(file => {
    const reader = new FileReader();
    reader.onload = event => {
      // extract file contents
      const contents = event.target.result;

      // split on line feed (Unix/Windows)
      const lines = contents.split(/\r\n|\n/);

      // apply mutation prediction to current file
      predictCustomMutations(
        lines,
        targetSequence,
        file.name,
        dispatchMutations,
        errorToaster
      );
    };

    reader.onerror = event => {
      errorToaster(`Error loading file ${file.name}`);
    };

    reader.readAsText(file);
  });
};

export const pollCustomMutationPredictions = (
  customMutations,
  dispatchCustomMutations
) => {
  // find all jobs that are still running
  const runningJobs = customMutations.filter(
    (v, k) => v.get("status") === MUTATION_PREDICTION_STATUS.RUNNING
  );

  // poll each result individually and update status if necessary
  runningJobs.forEach((v, k) => {
    apiPollMutationPrediction(
      k,
      // handler for successful retrieval of results
      result =>
        dispatchCustomMutations({
          action: "UPDATE_JOB",
          id: k,
          status: MUTATION_PREDICTION_STATUS.FINISHED,
          result: result
        }),
      // handler for fatal error when retrieving results
      error =>
        dispatchCustomMutations({
          action: "UPDATE_JOB",
          id: k,
          status: MUTATION_PREDICTION_STATUS.FAILED,
          result: error
        })
    );
  });
};

export const CustomMutationPredictionReducer = (state, action) => {
  // note: use result URL as job ID since this is unique due to job UUID
  switch (action.action) {
    case "ADD_JOB":
      return state.set(
        action.route,
        Map({
          status: MUTATION_PREDICTION_STATUS.RUNNING,
          jobGroup: action.jobGroup,
          job: action.job,
          fileName: action.fileName,
          result: null
        })
      );
    case "UPDATE_JOB":
      const currentId = action.id;
      return state.set(
        currentId,
        Map({
          status: action.status,
          fileName: state.get(currentId).get("fileName"),
          jobGroup: state.get(currentId).get("jobGroup"),
          job: state.get(currentId).get("job"),
          result: action.result
        })
      );
    case "DELETE_JOB":
      return state.delete(action.id);
    default:
      return;
  }
};

export const saveMutationResultFile = (
  resultId,
  customMutations,
  jobGroup,
  job
) => {
  // extract prediction result JSON
  const fullResult = customMutations.get(resultId);
  const results = fullResult.get("result");

  // also get filename of input file to assign appropriate name to output file
  const fileName = fullResult.get("fileName");

  // create dataframe
  const resultsTable = makeDataFrame(results);

  // create blob for file download
  const blob = new Blob([resultsTable.toCSV()], {
    type: "text/csv;charset=utf-8"
  });

  // trigger file download
  saveAs(blob, `EVcouplings_mutation_predictions_${jobGroup}_${job}_${fileName}.csv`);
};
