import React, { useEffect } from "react";
import { Set } from "immutable";
import { Button, ButtonGroup, Tooltip, Divider } from "@blueprintjs/core";
import {
  Cell,
  Column,
  Table,
  SelectionModes,
  Regions,
  RenderMode,
} from "@blueprintjs/table";
import { useDebouncedCallback } from "use-debounce";
import { saveAs } from "file-saver";

import { createSiteId, createSegmentPrefix } from "../../utils/Segments";
import { TableLegend } from "../common/Legend";
import { RESET_SELECTION_ICON } from "../../utils/Constants";
import "./DataTable.css"; // styles for scrolling bar fudge

// width to add to last column to fix scrollbar difference between Chrome and Safari/Firefox (WebKit/Gecko)
const SCROLLBAR_FIX_WIDTH = 15;

/*
  Generic data table for showing data frames based on custom
  column selection and rendering
*/
export const DataTable = ({
  data,
  columns,
  selectedRows,
  onSelection,
  dispatchReset,
  exportFilename,
  debounceTime,
  enableRowHeader,
  customOptions,
  customAnnotations
}) => {
  // optional debouncing of selection events
  const [onSelectionDebounced] = useDebouncedCallback(
    (newSelection) => {
      // console.log("DEBOUNCED SELECTION", JSON.stringify(newSelection));
      onSelection(newSelection);
    },
    debounceTime ? debounceTime : 0
  );

  const dataArray = data ? data.toArray() : [];

  // derive selected regions from selected row indices for blueprint table
  selectedRows = selectedRows ? selectedRows : [];
  const selectedRegions = selectedRows.map((rowIndex) => ({
    rows: [rowIndex, rowIndex],
  }));

  // cf. https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
  // for now, just treat all browsers as non-Chrome, since adding padding will also change behaviour in Chrome
  const isChrome = false;

  const fixCellWidth = (c, idx) => {
    if (idx === columns.length - 1 && !isChrome) {
      return c.width + SCROLLBAR_FIX_WIDTH;
    } else {
      return c.width;
    }
  };

  // select all columns that have a legend item to render
  const legendCols = columns.filter((c) => c.legend);

  // set key to length of data, so changing data doesn't mess up rendering (otherwise, will not rerender properly and show empty rows)
  // cf. https://github.com/palantir/blueprint/issues/3757
  return (
    <>
      <Table
        numRows={dataArray.length}
        key={dataArray.length}
        columnWidths={columns.map((c, idx) => fixCellWidth(c, idx))}
        enableRowHeader={enableRowHeader}
        enableRowResizing={false}
        enableColumnResizing={false}
        renderMode={RenderMode.NONE}
        selectedRegions={selectedRegions}
        selectionModes={SelectionModes.ROWS_AND_CELLS}
        forceRerenderOnSelectionChange={true}
        onSelection={(regions) => {
          // create list of all selected row indices from pairs [regionStart, regionEnd]
          // as a flat array
          // console.log("REGIONS", JSON.stringify(regions));
          const newRowSelectionFull = [];

          // setSelectionDebounced(regions);
          // return;

          for (let [regionStart, regionEnd] of regions.map(
            (region) => region.rows
          )) {
            for (let i = regionStart; i <= regionEnd; i++) {
              newRowSelectionFull.push(i);
            }
          }

          // console.log("FULL", newRowSelectionFull);

          // eliminate anything from selection that occurs twice
          // (deselected element will be added to end of array by blueprint)
          const newRowSelection = newRowSelectionFull.filter((r, i, a) => {
            const occurrenceCount = newRowSelectionFull.filter((r2) => r2 === r)
              .length;
            // console.log("...", r, i, a.length, "count", occurrenceCount);
            return occurrenceCount === 1 || r !== a[a.length - 1];
            // return (newRowSelectionFull.filter(r2 => r2 === r).length === 1) || (i !== a.length - 1)
          });

          // eliminate possible duplications from dragging selection
          const newRowSelectionUnique = Set(newRowSelection);
          // const newPosSelection = [
          //  ...new Set(newRowSelection.map(r => rowToSiteIndex.get(r)))
          // ];

          // check if current selection equals new selection, in this case we remove
          // entire selection, otherwise add/keep all newly selected sites and drop everything else
          let newSelection;

          if (
            newRowSelectionUnique.size === 1 &&
            newRowSelectionUnique.size === selectedRows.length &&
            selectedRows.every((x) => newRowSelectionUnique.includes(x))
          ) {
            // console.log("DESELECT ALL!"); // TODO: remove
            newSelection = [];
          } else {
            // console.log("SET ALL!"); // TODO: remove
            newSelection = newRowSelectionUnique.toList().toJS();
          }

          if (onSelection) {
            if (debounceTime) {
              onSelectionDebounced(newSelection);
            } else {
              onSelection(newSelection);
            }
          }
        }}
      >
        {columns.map((column, idx) => {
          let columnStyle = {};
          let headingStyle = {};

          // only change behaviour for last column and if we don't have Chrome
          if (idx === columns.length - 1 && !isChrome) {
            columnStyle = {
              ...column.style,
              paddingRight: SCROLLBAR_FIX_WIDTH + "px",
            };

            headingStyle = {
              ...column.headingStyle,
              paddingRight: SCROLLBAR_FIX_WIDTH + "px",
            };
          } else {
            columnStyle = column.style;
            headingStyle = column.headingStyle;
          }

          return (
            <Column
              key={idx}
              name={<div style={headingStyle}>{column.heading}</div>}
              cellRenderer={(rowIndex) => (
                <Cell style={columnStyle}>
                  {column.renderer(dataArray[rowIndex], rowIndex)}
                </Cell>
              )}
            />
          );
        })}
      </Table>
      <ButtonGroup minimal={true}>
        {customOptions ? customOptions : null}
        <Button
          title="Clear selection"
          icon={RESET_SELECTION_ICON}
          onClick={() => (dispatchReset ? dispatchReset() : null)}
        />
        <Button
          title="Export table"
          icon="import"
          onClick={() => {
            const blob = new Blob([data.toCSV()], {
              type: "text/csv;charset=utf-8",
            });
            saveAs(blob, exportFilename);
          }}
        />
        {legendCols.length > 0 ? (
          <>
            <Divider />
            <TableLegend columnDescriptions={legendCols} />
          </>
        ) : null}
        {customAnnotations ? customAnnotations : null}
      </ButtonGroup>
    </>
  );
};

export default DataTable;
