import React, { Component, useState } from "react";
import SegmentGraph from "./SegmentGraph";
import {
  Button,
  Collapse,
  Icon,
  Position,
  Popover,
  PopoverInteractionKind,
} from "@blueprintjs/core";
import { deepCopy } from "../../utils/Helpers";
import { DOMAIN_COLORS } from "../../utils/Constants";

const PFAM_URL = "//pfam.xfam.org/family/";
const PDB_URL = "//www.rcsb.org/structure/";
const PFAM_LINK_COLOR = "#669EFF";
const EVALUE_DIGITS = 1;

const StructureInfo = ({ domain }) => {
  const [isOpen, setIsOpen] = useState(false);

  const renderStructurePopover = () => {
    // number structures not returned (truncated for storage reasons)
    const numMissing = domain.numStructures - domain.structures.length;

    // list of PDB ID links
    const items = domain.structures.map((s, i) => (
      <li key={i}>
        <a
          href={PDB_URL + s.pdb_id}
          target="blank"
          style={{ color: PFAM_LINK_COLOR }}
        >
          {s.pdb_id}:{s.pdb_chain}
        </a>
      </li>
    ));

    return (
      <>
        <Button
          icon={isOpen ? "caret-down" : "caret-right"}
          minimal={true}
          small={true}
          onClick={() => setIsOpen(!isOpen)}
        />
        <Collapse isOpen={isOpen}>
          <div
            style={{
              maxHeight: "100px",
              overflowY: "scroll",
              overflowX: " hidden",
            }}
          >
            <ul className="bp3-list-unstyled">{items}</ul>
            {numMissing > 0 ? (
              <span>
                <br />({numMissing} more)
              </span>
            ) : null}
          </div>
        </Collapse>
      </>
    );
  };

  if (domain.numStructures !== null) {
    return (
      <span>
        <br />
        3D structures: {domain.numStructures}{" "}
        {domain.numStructures > 0 ? renderStructurePopover() : null}
      </span>
    );
  } else {
    return null;
  }
};

class PfamDomainGraph extends Component {
  /*
    Assign unique color to each Pfam family
  */
  getColorForDomain(uniqueDomains, domain, colors) {
    let domainIndex = uniqueDomains.findIndex((x) => x === domain);

    // if we have more unique domains than colors, reuse last color repeatedly
    if (domainIndex >= colors.length) {
      domainIndex = colors.length - 1;
    }

    return colors[domainIndex];
  }

  render() {
    // console.log(JSON.stringify(this.props.domains))
    // compute coloring for domains
    let uniqueDomains = Array.from(
      new Set(this.props.domains.map((domain) => domain.identifier))
    );

    // deep copy domains
    let domains = deepCopy(this.props.domains);

    // static assignment of color (for now)
    // domains[i].color = "gold";
    for (let domain of domains) {
      domain.color = this.getColorForDomain(
        uniqueDomains,
        domain.identifier,
        this.props.domainColors ? this.props.domainColors : DOMAIN_COLORS
      );

      // compute slider settings when clicking on domain (add overhang on either side,
      // but make sure we don't fall out of sequence range)
      let overhang = this.props.domainOverhang ? this.props.domainOverhang : 0;

      domain.setStart = Math.max(
        this.props.sequenceStart,
        domain.start - overhang
      );

      domain.setEnd = Math.min(this.props.sequenceEnd, domain.end + overhang);

      domain.content = (
        <Popover
          content={
            <div style={{ textAlign: "center" }}>
              <a
                href={PFAM_URL + domain.identifier}
                target="blank"
                style={{ color: PFAM_LINK_COLOR }}
              >
                <b>{domain.name}</b>
                <br />
                (Pfam: {domain.identifier})
              </a>
              <br />
              Start: {domain.start}
              <br />
              End: {domain.end}
              {domain.numSequences != null && (
                <span>
                  <br />
                  Sequences: {domain.numSequences}
                </span>
              )}
              {domain.eValue != null && (
                <span>
                  <br />
                  E-value:{" "}
                  {domain.eValue > 0 ? parseFloat(domain.eValue).toExponential(EVALUE_DIGITS) : 0}
                </span>
              )}
              <StructureInfo domain={domain} />
            </div>
          }
          interactionKind={PopoverInteractionKind.HOVER}
          position={this.props.positionBottom ? Position.BOTTOM : Position.TOP}
          usePortal={true}
          targetTagName="div"
          popoverClassName="bp3-tooltip"
        >
          <Button
            fill={true}
            minimal={true}
            small={this.props.small}
            style={{
              display: "inline-block",
              textAlign: "center",
              margin: "0%",
              padding: "2px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              minWidth: "0%",
            }}
            onClick={
              this.props.handleValueChange
                ? () =>
                    this.props.handleValueChange([
                      domain.setStart,
                      domain.setEnd,
                    ])
                : null
            }
          >
            {domain.name}
          </Button>
        </Popover>
      );
    }

    // TODO: expand props here?
    return (
      <SegmentGraph
        segments={domains}
        sequenceStart={this.props.sequenceStart}
        sequenceEnd={this.props.sequenceEnd}
        connectSegments={this.props.connectSegments}
      />
    );
  }
}

export default PfamDomainGraph;
