/*
    Functionality for segment handling
*/

export const DEFAULT_SEGMENT_ID = "A";
export const createSiteId = (segment, pos) => segment + "__" + pos;
export const createEdgeId = (source, target) => source + "___" + target;
export const createSegmentPrefix = (showSegments, segmentId) =>
  showSegments ? segmentId + ":" : "";
export const parseSiteId = (siteId) => {
  const splittedId = siteId.split("__");
  return {
    segment: splittedId[0],
    pos: parseInt(splittedId[1], 10)
  }
};
