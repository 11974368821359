import React, { useState, useEffect } from "react";
import DropzoneWrapper from "../external/Dropzone";
import {
  Button,
  ButtonGroup,
  Icon,
  Intent,
  Slider,
  Spinner,
  Switch,
  Toaster
} from "@blueprintjs/core";
import { Map as ImmutableMap } from "immutable";
import { BioblocksPDB, NGLInstanceManager } from "bioblocks-viz";
import { DEFAULT_EC_FILTERING_SETTINGS } from "../../utils/Constants";
import { helpIcon } from "../common/Helpers";
import {
  RESULT_SETTINGS_HELP_TEXTS,
  COMPARE_DISTANCE_TRESHOLD
} from "../../utils/Constants";

import {
  handleCustomMutations,
  saveMutationResultFile,
  MUTATION_PREDICTION_STATUS
} from "../../utils/Mutations";

import {
  extractSecondaryStructure,
  getUniqueProteinChains,
  residuesWithInsertionCodes,
  findResidueMismatches
} from "../../utils/PDB";
import { STRUCTURE_CLASSES } from "../../utils/Structures";

// TODO: eventually move somewhere else if more toasts necessary
export const AppToaster = Toaster.create();
const PAIR_FILTERING_SETTINGS_DEBOUNCE_TIME = 20;
const PANEL_MARGIN_BOTTOM = "3em";

/*
    Add/remove user-defined structures from user structure store
*/
export const CustomStructureSubsettingsReducer = (state, action) => {
  switch (action.action) {
    case "ADD_STRUCTURE":
      // add internal counter to give unique IDs to structures
      let currentStructureId;
      if (state.nextStructureId) {
        currentStructureId = state.nextStructureId;
      } else {
        currentStructureId = 0;
      }

      // add key to (like with those loaded from API)
      const newStructure = action.structure;

      // modify in place (rather ugly) to make filename
      // unique across user-uploaded structures s.t.
      // bioblocks can detect structure changes in input arrays
      newStructure.uuid = currentStructureId + "_" + newStructure.fileName;

      newStructure.nglData["key"] = ImmutableMap({
        id: currentStructureId,
        class: STRUCTURE_CLASSES.USER
      });

      // return updated structures
      return {
        structures: [
          ...state.structures,
          {
            id: currentStructureId,
            name: action.name,
            model: newStructure,
            secondaryStructure: action.secondaryStructure
          }
        ],
        nextStructureId: currentStructureId + 1
      };
    case "REMOVE_STRUCTURE":
      // for safety, check on index and name if some asynchronous stuff happens
      return {
        ...state,
        structures: state.structures.filter((s, i) => s.id !== action.id)
      };
    default:
      return state;
  }
};

const errorToaster = (message, isWarning) => {
  const intent = isWarning ? Intent.WARNING : Intent.DANGER;

  AppToaster.show({
    icon: "warning-sign",
    intent: intent,
    message: message
  });
};

/*
    Read structure files and put into file store
*/
const loadStructureFiles = (files, dispatch, targetSequence) => {
  // try to load each structure and store if successfully loaded
  files.forEach(file => {
    BioblocksPDB.createPDB(file).then(
      structure => {
        let residuesWithInsCodes;
        // try if we can parse the structure, if this fails, something is wrong with the file
        try {
          residuesWithInsCodes = residuesWithInsertionCodes(structure);
        } catch (e) {
          errorToaster(
            "Invalid structure file. Please verify you selected a valid PDB structure."
          );
          return;
        }

        // console.log("*** HAS INSCODE", residuesWithInsCodes);
        const uniqueProteinChains = getUniqueProteinChains(structure).count();
        if (uniqueProteinChains === 1 && residuesWithInsCodes.count() === 0) {
          // check if residues match to target sequence, if not, issue warning
          // (but do not abort completely if this is done intentionally by user)
          if (targetSequence) {
            const residueMismatches = findResidueMismatches(
              structure,
              targetSequence
            );

            const FIRST_N_MISMATCHES = 5;
            const ellipsis = residueMismatches.length > FIRST_N_MISMATCHES;
            const mismatchText =
              residueMismatches
                .slice(0, FIRST_N_MISMATCHES)
                .map(mm => `${mm.pos}: ${mm.targetRes}/${mm.pdbRes}`)
                .join(", ") + (ellipsis ? "..." : "");

            if (residueMismatches.length > 0) {
              errorToaster(
                `Residue mismatch between target sequence and PDB structure in ${residueMismatches.length} positions: ${mismatchText}`,
                true
              );
            }
          }

          dispatch({
            action: "ADD_STRUCTURE",
            structure: structure,
            secondaryStructure: extractSecondaryStructure(structure.nglData),
            name: file.name
          });
        } else {
          if (uniqueProteinChains !== 1) {
            errorToaster(
              "Structure may contain exactly one chain with protein residues, but has " +
                uniqueProteinChains +
                " chains"
            );
          }

          if (residuesWithInsCodes.count() !== 0) {
            errorToaster(
              `Structure has residues with insertion codes: ${residuesWithInsCodes.join(
                ", "
              )}. Note that numbering must strictly match to target sequence.`
            );
          }
        }
      },
      error => {
        // console.log("ERROR LOADING STRUCTURE", files.name, error);
        AppToaster.show({
          icon: "warning-sign",
          intent: Intent.DANGER,
          message: "Could not load structure " + file.name
        });
      }
    );
  });
};

/*
    User 3D structure uploads
*/
export const CustomStructureSubsettingsPanel = ({
  structureStore,
  dispatch,
  targetSequence
}) => {
  return (
    <div style={{ marginBottom: PANEL_MARGIN_BOTTOM }}>
      <p>
        <strong>User 3D structures</strong>&nbsp;
        {helpIcon(RESULT_SETTINGS_HELP_TEXTS.CUSTOM_STRUCTURE_UPLOAD)}
      </p>
      <div style={{ marginBottom: "1em" }}>
        <DropzoneWrapper
          content={
            <p style={{ marginBottom: "0px" }}>
              Drop PDB files here, or click to select
            </p>
          }
          onDrop={acceptedFiles =>
            loadStructureFiles(acceptedFiles, dispatch, targetSequence)
          }
        />
      </div>
      <ul className="bp3-list-unstyled">
        {structureStore.structures.map((s, i) => (
          <li key={i}>
            <span
              style={{
                display: "block",
                wordWrap: "break-word",
                width: "100%"
              }}
            >
              <Button
                icon="small-cross"
                title="Remove user structure"
                small={true}
                minimal={true}
                onClick={() =>
                  dispatch({
                    action: "REMOVE_STRUCTURE",
                    id: s.id
                  })
                }
              />
              {s.name}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

/*
    User custom mutation upload panel
*/
export const CustomMutationSubsettingsPanel = ({
  targetSequence,
  dispatchMutations,
  dispatchNewJobWrapper,
  mutationJobs,
  jobGroup,
  job
}) => {
  // conditional rendering of mutation jobs
  let predictionJobs = null;

  const relevantMutationJobs = mutationJobs
    ? mutationJobs.filter(
        (v, k) => v.get("jobGroup") === jobGroup && v.get("job") === job
      )
    : null;

  if (relevantMutationJobs && relevantMutationJobs.count() > 0) {
    // TODO: add key!
    const listItems = relevantMutationJobs
      .map((v, k) => {
        // button to remove prediction job again (will only delete from frontend, backend will still predict/hold results)
        let deleteButton = (
          <Button
            icon="small-cross"
            title="Remove mutation prediction job"
            small={true}
            minimal={true}
            onClick={() =>
              dispatchMutations({
                action: "DELETE_JOB",
                id: k
              })
            }
          />
        );

        let statusIndicator;
        switch (v.get("status")) {
          case MUTATION_PREDICTION_STATUS.RUNNING:
            statusIndicator = <Spinner size={Spinner.SIZE_SMALL} />;
            break;
          case MUTATION_PREDICTION_STATUS.FINISHED:
            // TODO: implement download of results
            statusIndicator = (
              <Button
                icon="import"
                title="Download prediction results (.csv file)"
                minimal={true}
                small={true}
                onClick={() =>
                  saveMutationResultFile(k, mutationJobs, jobGroup, job)
                }
              />
            );
            break;
          default:
            statusIndicator = <Icon icon="error" intent={Intent.DANGER} />;
            break;
        }
        return (
          <li key={k}>
            <div
              style={{
                verticalAlign: "middle",
                display: "flex",
                justifyContent: "left",
                alignItems: "center"
              }}
            >
              {deleteButton} {v.get("fileName")} &nbsp; {statusIndicator}
            </div>
          </li>
        );
      })
      .valueSeq();

    predictionJobs = <ol className="bp3-list-unstyled">{listItems}</ol>;
  }

  // panel including upload field
  return (
    <div style={{ marginBottom: PANEL_MARGIN_BOTTOM }}>
      <p>
        <strong>Predict custom mutations</strong>&nbsp;
        {helpIcon(RESULT_SETTINGS_HELP_TEXTS.CUSTOM_MUTATIONS_UPLOAD)}
      </p>
      <div style={{ marginBottom: "1em" }}>
        <DropzoneWrapper
          content={
            <p style={{ marginBottom: "0px" }}>
              Drop text file here, or click to select
            </p>
          }
          onDrop={acceptedFiles =>
            handleCustomMutations(
              acceptedFiles,
              targetSequence,
              dispatchNewJobWrapper,
              errorToaster
            )
          }
        />
      </div>
      {predictionJobs}
    </div>
  );
};

/*
    Add/remove user-defined structures from user structure store
*/
export const PairFilteringSubsettingsReducer = (state, action) => {
  switch (action.action) {
    case "RESTORE_DEFAULTS":
      return DEFAULT_EC_FILTERING_SETTINGS;
    case "TOGGLE_FILTER":
      // console.log("TOGGLE", action);   // TODO: remove
      let newStateToggle = {
        ...state
      };
      newStateToggle[action.filterToggle] = !state[action.filterToggle];
      return newStateToggle;
    case "CHANGE_THRESHOLD":
      // console.log("VALUECHANGE");  // TODO: remove
      let newStateThreshold = {
        ...state
      };
      newStateThreshold[action.filterValueKey] = action.thresholdValue;
      // console.log(newStateThreshold);
      return newStateThreshold;
    default:
      return state;
  }
};

/*
    Slider that shows selected value immediately, but only triggers
    outside action upon release of slider
    (problem: keep inside value and outside value in sync)
*/
export const ReleaseSlider = ({ initialValue, onRelease, ...otherProps }) => {
  const [value, setValue] = useState(initialValue);
  // if value supplied by prop changes, synchronize the current state with prop
  useEffect(() => setValue(initialValue), [initialValue]);

  return (
    <Slider
      {...otherProps}
      onChange={newValue => setValue(newValue)}
      value={value}
      onRelease={newValue => onRelease(newValue)}
    />
  );
};

/*
  Distance cutoff threshold for residue pair contacts
*/
export const ContactDistanceThresholdSubsettingPanel = ({
  contactDistanceThreshold,
  setContactDistanceTreshold
}) => {
  return (
    <div style={{ marginBottom: PANEL_MARGIN_BOTTOM }}>
      <p>
        <strong>Residue contact distance threshold [Å]</strong>
      </p>
      <ReleaseSlider
        disabled={false}
        min={COMPARE_DISTANCE_TRESHOLD.MIN}
        max={COMPARE_DISTANCE_TRESHOLD.MAX}
        stepSize={0.1}
        labelStepSize={
          COMPARE_DISTANCE_TRESHOLD.MAX - COMPARE_DISTANCE_TRESHOLD.MIN
        }
        onRelease={newValue => {
          setContactDistanceTreshold(newValue);
        }}
        initialValue={contactDistanceThreshold}
      />
    </div>
  );
};

/*
    Settings for filtering full list of ECs
*/
export const PairFilteringSubsettingsPanel = ({ dispatch, settings }) => {
  const renderFilter = (
    label,
    filterToggleKey,
    filterValueKey,
    minValue,
    maxValue,
    stepSize
  ) => {
    return (
      <>
        <Switch
          label={label}
          checked={settings[filterToggleKey]}
          onChange={() =>
            dispatch({ action: "TOGGLE_FILTER", filterToggle: filterToggleKey })
          }
        />
        <span
          style={{
            marginLeft: "45px",
            marginRight: "1em",
            marginBottom: "1em"
          }}
        >
          <ReleaseSlider
            disabled={!settings[filterToggleKey]}
            min={minValue}
            max={maxValue}
            stepSize={stepSize}
            labelStepSize={maxValue - minValue}
            onRelease={newValue => {
              // console.log("RELEASE");
              dispatch({
                action: "CHANGE_THRESHOLD",
                filterValueKey: filterValueKey,
                thresholdValue: newValue
              });
            }}
            initialValue={settings[filterValueKey]}
          />
        </span>
      </>
    );
  };

  // TODO: turn magic numbers into constants
  return (
    <div style={{ marginBottom: PANEL_MARGIN_BOTTOM }}>
      <p>
        <strong>Evolutionary couplings filtering</strong>
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "1em"
        }}
      >
        {renderFilter(
          "Minimum score",
          "useScoreFilter",
          "scoreFilterThreshold",
          0,
          10,
          0.01
        )}
        {renderFilter(
          "Probability threshold",
          "useProbabilityFilter",
          "probabilityFilterThreshold",
          0.5,
          1,
          0.005
        )}
        {renderFilter(
          "Maximum rank (fraction of L)",
          "useRankFilter",
          "rankFilterThreshold",
          0,
          3,
          0.01
        )}
        {renderFilter(
          "Minimum sequence distance",
          "useSeqDistFilter",
          "seqDistFilterThreshold",
          1,
          20,
          1
        )}
      </div>
      <Button
        icon="undo"
        fill={false}
        minimal={true}
        onClick={() => dispatch({ action: "RESTORE_DEFAULTS" })}
      >
        Restore defaults
      </Button>
    </div>
  );
};

export const PanelSelectionSubsettingsPanel = ({ panels }) => {
  return (
    <div style={{ marginBottom: PANEL_MARGIN_BOTTOM }}>
      <p>
        <strong>Displayed result panels</strong>
      </p>
      <ButtonGroup>
        {panels.map((panel, i) => {
          return (
            <Button
              key={i}
              minimal={true}
              active={panel.state}
              style={{ marginRight: "0.25em" }}
              onClick={() => panel.setter(!panel.state)}
            >
              {panel.label}
            </Button>
          );
        })}
      </ButtonGroup>
    </div>
  );
};
