import React from "react";
import { DataFrame } from "data-forge";
import { interpolateRdBu, color, rgb } from "d3";

export const ALMOST_EQUAL = 1e-12;

export function deepCopy(src) {
  return JSON.parse(JSON.stringify(src));
}

export const almostEqual = (a, b) => Math.abs(a - b) < ALMOST_EQUAL;

export const WHITE = rgb(255, 255, 255);
export const BLACK = rgb(0, 0, 0);

/*
  Wrapper that creates DataFrame independent
  of record/list orientation of data

  TODO: move to utils subpackage
*/
export const makeDataFrame = (data) => {
  // determine data orientation and create
  // dataframe accordingly
  if (Array.isArray(data)) {
    // list of row dictionary
    return new DataFrame({ values: data });
  } else {
    // dictionary of column lists
    return new DataFrame({ columns: data });
  }
};

/*
  Only rerender tab if switching there (or away),
  rather than rerendering on every tab change
  (but avoid unmounting)
*/
export class TabWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { count: 1 };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log("... next props:", nextProps);
    // console.log("... current props:", this.props);

    return nextProps.activeId === this.props.id;
    // maybe need to cover case where switching away from current tab too
    // return nextProps.activeId === this.props.id || this.props.activeId === this.props.id;
  }

  render() {
    // console.log("((( WRAPPER RENDERS )))", this.props.id);
    return <>{this.props.children}</>;
  }
}

export const mixColors = (c1, c2, alpha) => {
  const c1Rgb = c1.rgb();
  const c2Rbg = c2.rgb();

  const r = Math.floor(c1Rgb.r * alpha + c2Rbg.r * (1 - alpha));
  const g = Math.floor(c1Rgb.g * alpha + c2Rbg.g * (1 - alpha));
  const b = Math.floor(c1Rgb.b * alpha + c2Rbg.b * (1 - alpha));

  return rgb(r, g, b);
}

/*
  Create color mapping function for defined value range
*/
export const createColorMapper = (
  data,
  colorScale,
  invertScale,
  symmetrizeRange,
  opacityAdjustment,
  brightnessAdjustment,
) => {
  let minVal;
  let maxVal;

  if (Array.isArray(data)) {
    [minVal, maxVal] = data;
  } else {
    // dataforge series
    if (data.count() > 0) {
      minVal = data.min();
      maxVal = data.max();
    } else {
      throw new Error("Series 'data' does not contain any values.");
    }
  }

  let rangeMin;
  let rangeMax;

  if (symmetrizeRange) {
    const absMax = Math.max(Math.abs(minVal), Math.abs(maxVal));
    rangeMin = -absMax;
    rangeMax = absMax;
  } else {
    rangeMin = minVal;
    rangeMax = maxVal;
  }

  if (Math.abs(rangeMin - rangeMax) < ALMOST_EQUAL) {
    throw new Error(
      "Upper and lower boundary are the same, which would lead to division by zero"
    );
  }

  return (v) => {
    // symmetrical case
    // var mappedVal = (v + range) / (2 * range);
    var mappedVal = (v - rangeMin) / (rangeMax - rangeMin);

    // invert direction of scale if requested
    if (invertScale) {
      mappedVal = 1 - mappedVal;
    }

    let c = color(colorScale(mappedVal));

    // adjust color intensity if argument is given
    if (brightnessAdjustment > 0) {
      c = c.brighter(brightnessAdjustment);
    } else if (brightnessAdjustment < 0) {
      c = c.darker(-brightnessAdjustment);
    } 

    // adjust "opacity" of color by mixing with white background;
    // interpret negative opacity as black background
    if (opacityAdjustment > 0) {
      c = mixColors(c, WHITE, opacityAdjustment);
    } else if (opacityAdjustment < 0) {
      c = mixColors(c, BLACK, -opacityAdjustment);
    }

    return c.hex()
  };
};

export const validEmail = (address) => {
  const emailRegexp = /(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)/;
  return emailRegexp.test(address);
};

/*
  Find consecutive runs of numbers in a *sorted* list/array
  of numbers (last number in segment included)
*/
export const consecutiveSegments = (numbers) => {
  const segments = [];

  numbers.forEach((resNumber) => {
    const lastSegment =
      segments.length > 0 ? segments[segments.length - 1] : null;

    if (!lastSegment || resNumber - 1 !== lastSegment.end) {
      segments.push({
        start: resNumber,
        end: resNumber,
      });
    } else {
      segments[segments.length - 1].end = resNumber;
    }
  });

  return segments;
};
